import { IPasswordFields } from 'modules/Common/types';
import { IEmployeeValues } from 'modules/Common/types/employee';

import { ActionTypes } from './types';

export const createActions = (TYPES: ActionTypes) => {
	const getEmployeeRolesRequested = () => ({
		type: TYPES.GET_EMPLOYEES_ROLES.REQUESTED,
	});
	const getEmployeeStatusesRequested = () => ({
		type: TYPES.GET_EMPLOYEE_STATUSES.REQUESTED,
	});
	const createEmployeeRequested = (data: IEmployeeValues, callback?: () => void) => ({
		type: TYPES.CREATE_EMPLOYEE.REQUESTED,
		payload: data,
		callback,
	});
	const geEmployeesRequested = (params, isSuperUser = false) => ({
		type: TYPES.VIEW_EMPLOYEES_LIST.REQUESTED,
		payload: params,
		isSuperUser,
	});
	const archiveEmployeeRequested = (
		data: { employeeId: number; leaveDate: string },
		callback?: () => void,
	) => ({
		type: TYPES.ARCHIVE_EMPLOYEE.REQUESTED,
		payload: data,
		callback,
	});
	const unArchiveEmployeeRequested = (employeeId: number, callback?: () => void) => ({
		type: TYPES.UNARCHIVE_EMPLOYEE.REQUESTED,
		payload: employeeId,
		callback,
	});
	const changeEmployeeStatusRequested = (
		data: { employeeId: number; employeeStatus: number; startDate?: string; leaveDate?: string },
		callback?: () => void,
	) => ({
		type: TYPES.CHANGE_EMPLOYEE_STATUS.REQUESTED,
		payload: data,
		callback,
	});
	const sendFirstDayInvitationRequested = (employeeId: string) => ({
		type: TYPES.SEND_FIRST_DAY_INVITATION.REQUESTED,
		payload: employeeId,
	});
	const getEmployeeByIdRequested = (employeeId: string, isSuperUser: boolean) => ({
		type: TYPES.GET_EMPLOYEE_BY_ID.REQUESTED,
		payload: employeeId,
		isSuperUser,
	});
	const resetEmployeeDetails = () => ({
		type: TYPES.RESET_EMPLOYEE_DETAILS,
	});
	const getOwnProfileRequested = () => ({
		type: TYPES.GET_OWN_PROFILE.REQUESTED                                                                                                                         ,
	});
	const editOwnProfileRequested = (isSuperUser: boolean, values:IEmployeeValues) => ({
		type: TYPES.EDIT_OWN_PROFILE.REQUESTED,
		payload: { isSuperUser, values },
	});
	const editOwnPasswordRequested = (isSuperUser: boolean, values:IPasswordFields) => ({
		type: TYPES.EDIT_OWN_PASSWORD.REQUESTED,
		payload: { isSuperUser, values },
	});
	const editEmployeeRequested = (isSuperUser: boolean, employeeId: string, values:IEmployeeValues, callback?: () => void) => ({
		type: TYPES.EDIT_EMPLOYEE.REQUESTED,
		payload: { isSuperUser, employeeId, values },
		callback
	});
	const editEmployeePasswordRequested = (isSuperUser: boolean, employeeId: string, values:IEmployeeValues) => ({
		type: TYPES.EDIT_EMPLOYEE_PASSWORD.REQUESTED,
		payload: { isSuperUser, employeeId, values },
	});

	return {
		getEmployeeRolesRequested,
		getEmployeeStatusesRequested,
		createEmployeeRequested,
		geEmployeesRequested,
		archiveEmployeeRequested,
		unArchiveEmployeeRequested,
		changeEmployeeStatusRequested,
		getEmployeeByIdRequested,
		sendFirstDayInvitationRequested,
		resetEmployeeDetails,
		getOwnProfileRequested,
		editOwnProfileRequested,
		editOwnPasswordRequested,
		editEmployeeRequested,
		editEmployeePasswordRequested,
	};
};
