import { Button } from 'antd';
import { darken } from 'polished';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface IUserIconProps {
	bgColor?: string;
	backgroundImageUrl?: string;
}

export namespace Styled {
	export const Root = styled.div`
		position: relative;

		.rbc-time-content,
		.rbc-label.rbc-time-header-gutter {
			display: none;
		}

		.rbc-header {
			text-align: left;
			border-bottom: none;
		}

		.rbc-header button {
			padding: 10px 16px;
			pointer-events: none;

			span {
				color: ${COLORS.black};
				font-family: Inter;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: 150%;
				letter-spacing: -0.132px;
				text-transform: uppercase;
			}
		}

		.rbc-time-view .rbc-row {
			min-height: auto;
		}

		.rbc-time-header-content {
			border: none;
		}

		.rbc-row-content {
			padding-top: 10px;
		}

		.rbc-time-header {
			flex: 1 0 auto;
		}

		.rbc-time-view {
			overflow-y: auto;
			border: 1px solid ${COLORS.lightGray2};
		}
		.rbc-row-segment {
			margin-bottom: 2px;
		}
	`;

	export const EventWrapper = styled.div`
		position: relative;
		height: 100%;

		.rbc-month-view & {
			max-height: 14px;
		}

		span {
			display: flex;
			align-items: center;
			height: 100%;
		}
	`;

	export const PopoverHead = styled.div`
		display: flex;
		max-width: 326px;
		margin: 0 -12px;
		padding: 4px 16px 8px 24px;
		border-bottom: 1px solid ${COLORS.lightGray2};
		gap: 8px;
		font-family: 'Inter';

		h4 {
			word-break: break-all;
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}

		a {
			font-size: 14px;
			line-height: 20px;
		}
	`;

	export const UserIcon = styled.div<IUserIconProps>`
		width: 24px;
		height: 24px;
		background: ${({ backgroundImageUrl, bgColor }) =>
		backgroundImageUrl
			? `url(${backgroundImageUrl}) center/contain no-repeat`
			: bgColor || 'COLORS.green'};
		border-radius: 50%;
		color: ${COLORS.white};
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 9px;
		font-weight: bold;
		${({ backgroundImageUrl }) =>
				backgroundImageUrl &&
			`
				background-color: transparent;
				color: transparent;
		`}
	`;
}
