import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		width: 380px;
		padding: 32px 32px 24px 32px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		background-color: ${COLORS.white};
		border-radius: 10px;
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
	`;

	export const TopSection = styled.div`
		margin-bottom: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
	`;

	export const ProfileFullName = styled.h2`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: -0.264px;
		margin-bottom: 8px;
		text-align: center;
		margin-top: 16px;
	`;

	export const ProfileJobTitle = styled.h3`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		text-align: center;
	`;

	export const Block = styled.div``;

	export const BlockTitle = styled.h4`
		color: ${COLORS.darkGray2};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.176px;
		text-transform: uppercase;
		margin-bottom: 8px;
	`;

	export const BlockContent = styled.div`
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 16px;
		background-color: ${COLORS.lightGray3};
		border-radius: 10px;
		p {
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 0;
			display: flex;
			align-items: center;
			gap: 8px;
		}
	`;
}
