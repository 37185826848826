import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import ATSBuySMSCreditsPage from 'modules/ATS/pages/ATSBuySMSCreditsPage';
import ATSBuyVideoCreditsPage from 'modules/ATS/pages/ATSBuyVideoCreditsPage';
import ATSCandidateDatabasePage from 'modules/ATS/pages/ATSCandidateDatabasePage';
import ATSCreateMessagePage from 'modules/ATS/pages/ATSCreateMessagePage';
import ATSCreateTicketPage from 'modules/ATS/pages/ATSCreateTicketPage';
import ATSCreditDetailsPage from 'modules/ATS/pages/ATSCreditDetailsPage';
import ATSForwardInvoicePage from 'modules/ATS/pages/ATSForwardInvoicePage';
import ATSGravityJobPage from 'modules/ATS/pages/ATSGravityJobPage';
import ATSInterviewRecordingPage from 'modules/ATS/pages/ATSInterviewRecordingPage';
import ATSMessagesListPage from 'modules/ATS/pages/ATSMessagesListPage';
import ATSSendSMSPage from 'modules/ATS/pages/ATSSendSMSPage';
import ATSStatisticsPage from 'modules/ATS/pages/ATSStatisticsPage';
import AtsTicketDetailsPage from 'modules/ATS/pages/AtsTicketDetailsPage';
import AtsViewInvoicePage from 'modules/ATS/pages/ATSViewInvoice';
import CreditPrices from 'modules/BackOffice/containers/CreditPrices';
import ClientContextTicketsPage from 'modules/BackOffice/pages/ClientContextPage/ClientContextTicketsPage';
import PostContractPage from 'modules/BackOffice/pages/PostContractPage';
import PostInvoicePage from 'modules/BackOffice/pages/PostInvoicePage';
import ViewInvoicePage from 'modules/BackOffice/pages/ViewInvoicePage';
import InterviewPage from 'modules/Common/pages/InterviewPage';
import HRModuleRoutes from 'modules/HR/routes';
import ReplyToEmailCandidatesPage from 'modules/Unregistered/pages/ReplyToEmailCandidatesPage';
import TwoFAPage from 'modules/Unregistered/pages/TwoFA';
import ErrorPage from 'pages/ErrorPages/ErrorPage';
import ForbiddenPage from 'pages/ErrorPages/ForbiddenPage';
import NotFoundPage from 'pages/ErrorPages/NotFoundPage';
import ServerErrorPage from 'pages/ErrorPages/ServerErrorPage';
import UnauthorisedPage from 'pages/ErrorPages/UnauthorisedPage';
import RemoveCandidateFromDatabasePage from 'pages/RemoveCandidateFromDatabasePage';
import { Routes, SettingsRoutes, RouteTypesEnum, BackOfficeSettingsRoutes } from 'types';

import ATS2FA from './modules/ATS/containers/ATSSettings/ATS2FA';
import ATSAutoPop from './modules/ATS/containers/ATSSettings/ATSAutoPop';
import ATSChangePassword from './modules/ATS/containers/ATSSettings/ATSChangePassword';
import ATSEmailNotifications from './modules/ATS/containers/ATSSettings/ATSEmailNotifications';
import ATSSMSNotifications from './modules/ATS/containers/ATSSettings/ATSSMSNotifications';
import ATSSocialNetwork from './modules/ATS/containers/ATSSettings/ATSSocialNetwork';
import ATSUserAccess from './modules/ATS/containers/ATSSettings/ATSUserAccess';
import ClientBranding from './modules/ATS/containers/ATSSettings/ClientBranding';
import CompanyDetails from './modules/ATS/containers/ATSSettings/CompanyDetails';
import DefaultMessages from './modules/ATS/containers/ATSSettings/DefaultMessages';
import EmailSignature from './modules/ATS/containers/ATSSettings/EmailSignature';
import PersonalData from './modules/ATS/containers/ATSSettings/PersonalData';
import Subscriptions from './modules/ATS/containers/ATSSettings/Subscriptions';
import ATSAppliedJobsPage from './modules/ATS/pages/ATSAppliedJobsPage';
import ATSArrangeInterviewPage from './modules/ATS/pages/ATSArrangeInterviewPage';
import ATSCandidatesPage from './modules/ATS/pages/ATSCandidatesApplicationPage';
import ATSContractViewPage from './modules/ATS/pages/ATSContractViewPage';
import ATSCreditsPage from './modules/ATS/pages/ATSCreditsPage';
import ATSDashboard from './modules/ATS/pages/ATSDashboard';
import FinancePage from './modules/ATS/pages/ATSFinancePage';
import ATSInterviewsPage from './modules/ATS/pages/ATSInterviewsPage';
import ATSJobDetailsPage from './modules/ATS/pages/ATSJobDetailsPage';
import ATSJobsListPage from './modules/ATS/pages/ATSJobsListPage';
import ATSJobViewPage from './modules/ATS/pages/ATSJobViewPage';
import ATSMainPage from './modules/ATS/pages/ATSMainPage';
import ATSPostJobPage from './modules/ATS/pages/ATSPostJobPage';
import ATSQuestionnaireListPage from './modules/ATS/pages/ATSQuestionnaireListPage';
import ATSQuestionnairePage from './modules/ATS/pages/ATSQuestionnairePage';
import ATSSettings from './modules/ATS/pages/ATSSettingsPage';
import ATSUserAccessFormPage from './modules/ATS/pages/ATSSettingsPage/ATSUserAccessFormPage';
import AtsTicketsPage from './modules/ATS/pages/ATSTickets';
import Clients from './modules/BackOffice/containers/ClientsList';
import BackOfficeDashboardPage from './modules/BackOffice/pages/BackOfficeDashboardPage';
import BackOfficeMainPage from './modules/BackOffice/pages/BackOfficeMainPage';
import BackofficeSettingsPage from './modules/BackOffice/pages/BackOfficeSettingsPage';
import ChangePassword from './modules/BackOffice/pages/BackOfficeSettingsPage/ChangePassword';
import UserAccessFormPage from './modules/BackOffice/pages/BackOfficeSettingsPage/UserAccessFormPage';
import UserAccessPage from './modules/BackOffice/pages/BackOfficeSettingsPage/UserAccessPage';
import ClientContextPage from './modules/BackOffice/pages/ClientContextPage';
import ClientContextCandidatesPage from './modules/BackOffice/pages/ClientContextPage/ClientContextCandidatesPage';
import ClientContextDashboardPage from './modules/BackOffice/pages/ClientContextPage/ClientContextDashboardPage';
import ClientContextJobChangeFormPage from './modules/BackOffice/pages/ClientContextPage/ClientContextJobChangeFormPage';
import ClientContextJobsPage from './modules/BackOffice/pages/ClientContextPage/ClientContextJobsPage';
import ClientContextUserAccessFormPage from './modules/BackOffice/pages/ClientContextPage/ClientContextUserAccessFormPage';
import ClientContextUsersPage from './modules/BackOffice/pages/ClientContextPage/ClientContextUsersPage';
import ClientContractsPage from './modules/BackOffice/pages/ClientContractsPage';
import ClientsPage from './modules/BackOffice/pages/ClientsPage';
import ContractsPage from './modules/BackOffice/pages/ContractsPage';
import ContractViewPage from './modules/BackOffice/pages/ContractViewPage';
import CreateClientPage from './modules/BackOffice/pages/CreateClient';
import CreateJobPage from './modules/BackOffice/pages/CreateJobPage';
import EditClientPage from './modules/BackOffice/pages/EditClient';
import EditClientBranding from './modules/BackOffice/pages/EditClientBranding';
import EditJobPage from './modules/BackOffice/pages/EditJobPage';
import InvoicesPage from './modules/BackOffice/pages/InvoicesPage';
import JobsListPage from './modules/BackOffice/pages/JobsListPage';
import QuestionnaireListPage from './modules/BackOffice/pages/QuestionnaireListPage';
import BackofficeTicketsDetailsPage from './modules/BackOffice/pages/TicketDetailsPage';
import BackofficeTicketsPage from './modules/BackOffice/pages/Tickets';
import CreateClientBranding from './modules/BackOffice/pages/СreateClientBranding';
import { ProtectedRoute } from './modules/Common/components/ProtectedRoute';
import EmailTemplates from './modules/Common/containers/EmailTemplates';
import CareerJobAssessment from './modules/Common/pages/CareerJobAssessment';
import CareerJobPage from './modules/Common/pages/CareerJobPage';
import CareerMainPage from './modules/Common/pages/CareerMainPage';
import DeclineInterviewPage from './modules/Common/pages/DeclineInterviewPage';
import { AtsProfileMenuList } from './modules/Common/types';
import ChangePasswordPage from './modules/Unregistered/pages/ChangePasswordPage';
import ForgotPassword from './modules/Unregistered/pages/ForgotPasswordPage';
import Login from './modules/Unregistered/pages/LoginPage';
import VideoQuestionnairePage from './modules/Unregistered/pages/VideoQuestionnairePage';

const routes = [
	{
		path: Routes.Main,
		element: (
			<ProtectedRoute type={RouteTypesEnum.REGISTERED}>
				<BackOfficeMainPage />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
	},
	{
		path: Routes.Login,
		element: (
			<ProtectedRoute type={RouteTypesEnum.UNREGISTERED}>
				<Login />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
	},
	{
		path: Routes.FinishRegistration,
		element: (
			<ProtectedRoute type={RouteTypesEnum.UNREGISTERED}>
				<ChangePasswordPage isFinishRegistration />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
	},
	{
		path: Routes.TwoFA,
		params: ['jwt2fa'],
		element: (
			<ProtectedRoute type={RouteTypesEnum.UNREGISTERED}>
				<TwoFAPage />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
	},
	{
		path: Routes.ForgotPass,
		element: (
			<ProtectedRoute type={RouteTypesEnum.UNREGISTERED}>
				<ForgotPassword />
			</ProtectedRoute>
		),
	},
	{
		path: Routes.ResetPass,
		element: (
			<ProtectedRoute type={RouteTypesEnum.UNREGISTERED}>
				<ChangePasswordPage />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
	},
	{
		path: `${Routes.VideoQuestionnaire}/:questionnaireId`,
		element: <VideoQuestionnairePage />,
	},
	{
		path: `${Routes.EmailReply}/:emailThreadId`,
		params: ['pwd'],
		element: <ReplyToEmailCandidatesPage />,
	},
	{
		path: `${Routes.RemoveCandidatesFromDatabase}/:candidateApplicationId`,
		params: ['pwd'],
		element: <RemoveCandidateFromDatabasePage />,
	},
	{
		path: Routes.BackOffice,
		element: (
			<ProtectedRoute type={RouteTypesEnum.REGISTERED}>
				<BackOfficeMainPage />
			</ProtectedRoute>
		),
		children: [
			{
				path: `${Routes.BOClientContext}`,
				element: <ClientContextPage />,
				children: [
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Dashboard}`,
						element: <ClientContextDashboardPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Jobs}`,
						element: <ClientContextJobsPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.CreateJob}`,
						element: <ClientContextJobChangeFormPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.EditJob}/:jobId`,
						element: <ClientContextJobChangeFormPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Questionnaires}`,
						element: <QuestionnaireListPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Users}`,
						element: <ClientContextUsersPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Users}${Routes.Create}`,
						element: <ClientContextUserAccessFormPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Users}${Routes.Edit}/:userId`,
						element: <ClientContextUserAccessFormPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Candidates}`,
						element: <ClientContextCandidatesPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Contracts}`,
						element: <ContractsPage isClientContext />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.ContractsCreate}`,
						element: <PostContractPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.ContractsEdit}/:contractId`,
						element: <PostContractPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.ContractsView}/:contractId`,
						element: <ContractViewPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.Invoices}`,
						element: <InvoicesPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.InvoicesView}/:invoiceId`,
						element: <ViewInvoicePage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.InvoicesCreate}`,
						element: <PostInvoicePage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.InvoiceEdit}/:invoiceId`,
						element: <PostInvoicePage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.SupportTickets}`,
						element: <ClientContextTicketsPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.SupportTickets}/:ticketId`,
						element: <BackofficeTicketsDetailsPage />,
					},
					{
						path: `${Routes.BOClientContext}/:clientId${Routes.CreateSupportTicket}`,
						element: <BackofficeTicketsDetailsPage />,
					},
				],
			},
			{
				path: `${Routes.BackOffice}${Routes.Dashboard}`,
				element: <BackOfficeDashboardPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.Clients}`,
				element: <ClientsPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.ClientCreate}`,
				element: <CreateClientPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.EditClient}/:clientId`,
				element: <EditClientPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.InvoicesView}/:invoiceId`,
				element: <ViewInvoicePage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.CreateClientBranding}`,
				element: <CreateClientBranding />,
			},
			{
				path: `${Routes.BackOffice}${Routes.EditClientBranding}/:clientId`,
				element: <EditClientBranding />,
			},
			{
				path: `${Routes.BackOffice}${Routes.Clients}/:clientId`,
				element: <Clients />,
			},
			{
				path: `${Routes.BackOffice}${Routes.SupportTickets}`,
				element: <BackofficeTicketsPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.CreateSupportTicket}`,
				element: <BackofficeTicketsDetailsPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.SupportTickets}/:ticketId`,
				element: <BackofficeTicketsDetailsPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.Jobs}`,
				element: <JobsListPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.CreateJob}`,
				element: <CreateJobPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.EditJob}/:jobId`,
				element: <EditJobPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.Questionnaires}`,
				element: <QuestionnaireListPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.ContractsCreate}`,
				element: <PostContractPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.ContractsEdit}/:contractId`,
				element: <PostContractPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.Contracts}`,
				element: <ContractsPage isClientContext={false} />,
			},
			{
				path: `${Routes.BackOffice}${Routes.ContractsView}/:contractId`,
				element: <ContractViewPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.Contracts}/:clientId`,
				element: <ClientContractsPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.Invoices}`,
				element: <InvoicesPage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.InvoicesCreate}`,
				element: <PostInvoicePage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.InvoiceEdit}/:invoiceId`,
				element: <PostInvoicePage />,
			},
			{
				path: `${Routes.BackOffice}${Routes.Settings}`,
				element: <BackofficeSettingsPage />,
				children: [
					{
						path: BackOfficeSettingsRoutes.CreditPrices,
						element: <CreditPrices />,
					},
					{
						path: BackOfficeSettingsRoutes.EmailTemplates,
						element: <EmailTemplates />,
					},
					{
						path: BackOfficeSettingsRoutes.ChangePassword,
						element: <ChangePassword />,
					},
					{
						path: BackOfficeSettingsRoutes.UserAccess,
						element: <UserAccessPage />,
					},
					{
						path: BackOfficeSettingsRoutes.UserAccessCreate,
						element: <UserAccessFormPage />,
					},
					{
						path: `${BackOfficeSettingsRoutes.UserAccessEdit}/:userId`,
						element: <UserAccessFormPage />,
					},
				],
			},
		],
		errorElement: <ErrorPage />,
	},
	{
		path: Routes.ATS,
		element: (
			<ProtectedRoute type={RouteTypesEnum.REGISTERED}>
				<ATSMainPage />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
		children: [
			{
				path: `${Routes.ATS}${Routes.Dashboard}`,
				element: <ATSDashboard />,
			},
			{
				path: `${Routes.ATS}${Routes.Statistic}`,
				element: <ATSStatisticsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.Candidates}`,
				element: <ATSCandidatesPage />,
			},
			{
				path: `${Routes.ATS}${Routes.CandidatesDatabase}/:applicationId`,
				element: <ATSCandidateDatabasePage />,
			},
			{
				path: `${Routes.ATS}${Routes.AppliedJobsCandidate}/:candidateId`,
				element: <ATSAppliedJobsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.Interviews}`,
				element: <ATSInterviewsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.CandidatesSendSMS}`,
				element: <ATSSendSMSPage />,
			},
			{
				path: `${Routes.ATS}${Routes.ArrangeInterviewCreate}`,
				element: <ATSArrangeInterviewPage />,
			},
			{
				path: `${Routes.ATS}${Routes.ArrangeInterviewEdit}/:interviewId`,
				element: <ATSArrangeInterviewPage />,
			},
			{
				path: `${Routes.ATS}${Routes.InterviewRecording}/:interviewId`,
				element: <ATSInterviewRecordingPage />,
			},
			{
				path: `${Routes.ATS}${Routes.Jobs}`,
				element: <ATSJobsListPage />,
			},
			{
				path: `${Routes.ATS}${Routes.Jobs}/:jobId`,
				element: <ATSJobDetailsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.JobView}/:jobId`,
				element: <ATSJobViewPage />,
			},
			{
				path: `${Routes.ATS}${Routes.PostJob}`,
				element: <ATSPostJobPage />,
			},
			{
				path: `${Routes.ATS}${Routes.PostJobCopy}/:jobId`,
				element: <ATSPostJobPage />,
			},
			{
				path: `${Routes.ATS}${Routes.PostJobBoost}/:jobId`,
				element: <ATSPostJobPage />,
			},
			{
				path: `${Routes.ATS}${Routes.PostJobEdit}/:jobId`,
				element: <ATSPostJobPage />,
			},
			{
				path: `${Routes.ATS}${Routes.GravityJob}/:jobId`,
				element: <ATSGravityJobPage />,
			},
			{
				path: `${Routes.ATS}${Routes.Questionnaires}`,
				element: <ATSQuestionnaireListPage />,
			},
			{
				path: `${Routes.ATS}${Routes.QuestionnairesCreate}`,
				element: <ATSQuestionnairePage />,
			},
			{
				path: `${Routes.ATS}${Routes.QuestionnairesCopy}/:id`,
				element: <ATSQuestionnairePage />,
			},
			{
				path: `${Routes.ATS}${Routes.QuestionnairesEdit}/:id`,
				element: <ATSQuestionnairePage />,
			},
			{
				path: `${Routes.ATS}${Routes.QuestionnairesSend}`,
				element: <ATSQuestionnairePage />,
			},
			{
				path: `${Routes.ATS}${Routes.QuestionnairesSend}/:candidateId`,
				element: <ATSQuestionnairePage />,
			},
			{
				path: `${Routes.ATS}${Routes.Messages}`,
				element: <ATSMessagesListPage />,
			},
			{
				path: `${Routes.ATS}${Routes.CreateMessage}`,
				element: <ATSCreateMessagePage />,
			},
			{
				path: `${Routes.ATS}${Routes.Credits}`,
				element: <ATSCreditsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.Credits}/:creditId`,
				element: <ATSCreditDetailsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.SupportCenter}`,
				element: <AtsTicketsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.SupportCenter}/:ticketId`,
				element: <AtsTicketDetailsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.SupportCenterTicketCreate}`,
				element: <ATSCreateTicketPage />,
			},
			{
				path: `${Routes.ATS}${Routes.Finance}`,
				element: <FinancePage />,
			},
			{
				path: `${Routes.ATS}${Routes.InvoiceForward}/:invoiceId`,
				element: <ATSForwardInvoicePage />,
			},
			{
				path: `${Routes.ATS}${Routes.ContractsView}/:contractId`,
				element: <ATSContractViewPage />,
			},
			{
				path: `${Routes.ATS}${Routes.InvoicesView}/:invoiceId`,
				element: <AtsViewInvoicePage />,
			},
			{
				path: `${Routes.ATS}${Routes.UserProfile}`,
				element: <h2>{AtsProfileMenuList.UserProfile}</h2>,
			},
			{
				path: `${Routes.ATS}${Routes.BuyVideoCredits}`,
				element: <ATSBuyVideoCreditsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.BuySMSCredits}`,
				element: <ATSBuySMSCreditsPage />,
			},
			{
				path: `${Routes.ATS}${Routes.Settings}`,
				element: <ATSSettings />,
				children: [
					{
						path: SettingsRoutes.CompanyDetails,
						element: <CompanyDetails />,
					},
					{
						path: SettingsRoutes.Subscription,
						element: <Subscriptions />,
					},
					{
						path: SettingsRoutes.Branding,
						element: <ClientBranding />,
					},
					{
						path: SettingsRoutes.SocialNetworks,
						element: <ATSSocialNetwork />,
					},
					{
						path: SettingsRoutes.EmailTemplates,
						element: <EmailTemplates />,
					},
					{
						path: SettingsRoutes.EmailNotifications,
						element: <ATSEmailNotifications />,
					},
					{
						path: SettingsRoutes.SMSReminders,
						element: <ATSSMSNotifications />,
					},
					{
						path: SettingsRoutes.EmailSignature,
						element: <EmailSignature />,
					},
					{
						path: SettingsRoutes.DefaultMessages,
						element: <DefaultMessages />,
					},
					{
						path: SettingsRoutes.Authentication,
						element: <ATS2FA />,
					},
					{
						path: SettingsRoutes.AutoPop,
						element: <ATSAutoPop />,
					},
					{
						path: SettingsRoutes.ChangePassword,
						element: <ATSChangePassword />,
					},
					{
						path: SettingsRoutes.UserAccess,
						element: <ATSUserAccess />,
					},
					{
						path: SettingsRoutes.UserAccessCreate,
						element: <ATSUserAccessFormPage />,
					},
					{
						path: `${SettingsRoutes.UserAccessEdit}/:userId`,
						element: <ATSUserAccessFormPage />,
					},
					{
						path: SettingsRoutes.PersonalData,
						element: <PersonalData />,
					},
				],
			},
		],
	},
	{
		path: `${Routes.Interview}/:interviewId`,
		element: <InterviewPage />,
	},
	{
		path: Routes.CareerPage,
		element: (
			<ProtectedRoute type={RouteTypesEnum.UNREGISTERED}>
				<CareerMainPage />
			</ProtectedRoute>
		),
	},
	{
		path: `${Routes.CareerJobPage}/:jobId`,
		element: (
			<ProtectedRoute type={RouteTypesEnum.UNREGISTERED}>
				<CareerJobPage />
			</ProtectedRoute>
		),
	},
	{
		path: Routes.CareerJobAssessment,
		element: (
			<ProtectedRoute type={RouteTypesEnum.UNREGISTERED}>
				<CareerJobAssessment />
			</ProtectedRoute>
		),
	},
	{
		path: `${Routes.DeclineInterview}/:interviewId`,
		element: <DeclineInterviewPage />,
	},
	{
		path: Routes.Unauthorised,
		element: <UnauthorisedPage />,
	},
	{
		path: Routes.Forbidden,
		element: <ForbiddenPage />,
	},
	{
		path: Routes.NotFound,
		element: <NotFoundPage />,
	},
	{
		path: Routes.ServerError,
		element: <ServerErrorPage />,
	},
	...HRModuleRoutes,
];

const App = createBrowserRouter(routes);

export default App;
