import React, { FC } from 'react';
import { connect } from 'react-redux';

import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

import { IUser } from 'modules/Common/types';
import { CalendarEventType } from 'modules/Common/types/hrModuleTypes';
import { getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';
import HRCalendarPopupEvent from 'modules/HR/components/HRCalendarPopupEvent/HRCalendarPopupEvent';
import { CALENDAR_EVENT_TYPES } from 'modules/HR/constants/HRModuleConstants.constants';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { capitalizeFirstLetterOfEachWord } from 'utils/helpers';

import { Styled } from '../HRCalendarWeekly.styled';

type InterviewCalendarPopupEventTypeProps = {
	event: CalendarEventType;
	user: IUser;
	popoverPlacement?: TooltipPlacement;
	popoverTrigger?: 'click' | 'hover';
	popoverIsOff?: boolean;
	handleEventDecline: (id: number) => void;
	handleEventApprove: (id: number) => void;
	handleEventEdit: (id: number) => void;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

const HRCalendarCustomEvent: FC<InterviewCalendarPopupEventTypeProps> = ({
	event,
	user,
	popoverPlacement,
	popoverTrigger,
	popoverIsOff,
	handleEventDecline,
	handleEventApprove,
	handleEventEdit,
}) => {
	const {
		id,
		type,
		eventName,
		employeeFullName,
		employeeId,
		start,
		end,
		comments,
		icon,
		iconBackgroundColor,
		employeePhotoUuid,
		isApproved,
		isDeclined,
		isPending,
	} = event || {};

	const eventTitle =
		type === CALENDAR_EVENT_TYPES.publicHoliday
			? `${icon} ${eventName}`
			: `${icon} ${capitalizeFirstLetterOfEachWord(type)} - ${employeeFullName}`;

	if (type === CALENDAR_EVENT_TYPES.publicHoliday) {
		return <span>{eventTitle}</span>;
	}

	const backgroundImageUrl = employeePhotoUuid && `${baseUrl}${employeePhotoUuid}`;

	const popoverTitle = (
		<Styled.PopoverHead>
			<Styled.UserIcon bgColor={iconBackgroundColor} backgroundImageUrl={backgroundImageUrl || ''}>
				{getFirstLettersUppercase(employeeFullName || 'user')}
			</Styled.UserIcon>
			<h4>{employeeFullName}</h4>
		</Styled.PopoverHead>
	);

	return (
		<Styled.EventWrapper>
			{popoverIsOff ? (
				<span>{eventTitle}</span>
			) : (
				<Popover
					placement={popoverPlacement || 'rightTop'}
					title={popoverTitle}
					content={
						<HRCalendarPopupEvent
							id={id}
							employeeId={employeeId}
							isApproved={isApproved}
							isDeclined={isDeclined}
							isPending={isPending}
							user={user}
							type={type}
							start={start}
							end={end}
							comment={comments}
							handleApprove={handleEventApprove}
							handleDecline={handleEventDecline}
							handleEdit={handleEventEdit}
						/>
					}
					trigger={popoverTrigger || 'click'}
				>
					<span>{eventTitle}</span>
				</Popover>
			)}
		</Styled.EventWrapper>
	);
};

export default connect((state) => ({
	user: unregisteredDucks.unregisteredSelectors.getUser(state),
}))(HRCalendarCustomEvent);
