import React, { useState, type FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TablePaginationConfig, SorterResult } from 'antd/lib/table/interface';
import { debounce } from 'lodash';

import { useMount } from 'hooks';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { TabsTitleEnum } from 'modules/Common/types';
import { EmployeeStatusTypesFormEnum, IEmployeeState } from 'modules/Common/types/employee';
import { hrDucks } from 'modules/HR/ducks';
import { Routes } from 'modules/HR/routes/types';

import EmployeeTableForSuperUser from './EmployeeTableForSuperUser/EmployeeTableForSuperUser';
import EmployeeTableForUser from './EmployeeTableForUser/EmployeeTableForUser';
import { columns } from './HREmployeesList.entities';
import {
	ChangeActionEnum,
	HREmployeesListProps,
	StatusModalInfoProps,
} from './HREmployeesList.types';

const HREmployeesList: FC<HREmployeesListProps> = ({
	isSuperUser,
	employeeStatusesList,
	employeesList,
	loading,
	getEmployeeStatusesRequested,
	getEmployeeListRequested,
	archiveEmployeeRequested,
	unArchiveEmployeeRequested,
	changeEmployeeStatusRequested,
}) => {
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);
	const [searchValue, setSearchValue] = useState<string>('');
	const [search, setSearch] = useState<string>('');
	const [status, setStatus] = useState<IEmployeeState | string>('ALL');
	const [modalStatusProps, setModalStatusProps] = useState<StatusModalInfoProps>(null);
	const [tableParams, setTableParams] = useState({});

	const { pageIndex, pageSize, totalCount, data } = employeesList || {};

	const isActiveTab = tab === TabsTitleEnum.ActiveTab;
	const listLoading = !!loading?.viewEmployeesListLoad || !!loading?.getEmployeeStatusesLoad;
	const navigate = useNavigate();

	useMount(() => {
		isSuperUser && !employeeStatusesList.length && getEmployeeStatusesRequested();
		!employeesList.data.length &&
			getEmployeeListRequested(
				{
					active: isActiveTab,
					page: DEFAULT_CURRENT_PAGE,
					size: DEFAULT_PAGE_SIZE,
				},
				isSuperUser,
			);
	});

	const handleChangeTabs = (id: string) => {
		setTab(id);
	};

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			setTableParams({
				...tableParams,
				page,
				size,
			});
		},
		[tableParams],
	);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			sorter,
		});
	};

	useEffect(() => {
		getEmployeeListRequested(
			{
				active: isActiveTab,
				page: DEFAULT_CURRENT_PAGE,
				size: DEFAULT_PAGE_SIZE,
				search,
				...(isSuperUser && isActiveTab && { status: status === 'ALL' ? '' : status }),
			},
			isSuperUser,
		);
	}, [tab]);

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		getEmployeeListRequested(
			{
				active: isActiveTab,
				page: tableParams?.page || 0,
				size: tableParams?.size || DEFAULT_PAGE_SIZE,
				search,
				...(isSuperUser && isActiveTab && { status: status === 'ALL' ? '' : status }),
				...(tableParams?.sorter?.order &&
					tableParams?.sorter?.field && {
					sort: `${tableParams?.sorter?.field},${
						ordersMap[tableParams?.sorter?.order || 'ascend']
					}`,
				}),
			},
			isSuperUser,
		);
	}, [tableParams, status, search]);

	const handleViewItem = useCallback(async (employeeId: number) => {
		navigate(`${Routes.HRModule}${Routes.Employee}/${employeeId}`);
	}, []);

	const handleEditItem = useCallback(async (employeeId: number) => {
		navigate(`${Routes.HRModule}${Routes.EmployeeEdit}/${employeeId}`);
	}, []);

	const handleChangeStatus = useCallback(
		(
			employeeId: number,
			changeAction: ChangeActionEnum,
			startStatus?: EmployeeStatusTypesFormEnum,
			endStatus?: EmployeeStatusTypesFormEnum,
		) => {
			setModalStatusProps({ employeeId, changeAction, startStatus, endStatus });
		},
		[],
	);

	const handleStatusModalClose = useCallback(() => {
		setModalStatusProps(null);
	}, []);

	const handleModalSubmit = useCallback(
		async (
			changeAction: ChangeActionEnum,
			employeeId: number,
			values: { employeeStatus: number; startDate?: string; leaveDate?: string },
			cb?: () => void,
		) => {
			const callback = () => {
				cb && cb();
				handleStatusModalClose();
				getEmployeeListRequested(
					{
						active: isActiveTab,
						page: pageIndex,
						size: pageSize,
						search,
					},
					isSuperUser,
				);
			};
			if (changeAction === ChangeActionEnum.Archive) {
				archiveEmployeeRequested(
					{
						employeeId: employeeId,
						leaveDate: values?.leaveDate || '',
					},
					callback,
				);
			} else if (changeAction === ChangeActionEnum.UnArchive) {
				unArchiveEmployeeRequested(employeeId, callback);
			} else {
				changeEmployeeStatusRequested({ employeeId, ...values }, callback);
			}
		},
		[isActiveTab],
	);

	const debouncedSearch = useCallback(
		debounce((searchTerm) => {
			setSearch(searchTerm);
		}, 500),
		[tab],
	);

	const handleSearch = (value: string) => {
		setSearchValue(value);
		if (value.length >= 1 || value.length === 0) {
			debouncedSearch(value);
		}
	};

	const handleStatusFilterChange = useCallback((value: string) => {
		setStatus(value);
	}, []);

	const columnsData = columns(
		isActiveTab,
		employeeStatusesList,
		!isSuperUser,
		handleChangeStatus,
		handleViewItem,
		handleEditItem,
	);

	return isSuperUser ? (
		<EmployeeTableForSuperUser
			search={searchValue}
			onSearchChange={handleSearch}
			status={status}
			onStatusChange={handleStatusFilterChange}
			pageSize={pageSize}
			pageIndex={pageIndex}
			loading={listLoading}
			totalCount={totalCount}
			data={data}
			columnsData={columnsData}
			isActiveTab={isActiveTab}
			onTableChange={handleTableChange}
			onChange={handleTablePaginationChange}
			onPageSizeChange={handleTablePaginationChange}
			onTabChange={handleChangeTabs}
			modalStatusProps={modalStatusProps}
			onModalClose={handleStatusModalClose}
			onModalSubmit={handleModalSubmit}
			employeeStatusesList={employeeStatusesList}
		/>
	) : (
		<EmployeeTableForUser
			search={searchValue}
			onSearchChange={handleSearch}
			pageSize={pageSize}
			pageIndex={pageIndex}
			loading={listLoading}
			totalCount={totalCount}
			data={data}
			columnsData={columnsData}
			onTableChange={handleTableChange}
			onChange={handleTablePaginationChange}
			onPageSizeChange={handleTablePaginationChange}
		/>
	);
};

export default connect(
	(state) => ({
		employeeStatusesList: hrDucks.hrSelectors.getEmployeeStatuses(state),
		employeesList: hrDucks.hrSelectors.getEmployeesList(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
	}),
	{
		getEmployeeListRequested: hrDucks.hrActions.geEmployeesRequested,
		getEmployeeStatusesRequested: hrDucks.hrActions.getEmployeeStatusesRequested,
		archiveEmployeeRequested: hrDucks.hrActions.archiveEmployeeRequested,
		unArchiveEmployeeRequested: hrDucks.hrActions.unArchiveEmployeeRequested,
		changeEmployeeStatusRequested: hrDucks.hrActions.changeEmployeeStatusRequested,
	},
)(HREmployeesList);
