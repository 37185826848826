import React, { type FC, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';

import { InboxOutlined } from '@ant-design/icons';
import { DatePicker, Input, UploadFile } from 'antd';
import dayjs from 'dayjs';
import createDecorator from 'final-form-calculate';
import { isEmpty, omit } from 'lodash';
import useMedia from 'use-media';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import DownloadIcon from 'components/SVG/DownloadIcon';
import CollapseList from 'modules/ATS/components/CollapseList';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedEmployeeFilesUploadFormFileFormat } from 'modules/Common/constants';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import {
	CALENDAR_EVENT_TYPES,
	CalendarEmojisByTypes,
	MOBILE_VIEW_BREAKPOINT,
} from 'modules/HR/constants/HRModuleConstants.constants';
import { COLORS } from 'theme';
import { DataFormatEnum } from 'types';
import { handleFileDownload } from 'utils/helpers';
import {
	lengthValidator,
	requiredFieldValidator,
	textWithoutNewLinesTabsAndCarriageReturnRegExp,
} from 'utils/validators';

import { calculateDayOffsRequested } from './HRRequestEditTimeOffModal.helpers';
import { Styled } from './HRRequestEditTimeOffModal.styled';
import { IPostEditTimeOffEventValues } from './HRRequestEditTimeOffModal.types';

const { RangePicker } = DatePicker;

type AvailableHoursOffByType = {
	[key: string]: number;
};

type HRRequestEditTimeOffModalProps = {
	employeeId: number | null;
	availableTypeOffData: AvailableHoursOffByType[];
	timeOffEventFormFields?: IPostEditTimeOffEventValues;
	loading?: boolean;
	onCancel: () => void;
	handlePostTimeOffEvent: (formValues: IPostEditTimeOffEventValues) => void;
	handleEditTimeOffEvent: (formValues: IPostEditTimeOffEventValues) => void;
};

const filterOptions = [
	{ label: 'Vacation', value: CALENDAR_EVENT_TYPES.vacation },
	{ label: 'Sick Leave', value: CALENDAR_EVENT_TYPES.sickLeave },
	{ label: 'Maternity Leave', value: CALENDAR_EVENT_TYPES.maternityLeave },
	{ label: 'Other', value: CALENDAR_EVENT_TYPES.other },
	{ label: 'Public Holiday', value: CALENDAR_EVENT_TYPES.publicHoliday },
];

const dayTypeOptions = [
	{ label: 'Full Day (8 hrs)', value: 'FULL' },
	{ label: 'Half Day (4 hrs) - AM', value: 'HALF_OFF_AM' },
	{ label: 'Half Day (4 hrs) - PM', value: 'HALF_OFF_PM' },
];

const listOfHolidaysUK = [
	'01/01/2024', // New Year's Day
	'29/03/2024', // Good Friday
	'01/04/2024', // Easter Monday
	'06/05/2024', // Early May Bank Holiday
	'27/05/2024', // Spring Bank Holiday
	'26/08/2024', // Late Summer Bank Holiday
	'25/12/2024', // Christmas Day
	'26/12/2024', // Boxing Day
]; // UK public holidays, for now hardcoded, but should be fetched from the BE

const { TextArea } = Input;

export const HRRequestEditTimeOffModal: FC<HRRequestEditTimeOffModalProps> = ({
	employeeId,
	availableTypeOffData,
	timeOffEventFormFields,
	loading,
	onCancel,
	handlePostTimeOffEvent,
	handleEditTimeOffEvent,
}) => {
	const dataFormat = DataFormatEnum.UK ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [timeOffTypeSelected, setTimeOffTypeSelected] = useState<string | null>(null);
	const [availableDaysOff, setAvailableDaysOff] = useState<number | null>(null);
	const [totalDaysOffRequested, setTotalDaysOffRequested] = useState<number | null>(null);
	const [daysListArrayMobile, setDaysListArrayMobile] = useState<{ key: string }[]>([]);
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	const initialValues = useMemo(
		() => ({
			type: null,
			firstDayType: 'FULL',
			lastDayType: 'FULL',
			...(!isEmpty(timeOffEventFormFields) && timeOffEventFormFields),
		}),
		[timeOffEventFormFields],
	);

	useEffect(() => {
		if (timeOffTypeSelected && availableTypeOffData[timeOffTypeSelected]) {
			setAvailableDaysOff(availableTypeOffData[timeOffTypeSelected]);
		} else {
			setAvailableDaysOff(null);
		}
	}, [timeOffTypeSelected]);

	const onSubmit = (values: IPostEditTimeOffEventValues) => {
		const valuesToSend = {
			...values,
			startTime: dayjs(values.startTimeEndTime[0]).format('YYYY-MM-DDTHH:mm:ss'),
			endTime: dayjs(values.startTimeEndTime[1]).format('YYYY-MM-DDTHH:mm:ss'),
			amountHours: totalDaysOffRequested * 8,
			employeeId,
		};
		const filteredValuesToSend = omit(valuesToSend, ['startTimeEndTime']);
		// if (isEmpty(timeOffEventFormFields)) {
		// 	handlePostTimeOffEvent(filteredValuesToSend);
		// } else {
		// 	handleEditTimeOffEvent(filteredValuesToSend);
		// }
	};

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData, file) => {
				onChange([...value, responseData]);
			},
			{
				fileList,
				accept: acceptedEmployeeFilesUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					onChange([...value.filter((item) => item !== file?.response?.data)]);
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.Ticket, // should there be a new type created for this? ask the BE team
				maxCount: undefined,
			},
			setFileList,
		);

	const handleDownload = (file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	};

	const calculateAndSetTotalDaysOff = (allValues) => {
		if (allValues?.startTimeEndTime) {
			const { totalDays, daysListArray } = calculateDayOffsRequested(
				allValues?.startTimeEndTime,
				allValues?.firstDayType,
				allValues?.lastDayType,
				listOfHolidaysUK,
			);
			setTotalDaysOffRequested(totalDays);
			setDaysListArrayMobile(daysListArray);
		} else {
			setTotalDaysOffRequested(null);
			setDaysListArrayMobile([]);
		}
	};

	const formDecorator = useMemo(
		() =>
			createDecorator(
				{
					field: 'type',
					updates: (value) => {
						setTimeOffTypeSelected(value);

						return {};
					},
				},
				{
					field: 'startTimeEndTime',
					updates: (_, __, allValues) => {
						calculateAndSetTotalDaysOff(allValues);

						return {};
					},
				},
				{
					field: 'firstDayType',
					updates: (_, __, allValues) => {
						calculateAndSetTotalDaysOff(allValues);

						return {};
					},
				},
				{
					field: 'lastDayType',
					updates: (_, __, allValues) => {
						calculateAndSetTotalDaysOff(allValues);

						return {};
					},
				},
			),
		[],
	) as never;

	return (
		<Styled.Modal open={!!employeeId} footer={null} centered onCancel={onCancel} width='640px'>
			<Styled.ModalHead>Add Time-Off</Styled.ModalHead>
			<Styled.ModalContent>
				{availableDaysOff !== null && (
					<Styled.AvailableDaysOffNote>
						{CalendarEmojisByTypes[timeOffTypeSelected || '']} Available days:{' '}
						<span>
							{availableDaysOff === 1 ? `${availableDaysOff} day` : `${availableDaysOff} days`}
						</span>
					</Styled.AvailableDaysOffNote>
				)}
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					decorators={[formDecorator]}
					autoComplete='off'
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Field name='type' validate={requiredFieldValidator}>
								{({ input, meta }) => (
									<FieldWrapper
										name='type'
										label='Time-Off type'
										required
										isFixed
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<Styled.Select
											{...input}
											value={input.value || null}
											options={filterOptions}
											placeholder='Select type'
										/>
									</FieldWrapper>
								)}
							</Field>
							<Styled.DateRangeLine>
								<Styled.DateRangeLineTitle>
									Date Range <span>*</span>
								</Styled.DateRangeLineTitle>
								<Styled.DateRangeLineMainContent>
									<Styled.DateRangeLineContentColumnsWrap>
										<Styled.DateRangeLineContentColumnTitle>
											From
										</Styled.DateRangeLineContentColumnTitle>
										<Styled.DateRangeLineContentColumnTitle>
											To
										</Styled.DateRangeLineContentColumnTitle>
									</Styled.DateRangeLineContentColumnsWrap>
									<Styled.DateRangeInput isMobile={isMobile}>
										<Field name='startTimeEndTime'>
											{({ input, meta }) => (
												<FieldWrapper
													name='startTimeEndTime'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													isFixed
												>
													<RangePicker {...input} format={dataFormat} inputReadOnly={false} />
												</FieldWrapper>
											)}
										</Field>
									</Styled.DateRangeInput>
								</Styled.DateRangeLineMainContent>
								{!isMobile && (
									<Styled.DateRangeLineContent>
										<Styled.DateRangeLineContentColumn>
											<Styled.DateRangeLineContentColumnContent>
												<Field name='firstDayType' validate={requiredFieldValidator}>
													{({ input, meta }) => (
														<FieldWrapper
															name='firstDayType'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
														>
															<Styled.RadioGroup
																{...input}
																value={input.value}
																options={dayTypeOptions}
															></Styled.RadioGroup>
														</FieldWrapper>
													)}
												</Field>
											</Styled.DateRangeLineContentColumnContent>
										</Styled.DateRangeLineContentColumn>
										<div>-</div>
										<Styled.DateRangeLineContentColumn>
											<Styled.DateRangeLineContentColumnContent>
												<Field name='lastDayType' validate={requiredFieldValidator}>
													{({ input, meta }) => (
														<FieldWrapper
															name='lastDayType'
															errorMessage={meta.submitFailed && meta.touched && meta.error}
														>
															<Styled.RadioGroup
																{...input}
																value={input.value}
																options={dayTypeOptions}
															></Styled.RadioGroup>
														</FieldWrapper>
													)}
												</Field>
											</Styled.DateRangeLineContentColumnContent>
										</Styled.DateRangeLineContentColumn>
									</Styled.DateRangeLineContent>
								)}
							</Styled.DateRangeLine>
							<FormBlockLine columns={1}></FormBlockLine>
							<FormBlockLine columns={1}>
								<Field
									name='description'
									validate={lengthValidator(
										1,
										3000,
										textWithoutNewLinesTabsAndCarriageReturnRegExp,
									)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='description'
											label='Description'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<TextArea
												{...input}
												placeholder='Include comments for your approver'
												autoSize={{ minRows: 3, maxRows: 3 }}
											/>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<FormBlockLine columns={1}>
								<Field name='attachments'>
									{({ input, meta }) => (
										<FieldWrapper
											isFixed
											name='attachments'
											label='Attachments'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Styled.Dragger
												{...draggerProps(input)}
												listType='text'
												onPreview={handleDownload}
												showUploadList={{
													showDownloadIcon: true,
													downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
												}}
											>
												<p className='ant-upload-drag-icon'>
													<InboxOutlined />
												</p>
												<p className='ant-upload-text'>Upload a files or drag and drop it</p>
												<p className='ant-upload-hint'>
													{' '}
													PDF, DOC, DOCX, XLS, XLSX, JPEG, PNG up to 15MB{' '}
												</p>
											</Styled.Dragger>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							{!isMobile && totalDaysOffRequested !== null && (
								<Styled.TotalRequestedDayOffCalculation>
									Total request:{' '}
									<span>
										{totalDaysOffRequested === 1
											? `${totalDaysOffRequested} Working Day`
											: `${totalDaysOffRequested} Working Days`}
									</span>
								</Styled.TotalRequestedDayOffCalculation>
							)}
							{isMobile && daysListArrayMobile.length > 0 && (
								<Styled.DayOffCalculationMobile>
									<Styled.DayOffCalculationMobileHeader>
										Time Off Request includes
									</Styled.DayOffCalculationMobileHeader>
									<Styled.DayOffCalculationMobileList>
										{daysListArrayMobile.map((day, index, arr) => {
											const [date, description] = Object.entries(day)[0];
											const isFirst = index === 0;
											const isLast = index === arr.length - 1;
											const durationInput = isFirst || isLast;

											const TitleComponent = (
												<Styled.DayOffCalculationMobileRowTitle>
													<Styled.DayOffCalculationMobileRowDate>
														{date}
													</Styled.DayOffCalculationMobileRowDate>{' '}
													<Styled.DayOffCalculationMobileRowDescription>
														{description}
													</Styled.DayOffCalculationMobileRowDescription>
												</Styled.DayOffCalculationMobileRowTitle>
											);

											const DurationInputComponent = durationInput && (
												<Styled.DayOffCalculationMobileRowDateInput>
													<Field
														name={isFirst ? 'firstDayType' : 'lastDayType'}
														validate={requiredFieldValidator}
													>
														{({ input, meta }) => (
															<FieldWrapper
																label='Duration'
																name={isFirst ? 'firstDayType' : 'lastDayType'}
																errorMessage={meta.submitFailed && meta.touched && meta.error}
															>
																<Styled.RadioGroup
																	{...input}
																	value={input.value}
																	options={dayTypeOptions}
																></Styled.RadioGroup>
															</FieldWrapper>
														)}
													</Field>
												</Styled.DayOffCalculationMobileRowDateInput>
											);

											return (
												<Styled.DayOffCalculationMobileRow key={date}>
													{durationInput ? (
														<CollapseList
															items={[
																{
																	key: '1',
																	label: TitleComponent,
																	children: DurationInputComponent,
																	showArrow: true,
																},
															]}
														/>
													) : (
														TitleComponent
													)}
												</Styled.DayOffCalculationMobileRow>
											);
										})}
									</Styled.DayOffCalculationMobileList>
									<Styled.DayOffCalculationMobileFooter>
										<Styled.DayOffCalculationMobileFooterTitle>
											Total request
										</Styled.DayOffCalculationMobileFooterTitle>
										<Styled.DayOffCalculationMobileFooterCalculation>
											{totalDaysOffRequested === 1
												? `${totalDaysOffRequested} Working Day`
												: `${totalDaysOffRequested} Working Days`}
										</Styled.DayOffCalculationMobileFooterCalculation>
									</Styled.DayOffCalculationMobileFooter>
								</Styled.DayOffCalculationMobile>
							)}
							<Styled.ButtonBox>
								<Button type='button' buttonType={ButtonTypes.tertiary} onClick={() => onCancel()}>
									Cancel
								</Button>
								<Button type='submit' buttonType={ButtonTypes.primary} loading={false}>
									{timeOffEventFormFields ? 'Update request' : 'Send Request'}
								</Button>
							</Styled.ButtonBox>
						</form>
					)}
				/>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
