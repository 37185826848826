import { FC } from 'react';
import { Field, useField } from 'react-final-form';

import FormSwitch from 'components/FormSwitch';

import { Styled } from './HRHolidayAbsencePolicyBlock.styled';

export const DayToggleItem: FC<{ dayName: string; dayLabel: string }> = ({ dayName, dayLabel }) => {
	const { input: dayToggle } = useField(dayName, { subscription: { value: true } });

	return (
		<Styled.WorkingDaysToggle isActive={dayToggle.value}>
			<Field name={dayName}>{({ input }) => <FormSwitch {...input} title={dayLabel} />}</Field>
		</Styled.WorkingDaysToggle>
	);
};
