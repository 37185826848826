import { message } from 'antd';
import _ from 'lodash';
import { call, put, select, all } from 'redux-saga/effects';

import API from 'api';
import { DEFAULT_PAGE_SIZE, MESSAGES } from 'modules/Common/constants';
import {
	clientChangesFormAllowedFields,
	CareerPageBrandingAllowedFields,
	AtsBrandingAllowedFields,
	AtsColoursAllowedFields,
	SocialNetworksAllowedFields,
	subscriptionFields,
	contractChangesFormAllowedFields,
} from 'modules/Common/constants/clientChangesForm.constants';
import { commonDucks } from 'modules/Common/ducks';
import { JobClassesEnum } from 'modules/Common/types';
import { getDateAndTime } from 'utils/helpers';

import { getAllPrices, getWallet, convertAllCredits, convertAllPrices } from './helpers';

import { backOfficeDucks } from './';

export const createSagas = (TYPES) => {
	function* getClients() {
		try {
			const clients = yield call(API.backOfficeService.getClients);
			const sortedClients = _.orderBy(
				_.filter(clients, _.isObject),
				[(client) => client.clientName.toLowerCase()],
				['asc'],
			);

			yield put({ type: TYPES.GET_CLIENTS.SUCCESS, payload: sortedClients });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENTS.FAILED, payload: error });
		}
	}

	function* getBackofficeClientsSaga({ payload }) {
		try {
			const clients = yield call(API.clientsService.getBOClients, payload);

			yield put({ type: TYPES.GET_BO_CLIENTS.SUCCESS, payload: clients });
		} catch (error) {
			yield put({ type: TYPES.GET_BO_CLIENTS.FAILED, payload: error });
		}
	}

	function* getContextClientByIdSaga({ payload }) {
		try {
			const clientData = yield call(API.backOfficeService.getClientById, payload);

			yield put({ type: TYPES.GET_CONTEXT_CLIENT.SUCCESS, payload: clientData });
		} catch (error) {
			yield put({ type: TYPES.GET_CONTEXT_CLIENT.FAILED, payload: error });
		}
	}

	function* getClientContextClientsListSaga({ payload }) {
		try {
			const clients = yield call(API.clientsService.getClientContextClientsList, payload);

			yield put({
				type: TYPES.GET_CLIENT_CONTEXT_CLIENTS_LIST.SUCCESS,
				payload: clients,
			});

			if (!!payload?.search && !clients?.totalCount) {
				yield message.warning(MESSAGES.warningNotFoundClients);
			}
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_CONTEXT_CLIENTS_LIST.FAILED, payload: error });
		}
	}

	function* getClientsSubs() {
		try {
			const clientsSubs = yield call(API.backOfficeService.getClientsSubs);

			yield put({ type: TYPES.GET_CLIENTS_SUBS.SUCCESS, payload: clientsSubs });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENTS_SUBS.FAILED, payload: error });
		}
	}

	function* getClientsRegions() {
		try {
			const clientRegions = yield call(API.backOfficeService.getClientRegions);

			yield put({ type: TYPES.GET_CLIENT_REGIONS.SUCCESS, payload: clientRegions });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_REGIONS.FAILED, payload: error });
		}
	}

	function* getBackofficeInvoicesSaga(action) {
		try {
			const params = action.payload;
			const invoices = yield call(API.invoicesService.getBackofficeInvoices, params);

			yield put({ type: TYPES.GET_BACKOFFICE_INVOICES.SUCCESS, payload: invoices });
		} catch (e) {
			const res = e?.response?.data;

			if (res && res.statusCode === 403) {
				yield put({ type: TYPES.GET_BACKOFFICE_INVOICES.FAILED, payload: res.exceptionMessage });
			}
		}
	}

	function* deleteInvoiceByIdSaga({ payload, callback }) {
		try {
			yield call(API.invoicesService.deleteInvoiceById, payload);

			yield put({ type: TYPES.DELETE_INVOICE_BY_ID.SUCCESS, payload });

			yield call(message.success, MESSAGES.successfullyDeletedInvoice);

			yield call(callback);
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.DELETE_INVOICE_BY_ID.FAILED, payload: e });
		}
	}

	function* deleteTicketByIdSaga({ payload, callback }) {
		try {
			// yield call(API.ticketsService.deleteTicketById, payload);

			yield put({ type: TYPES.DELETE_TICKET_BY_ID.SUCCESS, payload });

			yield call(message.success, MESSAGES.successfullyDeletedTicket);

			yield call(callback);
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.DELETE_TICKET_BY_ID.FAILED, payload: e });
		}
	}

	function* getBackofficeTicketsSaga(action) {
		try {
			const params = action.payload;
			const { clientId } = params;
			const url = clientId
				? API.ticketsService.getBackofficeClientTickets
				: API.ticketsService.getBackofficeTickets;

			const tickets = yield call(
				url,
				params || {
					size: 20,
					active: true,
				},
			);
			yield put({ type: TYPES.GET_TICKETS.SUCCESS, payload: tickets });
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_TICKETS.FAILED, payload: e });
		}
	}

	function* getBackofficeTicketDetailsSaga(action) {
		try {
			const ticketId = action.payload;
			const ticket = yield call(API.ticketsService.getBackofficeTicketById, ticketId);
			let assessmentQuestions = null;

			if (ticket?.jobId) {
				assessmentQuestions = yield call(API.assessmentService.getAssessmentByJobId, ticket.jobId);
			}

			yield put({
				type: TYPES.GET_TICKET_DETAILS.SUCCESS,
				payload: { ...ticket, assessmentQuestions },
			});
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_TICKET_DETAILS.FAILED, payload: e });
		}
	}

	function* getClientSubsInfoSaga(action) {
		try {
			const clientId = action.payload;
			const subsInfo = yield call(API.subscriptionsService.getClientSubscriptionSettings, clientId);
			const subsDetails = yield call(
				API.subscriptionsService.getClientSubscriptionInfoDetails,
				clientId,
			);

			yield put({
				type: TYPES.GET_CLIENT_SUBS_INFO.SUCCESS,
				payload: {
					...subsInfo,
					subsDetails,
					premiumStatus: subsDetails?.premiumStatus?.toUpperCase(),
				},
			});
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_CLIENT_SUBS_INFO.FAILED, payload: e });
		}
	}

	function* getCreditsDefaultPricesSaga() {
		try {
			const creditsDefaultPrices = yield call(API.creditsService.getCreditsDefaultPrices);

			yield put({ type: TYPES.GET_CREDITS_DEFAULT_PRICES.SUCCESS, payload: creditsDefaultPrices });

			return creditsDefaultPrices;
		} catch (e) {
			yield put({ type: TYPES.GET_CREDITS_DEFAULT_PRICES.FAILED, payload: e });
		}
	}

	function* updateCreditsDefaultPricesSaga(action) {
		try {
			yield call(API.creditsService.updateCreditsDefaultPrices, action.payload);

			yield put({ type: TYPES.UPDATE_CREDITS_DEFAULT_PRICES.SUCCESS });
			yield put({ type: TYPES.GET_CREDITS_DEFAULT_PRICES.REQUESTED });
			yield call(message.success, 'Default prices have updated');
		} catch (e) {
			yield put({ type: TYPES.UPDATE_CREDITS_DEFAULT_PRICES.FAILED, payload: e });
		}
	}

	function* getSubsDefaultPricesSaga() {
		try {
			const subsDefaultPrices = yield call(API.subscriptionsService.getSubsDefaultPrices);

			yield put({ type: TYPES.GET_SUBS_DEFAULT_PRICES.SUCCESS, payload: subsDefaultPrices });
		} catch (e) {
			yield put({ type: TYPES.GET_SUBS_DEFAULT_PRICES.FAILED, payload: e });
		}
	}

	function* updateSubsDefaultPricesSaga(action) {
		try {
			yield call(API.subscriptionsService.updateSubsDefaultPrices, action.payload);

			yield put({ type: TYPES.UPDATE_SUBS_DEFAULT_PRICES.SUCCESS });
			yield put({ type: TYPES.GET_SUBS_DEFAULT_PRICES.REQUESTED });
		} catch (e) {
			yield put({ type: TYPES.UPDATE_SUBS_DEFAULT_PRICES.FAILED, payload: e });
		}
	}

	function* getCurrentInvoiceSaga(action) {
		try {
			const invoiceId = action.payload;
			const invoice = yield call(API.invoicesService.getBOInvoiceById, invoiceId);

			yield put({ type: TYPES.GET_INVOICE_BY_ID.SUCCESS, payload: invoice });
		} catch (e) {
			yield put({ type: TYPES.GET_INVOICE_BY_ID.FAILED, payload: e });
		}
	}

	function* getClientsByRegionSaga({ payload }) {
		try {
			const { region, params } = payload;

			const clients = yield call(API.backOfficeService.getClientsByRegion, region, params);

			yield put({ type: TYPES.GET_CLIENTS_BY_REGION.SUCCESS, payload: clients });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENTS_BY_REGION.FAILED, payload: error });
		}
	}

	function* createAndUpdateClientChangesFormSaga({
		payload,
		cb: callback,
		isUpdate = false,
		isSuperUser = false,
		subsInfo,
	}) {
		try {
			let client;
			let career;
			const values = payload;
			const creditsDefault = yield call(getCreditsDefaultPricesSaga);

			const clientChangesFormData = yield select(
				backOfficeDucks.backOfficeSelectors.getClientChangesFormState,
			);

			const combinedValues = {
				...clientChangesFormData,
				...values,
				annualSubscription: `${Math.round(+values.annualPrice * 100)}`,
				monthlySubscription: `${Math.round(+values.monthlyPrice * 100)}`,
			};

			const isUKClient = combinedValues.region === 1;

			let preparedClientValues = {
				..._.pick(combinedValues, clientChangesFormAllowedFields),
				careersPageEnabled: true,
			};

			if (isUKClient && !combinedValues?.parent) {
				const { wallet } = getWallet(combinedValues, creditsDefault);
				preparedClientValues = { ...preparedClientValues, ...wallet };
			}

			if (isUpdate) {
				delete preparedClientValues.region;
				delete preparedClientValues.creditChecked;

				client = yield call(API.clientsService.updateClient, preparedClientValues);
			} else {
				client = yield call(API.backOfficeService.createClient, preparedClientValues);
			}

			const preparedAtsBrandingValues = {
				..._.pick(combinedValues.atsBranding, AtsBrandingAllowedFields),
				client: client?.id,
				...combinedValues.atsColors,
			};

			const prepareCareerBrandingValues = {
				..._.pick(combinedValues.careerBranding, CareerPageBrandingAllowedFields),
				client: client?.id,
				...combinedValues.careerPageColors,
			};

			const preparedSocialNetworks = {
				..._.pick(combinedValues?.careerBranding?.socialNetworks, SocialNetworksAllowedFields),
				client: client?.id,
			};

			if (isUpdate) {
				preparedSocialNetworks.id = combinedValues?.socialNetworks;

				yield call(API.brandingService.updateAtsBranding, preparedAtsBrandingValues);
				yield call(API.socialNetworksService.updateClientSocialNetworks, preparedSocialNetworks);
				career = yield call(API.brandingService.updateCareerBranding, prepareCareerBrandingValues);
			} else {
				yield call(API.brandingService.createAtsBranding, preparedAtsBrandingValues);
				yield call(API.socialNetworksService.createClientSocialNetworks, preparedSocialNetworks);
				career = yield call(API.brandingService.createCareerBranding, prepareCareerBrandingValues);
			}

			const subscriptionSettings = {
				..._.pick(
					combinedValues,
					combinedValues.freeTrial && !clientChangesFormData.initialTrialEnabled
						? subscriptionFields
						: subscriptionFields.filter((sf) => !['trialEndDate'].includes(sf)),
				),
				manualInvoice: combinedValues.manualInvoice,
				...(combinedValues.manualInvoice && {
					subscriptionPlanDuration: combinedValues.subscriptionPlanDuration,
				}),
				clientId: client.id,
				...(combinedValues.trialEndDate && {
					trialEndDate: getDateAndTime(combinedValues.trialEndDate, {
						endDayFormat: true,
						isUTCFormatting: false,
					}),
				}),
				annualPrice: `${Math.round(+values.annualPrice * 100)}`,
				monthlyPrice: `${Math.round(+values.monthlyPrice * 100)}`,
			};

			if (isUpdate && !isSuperUser && !subsInfo?.subsDetails?.trialAvailable) {
				delete subscriptionSettings.trialEndDate;
			}

			yield call(API.subscriptionsService.applySubscriptionSettings, subscriptionSettings);

			if (isUKClient) {
				const { prices, shouldCreateNewPrices } =
					combinedValues &&
					!combinedValues?.parent &&
					getAllPrices(combinedValues, isUpdate, client);

				// When the parent is not set the prices will be sent to server
				if (prices && !combinedValues?.parent) {
					for (const price of prices) {
						if (isUpdate && !shouldCreateNewPrices) {
							yield call(API.backOfficeService.updatePrice, price);
						} else {
							yield call(API.backOfficeService.createPrice, price);
						}
					}
				}
			}

			if (combinedValues.careerBranding?.benefits?.length) {
				const benefits = combinedValues.careerBranding?.benefits;
				const addedBenefits = benefits.filter((b) => b.isNew);
				const deletedBenefits = benefits.filter((b) => b.isDeleted);

				if (addedBenefits.length) {
					yield call(
						API.brandingService.createClientBenefits,
						addedBenefits.map((b) => ({
							benefitText: b.benefitText,
							career: career.id,
							img: b.img,
						})),
					);
				}

				if (deletedBenefits.length) {
					yield all(
						deletedBenefits.map(({ id: benefitId }) =>
							call(API.brandingService.removeBenefit, benefitId),
						),
					);
				}
			}

			yield put({ type: TYPES.CREATE_AND_UPDATE_CLIENT.SUCCESS, payload: client });
			yield call(callback);
			yield put({
				type: TYPES.GET_BO_CLIENTS.REQUESTED,
				payload: { page: 0, size: DEFAULT_PAGE_SIZE },
			});

			message.success(`A client has been successfully ${isUpdate ? 'updated' : 'created'}`);
		} catch (error) {
			yield put({ type: TYPES.CREATE_AND_UPDATE_CLIENT.FAILED, payload: error });
		}
	}

	function* getCurrentClientAndSaveChangesFormSaga(action) {
		try {
			const clientId = action.payload;
			const clientData = yield call(API.clientsService.getClientById, clientId);

			const atsBrandingData = yield call(API.brandingService.getAtsBrandingByClientId, clientId);
			const careerBrandingData = yield call(
				API.brandingService.getCareerBrandingByClient,
				clientId,
			);
			const socialNetworksData = careerBrandingData[0]?.socialNetworks ?? null;
			const benefits = careerBrandingData[0]?.benefits ?? null;

			const pricesData = yield call(API.backOfficeService.getPricesByClientId, clientId);
			const creditsData = yield call(API.backOfficeService.getCreditsByClientId, clientId);
			const socialNetworks = _.pick(socialNetworksData, SocialNetworksAllowedFields);
			const prices = convertAllPrices(pricesData);
			const credits = convertAllCredits(creditsData);

			const atsColors = _.pick(atsBrandingData?.[0], AtsColoursAllowedFields);
			const atsBranding = _.pick(atsBrandingData?.[0], AtsBrandingAllowedFields);
			const careerBranding = _.pick(careerBrandingData?.[0], CareerPageBrandingAllowedFields);
			const careerPageColors = _.pick(careerBrandingData?.[0], AtsColoursAllowedFields);

			// Needed for cleaning credits up when we delete the parent
			const hasSavedParent = !!clientData.parent;

			yield put({
				type: TYPES.GET_CURRENT_CLIENT.SUCCESS,
				payload: {
					...clientData,
					hasSavedParent,
					...prices,
					...credits,
					id: +clientId,
					atsColors,
					atsBranding,
					careerPageColors,
					careerBranding: {
						...careerBranding,
						socialNetworks,
						benefits,
					},
				},
			});
		} catch (error) {
			yield put({ type: TYPES.GET_CURRENT_CLIENT.FAILED, payload: error });
		}
	}

	function* createContractSaga(action) {
		try {
			const values = _.omit(action.payload, ['btoReference']);
			const callback = action.cb;

			const contact = yield call(API.contractsService.createContract, values);
			yield put({ type: TYPES.CREATE_CONTRACT.SUCCESS, payload: contact });

			message.success('The contract has been sent');

			yield call(callback);
			yield put({ type: commonDucks.commonActionTypes.GET_CONTRACTS.REQUESTED });
		} catch (error) {
			yield put({ type: TYPES.CREATE_CONTRACT.FAILED, payload: error });
		}
	}

	function* createInvoiceSaga({ payload, cb }) {
		try {
			const invoice = yield call(API.invoicesService.createInvoice, payload);
			yield put({ type: TYPES.CREATE_INVOICE.SUCCESS, payload: invoice });

			yield call(message.success, MESSAGES.successfullySentInvoice);

			cb && (yield call(cb));
			yield put({ type: commonDucks.commonActionTypes.GET_BACKOFFICE_INVOICES.REQUESTED });
		} catch (error) {
			yield put({ type: TYPES.CREATE_INVOICE.FAILED, payload: error });
		}
	}

	function* updateInvoiceSaga({ payload, cb }) {
		try {
			const invoice = yield call(API.invoicesService.updateInvoice, payload);

			yield put({ type: TYPES.UPDATE_INVOICE.SUCCESS, payload: invoice });

			cb && (yield call(cb));

			yield call(message.success, MESSAGES.successfullyUpdatedInvoice);

			yield put({ type: commonDucks.commonActionTypes.GET_BACKOFFICE_INVOICES.REQUESTED });
		} catch (error) {
			yield put({ type: TYPES.UPDATE_INVOICE.FAILED, payload: error });
		}
	}

	function* getAllBackOfficeJobsSaga({ payload, isClientContext, isDashboard }) {
		try {
			let endpoint = '';
			if (isDashboard) {
				endpoint = API.jobsService.getClientContextDashboardJobs;
			} else if (isClientContext) {
				endpoint = API.jobsService.getAllClientContextJobsJobs;
			} else {
				endpoint = API.jobsService.getAllBackOfficeJobs;
			}

			const jobsData = yield call(endpoint, payload);

			yield put({
				type: TYPES.GET_ALL_BACKOFFICE_JOBS.SUCCESS,
				payload: jobsData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_ALL_BACKOFFICE_JOBS.FAILED, payload: error });
		}
	}

	function* getBackOfficeCandidatesSaga({ payload, isClientContext }) {
		try {
			const candidatesData = yield call(
				isClientContext
					? API.candidateApplicationService.getClientContextCandidates
					: API.candidateApplicationService.getClientContextCandidates,
				payload,
			);

			yield put({
				type: TYPES.GET_BACKOFFICE_CANDIDATES.SUCCESS,
				payload: candidatesData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_BACKOFFICE_CANDIDATES.FAILED, payload: error });
		}
	}

	function* getBackOfficeJobByIdSaga(action) {
		try {
			let assessmentQuestions = [];
			const jobData = yield call(API.jobsService.getBOJobById, action.payload);

			if (jobData.jobClass !== JobClassesEnum.PendingJob) {
				assessmentQuestions = yield call(
					API.assessmentService.getAssessmentByJobId,
					action.payload,
				);
			}

			yield put({
				type: TYPES.GET_BACKOFFICE_JOB_BY_ID.SUCCESS,
				payload: {
					...jobData,
					assessmentQuestions,
				},
			});
		} catch (error) {
			yield put({ type: TYPES.GET_BACKOFFICE_JOB_BY_ID.FAILED, payload: error });
		}
	}

	function* getBackOfficeApplicationMessageByClientIdSaga(action) {
		try {
			const applicationMessages = yield call(
				API.jobsService.getApplicationMessages,
				action.payload,
			);

			yield put({
				type: TYPES.GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID.SUCCESS,
				payload: applicationMessages,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID.FAILED, payload: error });
		}
	}

	function* createBackOfficeTicketSaga({ payload, callback }) {
		try {
			yield call(API.ticketsService.postBackOfficeTicket, payload);

			yield call(message.success, MESSAGES.successfullyCreatedBackOfficeTicket);

			yield put({ type: TYPES.CREATE_BACKOFFICE_TICKET.SUCCESS });

			callback && (yield call(callback));
		} catch (error) {
			yield call(message.error, MESSAGES.genericErrorMessage);
			yield put({ type: TYPES.CREATE_BACKOFFICE_TICKET.FAILED });
		}
	}

	function* updateBackOfficeTicketSaga({ payload, callback }) {
		try {
			yield call(API.ticketsService.updateBackOfficeTicket, payload);

			yield call(message.success, MESSAGES.successfullySavedChanges);

			yield put({ type: TYPES.UPDATE_BACKOFFICE_TICKET.SUCCESS });

			callback && (yield call(callback));
		} catch (error) {
			yield call(message.error, MESSAGES.genericErrorMessage);
			yield put({ type: TYPES.UPDATE_BACKOFFICE_TICKET.FAILED });
		}
	}

	function* updateBackOfficeTicketStatusSaga({ payload, callback }) {
		try {
			const updatedTicketDetails = yield call(
				API.ticketsService.updateBackOfficeTicketStatus,
				payload,
			);

			yield put({
				type: TYPES.UPDATE_BACKOFFICE_TICKET_STATUS.SUCCESS,
				payload: updatedTicketDetails,
			});

			yield call(message.success, MESSAGES.successfullyUpdated);

			callback && (yield call(callback));
		} catch (error) {
			yield call(message.error, MESSAGES.genericErrorMessage);
			yield put({ type: TYPES.UPDATE_BACKOFFICE_TICKET_STATUS.FAILED });
		}
	}

	function* getInvoiceStatusesSaga() {
		try {
			const invoiceStatuses = yield call(API.invoicesService.getInvoiceStatuses);

			yield put({ type: TYPES.GET_INVOICE_STATUSES.SUCCESS, payload: invoiceStatuses });
		} catch (error) {
			yield put({ type: TYPES.GET_INVOICE_STATUSES.FAILED, payload: error });
		}
	}

	function* getInvoicePaymentMethodsSaga() {
		try {
			const invoicePaymentMethods = yield call(API.invoicesService.getInvoicePaymentMethods);

			yield put({
				type: TYPES.GET_INVOICE_PAYMENT_METHODS.SUCCESS,
				payload: invoicePaymentMethods,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_INVOICE_PAYMENT_METHODS.FAILED, payload: error });
		}
	}

	function* updateInvoiceStatusSaga({ payload, callback }) {
		try {
			const updatedInvoice = yield call(API.invoicesService.updateInvoiceStatus, payload);

			yield put({
				type: TYPES.UPDATE_INVOICE_STATUS.SUCCESS,
				payload: updatedInvoice,
			});

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyUpdated);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_INVOICE_STATUS.FAILED, payload: error });
		}
	}

	function* getBackOfficeContractByIdSaga({ payload, cb }) {
		try {
			const contractDetails = yield call(API.contractsService.getBackOfficeContractById, payload);
			if (!contractDetails?.sentContractEmails?.recipientEmails) {
				const client = yield call(API.clientsService.getClientById, contractDetails.client);
				contractDetails.sentContractEmails.recipientEmails = client.email;
			}

			yield put({
				type: TYPES.GET_BACKOFFICE_CONTRACT_BY_ID.SUCCESS,
				payload: contractDetails,
			});

			if (cb) {
				yield call(cb, contractDetails);
			}
		} catch (error) {
			yield put({ type: TYPES.GET_BACKOFFICE_CONTRACT_BY_ID.FAILED });
		}
	}

	function* updateContractSaga(action) {
		try {
			const { payload, cb } = action;

			const preparedContractFieldsValues = {
				..._.pick(payload, contractChangesFormAllowedFields),
			};

			const contract = yield call(API.contractsService.updateBackOfficeContractById, {
				contractId: payload.id,
				updatedData: preparedContractFieldsValues,
			});

			yield put({ type: TYPES.UPDATE_CONTRACT.SUCCESS, payload: contract });

			yield call(message.success, MESSAGES.successfullyUpdatedContract);

			yield call(cb);
			yield put({ type: commonDucks.commonActionTypes.GET_CONTRACTS.REQUESTED });
		} catch (error) {
			yield put({ type: TYPES.CREATE_CONTRACT.FAILED, payload: error });
		}
	}

	function* changeUserPasswordSaga({ payload, callback }) {
		try {
			yield call(API.usersService.updateUserPassword, payload);

			yield put({
				type: TYPES.CHANGE_USER_PASSWORD.SUCCESS,
			});

			yield call(message.success, MESSAGES.successfullyUpdated);

			yield call(callback);
		} catch (error) {
			yield put({ type: TYPES.CHANGE_USER_PASSWORD.FAILED });
		}
	}

	function* createBackOfficeUserSaga({ payload, isClientContext, callback }) {
		try {
			yield call(
				isClientContext ? API.usersService.createClientContextUser : API.usersService.createUser,
				payload,
			);

			yield put({ type: TYPES.CREATE_BO_USER.SUCCESS });

			message.success(MESSAGES.successfullyCreated);

			yield call(callback);
		} catch (e) {
			yield put({ type: TYPES.CREATE_BO_USER.FAILED });
		}
	}

	function* getBackOfficeUserByIdSaga({ payload, isClientContext }) {
		try {
			const user = yield call(
				isClientContext
					? API.usersService.getClientContextUserById
					: API.usersService.getBOUserById,
				payload,
			);

			yield put({
				type: TYPES.GET_BO_USER_BY_ID.SUCCESS,
				payload: user,
			});
		} catch (e) {
			yield put({ type: TYPES.GET_BO_USER_BY_ID.FAILED });
		}
	}

	function* updateBackOfficeUserByIdSaga({ payload, isClientContext, callback }) {
		try {
			yield call(
				isClientContext ? API.usersService.updateClientContextUser : API.usersService.updateBOUser,
				payload,
			);

			yield put({ type: TYPES.UPDATE_BO_USER.SUCCESS });

			message.success(MESSAGES.successfullyUpdated);

			yield call(callback);
		} catch (e) {
			yield put({ type: TYPES.UPDATE_BO_USER.FAILED });
		}
	}

	function* getBackOfficeUsersSaga({ payload, isClientContext }) {
		try {
			const users = yield call(
				isClientContext ? API.usersService.getClientContextUsers : API.usersService.getBOUsers,
				payload,
			);

			if (!!payload?.searchKeyword && !users?.totalCount) {
				yield message.warning(MESSAGES.warningNotFoundClients);
			}

			yield put({ type: TYPES.GET_BO_USERS.SUCCESS, payload: users });
		} catch (e) {
			yield put({ type: TYPES.GET_BO_USERS.FAILED });
		}
	}

	function* getBackOfficeUserRolesSaga({ isClientContext }) {
		try {
			const userRoles = yield call(
				isClientContext ? API.usersService.getAtsUserRole : API.usersService.getBOUserRoles,
			);

			yield put({ type: TYPES.GET_BO_USER_ROLES.SUCCESS, payload: userRoles });
		} catch (e) {
			yield put({ type: TYPES.GET_BO_USER_ROLES.FAILED });
		}
	}

	function* getBackOfficeUserManagersSaga() {
		try {
			const userManagers = yield call(API.usersService.getBOUserManagers);

			yield put({ type: TYPES.GET_BO_USER_MANAGERS.SUCCESS, payload: userManagers });
		} catch (e) {
			yield put({ type: TYPES.GET_BO_USER_MANAGERS.FAILED });
		}
	}

	function* getBoContractsByClientIdSaga(action) {
		try {
			const contracts = yield call(API.contractsService.getBoClientContractsById, action.payload);

			yield put({ type: TYPES.GET_BO_CONTRACTS_BY_CLIENT_ID.SUCCESS, payload: contracts });
		} catch (error) {
			yield put({ type: TYPES.GET_BO_CONTRACTS_BY_CLIENT_ID.FAILED, payload: error });
		}
	}

	function* deActivateBackOfficeUserSaga({ payload, isClientContext, callback }) {
		try {
			const { id, active } = payload;
			yield call(
				isClientContext
					? API.usersService.activationAtsUserById
					: API.usersService.activationBOUserById,
				id,
				{ active },
			);

			yield put({ type: TYPES.ACTIVATE_BO_USER.SUCCESS });

			message.success(active ? MESSAGES.userActivated : MESSAGES.userDeleted);

			yield call(callback);
		} catch (e) {
			yield put({ type: TYPES.ACTIVATE_BO_USER.FAILED });
		}
	}

	function* getClientContextInterviewsListSaga(action) {
		try {
			const interviewsList = yield call(
				API.interviewService.getClientContextInterviewList,
				action.payload,
			);

			yield put({ type: TYPES.GET_CLIENT_CONTEXT_INTERVIEW_LIST.SUCCESS, payload: interviewsList });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_CONTEXT_INTERVIEW_LIST.FAILED, payload: error });
		}
	}

	function* getCandidateStatisticsBOSaga(action) {
		try {
			const candidateStatistics = yield call(
				API.statisticsService.getBOCandidateStatistics,
				action.payload,
			);

			yield put({
				type: TYPES.GET_CANDIDATE_STATISTICS_BO.SUCCESS,
				payload: candidateStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_CANDIDATE_STATISTICS_BO.FAILED,
				payload: error,
			});
		}
	}

	function* getClientContextDashboardUnreadEmailsSaga(action) {
		try {
			const candidateStatistics = yield call(
				API.emailService.getBOClientContextDashboardsEmailUnreadList,
				action.payload,
			);

			yield put({
				type: TYPES.GET_CLIENT_CONTEXT_DASHBOARD_UNREAD_EMAILS.SUCCESS,
				payload: candidateStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_CLIENT_CONTEXT_DASHBOARD_UNREAD_EMAILS.FAILED,
				payload: error,
			});
		}
	}

	function* getBOApplicationMonthStatisticSaga({ payload }) {
		try {
			const data = yield call(API.statisticsService.getBOCCMonthStatistic, payload);

			yield put({ type: TYPES.GET_JOBS_MONTH_STATISTIC_BO.SUCCESS, payload: data });
		} catch (error) {
			yield put({ type: TYPES.GET_JOBS_MONTH_STATISTIC_BO.FAILED, payload: error });
		}
	}

	function* getClientsShortSaga({ payload }) {
		try {
			const { isParentClient } = payload;

			const data = yield call(API.backOfficeService.getClientsShort, isParentClient);

			yield put({ type: TYPES.GET_CLIENTS_SHORT.SUCCESS, payload: data });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENTS_SHORT.FAILED, payload: error });
		}
	}

	function* getClientByIdShortSaga(action) {
		try {
			const clientId = action.payload;
			const { cb } = action;
			const clientData = yield call(API.clientsService.getClientById, clientId);

			yield put({ type: TYPES.GET_CLIENT_BY_ID_SHORT.SUCCESS, payload: clientData });
			cb && (yield call(cb, clientData));
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_BY_ID_SHORT.FAILED, payload: error });
		}
	}

	function* boostJobSaga(action) {
		try {
			const jobId = action.payload;
			const { cb } = action;

			yield call(API.jobsService.boostBOPaidJob, { id: jobId });

			yield put({ type: TYPES.BOOST_JOB_BO.SUCCESS });

			cb && (yield call(cb));

			yield call(message.success, MESSAGES.successfullyBoostedJob);
		} catch (error) {
			yield put({ type: TYPES.BOOST_JOB_BO.FAILED, payload: error });
		}
	}

	function* addNoteToTicketBOSaga({ payload, callback }) {
		try {
			const updatedTicketDetails = yield call(API.ticketsService.addBackOfficeTicketNote, payload);

			yield put({
				type: TYPES.ADD_NOTE_TO_TICKET_BO.SUCCESS,
				payload: updatedTicketDetails,
			});

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyAddedNote);
		} catch (error) {
			yield call(message.error, MESSAGES.genericErrorMessage);
			yield put({ type: TYPES.ADD_NOTE_TO_TICKET_BO.FAILED });
		}
	}

	function* getBODashboardInsightsSaga() {
		try {
			const boDashboardInsights = yield call(API.backOfficeService.getDashboardsInsights);

			yield put({ type: TYPES.GET_BO_DASHBOARD_INSIGHTS.SUCCESS, payload: boDashboardInsights });
		} catch (error) {
			yield put({ type: TYPES.GET_BO_DASHBOARD_INSIGHTS.FAILED, payload: error });
		}
	}

	return {
		getClients,
		getBackofficeClientsSaga,
		getContextClientByIdSaga,
		getClientContextClientsListSaga,
		getClientsSubs,
		getClientsRegions,
		getClientsByRegionSaga,
		createContractSaga,
		createInvoiceSaga,
		updateInvoiceSaga,
		createAndUpdateClientChangesFormSaga,
		getCurrentClientAndSaveChangesFormSaga,
		getBackofficeInvoicesSaga,
		deleteInvoiceByIdSaga,
		deleteTicketByIdSaga,
		getCurrentInvoiceSaga,
		getAllBackOfficeJobsSaga,
		getBackOfficeCandidatesSaga,
		getBackOfficeJobByIdSaga,
		getBackOfficeApplicationMessageByClientIdSaga,
		getBackofficeTicketsSaga,
		getBackofficeTicketDetailsSaga,
		getCreditsDefaultPricesSaga,
		updateCreditsDefaultPricesSaga,
		getSubsDefaultPricesSaga,
		updateSubsDefaultPricesSaga,
		getClientSubsInfoSaga,
		createBackOfficeTicketSaga,
		updateBackOfficeTicketSaga,
		updateBackOfficeTicketStatusSaga,
		getBackOfficeContractByIdSaga,
		getInvoiceStatusesSaga,
		getInvoicePaymentMethodsSaga,
		updateInvoiceStatusSaga,
		updateContractSaga,
		changeUserPasswordSaga,
		getBackOfficeUserByIdSaga,
		createBackOfficeUserSaga,
		updateBackOfficeUserByIdSaga,
		getBackOfficeUsersSaga,
		getBackOfficeUserRolesSaga,
		getBackOfficeUserManagersSaga,
		deActivateBackOfficeUserSaga,
		getBoContractsByClientIdSaga,
		getClientContextInterviewsListSaga,
		getCandidateStatisticsBOSaga,
		getClientContextDashboardUnreadEmailsSaga,
		getBOApplicationMonthStatisticSaga,
		getClientsShortSaga,
		getClientByIdShortSaga,
		boostJobSaga,
		addNoteToTicketBOSaga,
		getBODashboardInsightsSaga,
	};
};
