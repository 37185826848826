import React, { type FC } from 'react';
import { Field } from 'react-final-form';

import { InboxOutlined } from '@ant-design/icons';
import { UploadFile, UploadProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import DownloadIcon from 'components/SVG/DownloadIcon';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { COLORS } from 'theme';
import { textValidator } from 'utils/validators';

import { Styled } from './HRBoardingBlock.styled';

type HRBoardingBlockProps = {
	blockTitle: string;
	fieldName: string;
	boardingDraggerProps: ({
		onChange,
		value,
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) => UploadProps<unknown>;
	handleDownload: (file: UploadFile) => void;
};

const HRBoardingBlock: FC<HRBoardingBlockProps> = ({
	blockTitle,
	fieldName,
	boardingDraggerProps,
	handleDownload,
}) => {
	return (
		<Styled.Block>
			<Styled.BlockTitle>{blockTitle}</Styled.BlockTitle>
			<FormBlockLine columns={1}>
				<Field name={`${fieldName}Description`} validate={textValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							name={`${fieldName}Description`}
							label='Description'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<TextArea
								{...input}
								placeholder={`Add ${blockTitle.toLocaleLowerCase()} details`}
								autoSize={{ minRows: 8, maxRows: 8 }}
							/>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>
			<FormBlockLine columns={1}>
				<Field name={`${fieldName}attachments`}>
					{({ input, meta }) => (
						<FieldWrapper
							isFixed
							name={`${fieldName}attachments`}
							label='Attachments'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<Styled.Dragger
								{...boardingDraggerProps(input)}
								listType='text'
								onPreview={handleDownload}
								showUploadList={{
									showDownloadIcon: true,
									downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
								}}
							>
								<p className='ant-upload-drag-icon'>
									<InboxOutlined />
								</p>
								<p className='ant-upload-text'>Upload a files or drag and drop it</p>
								<p className='ant-upload-hint'>
									{' '}
									PDF, DOC, DOCX, XLS, XLSX, CSV, JPEG, PNG up to 15MB{' '}
								</p>
							</Styled.Dragger>
						</FieldWrapper>
					)}
				</Field>
			</FormBlockLine>
			<Field name={`${fieldName}DefaultTemplateToggle`}>
				{({ input }) => <FormSwitch {...input} title='Use as Default Template' />}
			</Field>
		</Styled.Block>
	);
};

export default HRBoardingBlock;
