export const HR_MANAGER_ROLE_ID = 12;

export const EMPLOYEE_ROLE_ID = 10;

export const AllowedSUProfileFields = [
	'photo',
	'firstName',
	'lastName',
	'birthdayDate',
	'personalPhone',
	'workPhone',
	'email',
	'postCode',
	'country',
	'city',
	'address',
	'startDate',
	'leaveDate',
	'jobTitle',
	'departmentName',
	'emergencyContactFullName',
	'emergencyContactPhone',
	'password'
];

export const AllowedNonSUProfileFields = [
	'photo',
	'firstName',
	'lastName',
	'birthdayDate',
	'personalPhone',
	'postCode',
	'country',
	'city',
	'address',
	'emergencyContactFullName',
	'emergencyContactPhone',
	'password'
];
