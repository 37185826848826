import React, { useState } from 'react';
import { connect } from 'react-redux';

import useMedia from 'use-media';

import { ButtonTypes } from 'components/Button/Button.types';
import { IUser } from 'modules/Common/types';
import HRRequestEditTimeOffModal from 'modules/HR/components/HRRequestEditTimeOffModal';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import HRDashboard from 'modules/HR/containers/HRDashboard';
import { unregisteredDucks } from 'modules/Unregistered/ducks';

import { availableTypeOffDataMock } from './DashboardPage.mock';
import { Styled } from './DashboardPage.styled';

const DashboardPage = ({ userInfo }: { userInfo: IUser }) => {
	const [isModalOpen, setIsModalOpen] = useState<number | null>(null);
	const isSuperUser = !!userInfo?.hrAdmin;
	const userId = userInfo?.userId;
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	const handleOpenModal = () => {
		setIsModalOpen(userId);
	};

	const handleClosedModal = () => {
		setIsModalOpen(null);
	};

	return (
		<Styled.Root isMobile={isMobile}>
			{!isMobile && (
				<Styled.Head>
					<Styled.Title>Dashboard</Styled.Title>
					<HRThemedButton
						buttonType={ButtonTypes.primary}
						type='button'
						onClick={() => handleOpenModal(userId)}
					>
						Request Time Off
					</HRThemedButton>
				</Styled.Head>
			)}
			<HRDashboard isSuperUser={isSuperUser} userId={userId} handleOpenModal={handleOpenModal} />
			<HRRequestEditTimeOffModal
				employeeId={isModalOpen}
				onCancel={handleClosedModal}
				handlePostTimeOffEvent={() => undefined}
				handleEditTimeOffEvent={() => undefined}
				loading={false}
				availableTypeOffData={availableTypeOffDataMock}
			/>
		</Styled.Root>
	);
};

export default connect((state) => ({
	userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
}))(DashboardPage);
