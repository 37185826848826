import styled from 'styled-components/macro';

import { device } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
		padding: 24px 0 40px;
	`;

	export const DashboardLayout = styled.div``;

	export const LayoutColumns = styled.div<{ isMobile: boolean }>`
		display: flex;
		justify-content: space-between;
		gap: 24px;
		${({ isMobile }) =>
		isMobile &&
			`
				flex-direction: column;
				padding: 0 0 24px;
			`}
	`;

	export const LeftColumn = styled.div<{ isMobile: boolean }>`
		width: 66.8%;
		min-width: 380px;
		${({ isMobile }) =>
		isMobile &&
			`
				width: 100%;
			`}
		@media ${device.tablet} {
			width: 100%;
		}
	`;

	export const RightColumn = styled.div<{ isMobile: boolean }>`
		width: 32.15%;
		display: flex;
		flex-direction: column;
		gap: 24px;
		${({ isMobile }) =>
		isMobile &&
			`
				width: 100%;
			`}
	`;
}
