export const customHolidaysListMock = [
	{
		id: 1,
		holidayName: 'Company Picnic',
		holidayDate: '2024-07-04',
		holidayDescription: 'some test description',
		isEveryYear: false,
	},
	{
		id: 2,
		holidayName: 'Christmas',
		holidayDate: '2024-12-25',
		holidayDescription: null,
		isEveryYear: true,
	},
	{
		id: 3,
		holidayName: 'New Year',
		holidayDate: '2025-01-01',
		holidayDescription: null,
		isEveryYear: true,
	},
];

export const bankHolidayThisYearMock = [
	{
		id: 1,
		holidayName: 'Early May bank holiday',
		holidayDate: '2024-05-06',
	},
	{
		id: 2,
		holidayName: 'Spring bank holiday',
		holidayDate: '2024-05-27',
	},
	{
		id: 3,
		holidayName: 'Summer bank holiday',
		holidayDate: '2024-08-26',
	},
	{
		id: 4,
		holidayName: 'Christmas Day',
		holidayDate: '2024-12-25',
	},
	{
		id: 5,
		holidayName: 'Boxing Day',
		holidayDate: '2024-12-26',
	},
];

export const EmployeeListOptionMock = [
	{
		value: 1,
		label: 'Liam Lopez',
	},
	{
		value: 2,
		label: 'Ava Taylor',
	},
	{
		value: 3,
		label: 'Andrew Robertson',
	},
	{
		value: 4,
		label: 'Kim Donald',
	},
];

export const VacationDaysMock = [
	{ id: 1, employeeId: 3, days: 40 },
	{ id: 2, employeeId: 1, days: 0 },
	{ id: 3, employeeId: 2, days: 0 },
];
