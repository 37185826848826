import dayjs from 'dayjs';

export const formatDateRange = (startDate: string, endDate: string) => {
	const start = dayjs(startDate);
	const end = dayjs(endDate);

	if (start.isSame(end, 'day')) {
		return start.format('MMM D, YYYY');
	} else if (start.isSame(end, 'month')) {
		return `${start.format('MMM D')} - ${end.format('D, YYYY')}`;
	} else if (start.isSame(end, 'year')) {
		return `${start.format('MMM D')} - ${end.format('MMM D, YYYY')}`;
	} else {
		return `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}`;
	}
};
