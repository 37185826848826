export enum ListActionEnums {
	ADD = 'ADD',
	DELETE = 'DELETE',
	UPDATE = 'UPDATE',
}

export type CustomHolidayEventType = {
	id: number;
	holidayName: string;
	holidayDate: string;
	isEveryYear: boolean;
};

export type BankHolidayEventType = {
	id: number;
	holidayName: string;
	holidayDate: string;
};

export type VacationLeaveDayType = {
	id: number;
	employeeId: number;
	days: number;
};

export type EmployeeOptions = {
	value: number;
	label: string;
};
