import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import Spinner from 'components/Spinner';
import { IEmployeeShortValues } from 'modules/Common/types/employee';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import { Routes } from 'modules/HR/routes/types';

import UserIcon from '../../../Common/components/UserIcon';

import { Styled } from './HRViewEmployeesDashboard.styled';

type HRViewEmployeesDashboardProps = {
	employeeList: IEmployeeShortValues[];
	loading: boolean;
};

const HRViewEmployeesDashboard: FC<HRViewEmployeesDashboardProps> = ({ employeeList, loading }) => {
	const navigate = useNavigate();
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	if (loading) {
		return <Spinner fixed />;
	}

	const handleSeeAllEmployeeList = () => {
		navigate(`${Routes.HRModule}${Routes.PeopleManagement}`);
	};

	return (
		<Styled.Root isMobile={isMobile}>
			<Styled.Header>
				<Styled.Title>Employees</Styled.Title>
				{!isMobile && (
					<Styled.SeeMoreButton type='link' htmlType='button' onClick={handleSeeAllEmployeeList}>
						View all
					</Styled.SeeMoreButton>
				)}
			</Styled.Header>
			{employeeList?.length === 0 ? (
				<Styled.NoEmployees>
					<p>There are no employees currently</p>
				</Styled.NoEmployees>
			) : (
				<Styled.EmployeeList>
					{employeeList?.map((employee) => {
						return (
							<Link to={`${Routes.HRModule}${Routes.Employee}/${employee.id}`} key={employee.id}>
								<Styled.EmployeeWrapper>
									<UserIcon imageId={employee.photo} backgroundColor='grey' />
									<Styled.EmployeeFullName>{`${employee.firstName} ${employee.lastName}`}</Styled.EmployeeFullName>
									{employee.isNew && <Styled.NewEmployeeBadge>New</Styled.NewEmployeeBadge>}
								</Styled.EmployeeWrapper>
							</Link>
						);
					})}
				</Styled.EmployeeList>
			)}
		</Styled.Root>
	);
};

export default HRViewEmployeesDashboard;
