import { type FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useMedia from 'use-media';

import Container from 'components/Container';
import Footer from 'modules/Common/components/Footer';
import Header from 'modules/Common/components/Header';
import { IUser } from 'modules/Common/types';
import { ClientBrandingDataType } from 'modules/Common/types/branding';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';
import { Routes, SettingsRoutes } from 'modules/HR/routes/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { UserRolesType, Routes as CommonRoutes } from 'types';

import { HRLayoutProvider } from '../HRLayoutContext/HRLayoutContext';

import { HrMenuList } from './HrModuleHeader.constants';
import { S } from './HrModuleLayout.styled';
import MobileHeader from './MobileHeader';

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

type HrModuleLayoutProps = {
	user: IUser;
	roles: UserRolesType;
	logout: (p: () => void) => void;
	children: React.ReactNode;
	isImpersonate: boolean;
	brandingData: ClientBrandingDataType;
};

export const HrModuleLayout: FC<HrModuleLayoutProps> = ({
	user,
	roles,
	logout,
	children,
	isImpersonate,
	brandingData,
}) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	const fullWidthRoute = pathname?.includes(`${CommonRoutes.BOClientContext}`);
	const isSuperUser = !!user?.hrAdmin;
	const logoUrl = brandingData?.logo?.split('/').includes('images')
		? brandingData?.logo
		: brandingData?.logo && `${baseUrl}${brandingData?.logo}`;

	const UserMenuList = useMemo(
		() => [
			{
				id: 0,
				name: 'Edit personal profile ',
				handleClick: () => {
					navigate(SettingsRoutes.UserDetails);
				},
			},
			{
				id: 0,
				name: 'Settings',
				handleClick: () => {
					navigate(`${Routes.HRModule}${Routes.Settings}`);
				},
			},
			{
				id: 1,
				name: 'Logout',
				handleClick: () => {
					logout(() => navigate(CommonRoutes.Login));
				},
			},
		],
		[logout, navigate],
	);

	return (
		<HRLayoutProvider subscriptionPlans={{ isPremiumPlan: true }} clientBrandingData={brandingData}>
			<S.Root isImpersonating={isImpersonate} isMobile={isMobile}>
				{isMobile ? (
					<MobileHeader user={user} logout={logout} />
				) : (
					<Header
						user={user}
						subMenuItems={UserMenuList}
						menuItems={HrMenuList(isSuperUser)}
						logoUrl={logoUrl}
						isHrModule
						brandingData={brandingData}
					/>
				)}
				<S.Main isMobile={isMobile}>
					<Container noSpaces fullWidth={fullWidthRoute}>
						{children}
					</Container>
					{!isMobile && <Footer />}
				</S.Main>
			</S.Root>
		</HRLayoutProvider>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state)?.id,
	}),
	{
		logout: unregisteredDucks.unregisteredActions.logOut,
	},
)(HrModuleLayout);
