import React, { useEffect, useState, type FC } from 'react';
import { Field } from 'react-final-form';
import PhoneInput from 'react-phone-number-input';

import { Input, Select, UploadProps, DatePicker } from 'antd';

import Button, { ButtonTypes } from 'components/Button';
import FieldWrapper from 'components/FieldWrapper';
import DeleteIcon from 'components/SVG/DeleteIcon';
import { IOption } from 'types';
import { fetchEmployeePhotoWithCredentials } from 'utils/helpers';
import {
	composeValidators,
	emailValidator,
	phoneValidator,
	requiredFieldValidator,
	textValidator,
} from 'utils/validators';

import Cropper from '../Cropper/Cropper';

import { Styled } from './PersonalDetails.styled';

type PersonalDetailsProps = {
	photo: string | null;
	rolesList: IOption[];
	isFieldDisabledForEdit: boolean;
	isRoleFieldVisible: boolean;
	photoProfileUploaderProps: (
		fieldOnchange: (fileName: string | null) => null,
	) => UploadProps<unknown>;
	handleSetProfilePhoto: (photoId: string) => void;
	handleDeletePhoto: () => void;
	isEditMode: boolean;
	disabledFields?: string[];
};

export const PersonalDetails: FC<PersonalDetailsProps> = ({
	photo,
	rolesList,
	photoProfileUploaderProps,
	handleSetProfilePhoto,
	handleDeletePhoto,
	isFieldDisabledForEdit,
	isRoleFieldVisible,
	isEditMode,
	disabledFields,
}) => {
	const [imageUrl, setImageUrl] = useState<string>('');

	const birthdayDataFormat = 'MM/DD/YYYY';

	useEffect(() => {
		fetchEmployeePhotoWithCredentials(photo, setImageUrl);
	}, [photo]);

	return (
		<Styled.Wrap>
			{imageUrl ? (
				<Styled.PersonProfilePhoto imageUrl={imageUrl}>
					<Button
						buttonType={ButtonTypes.primary}
						type='button'
						icon={<DeleteIcon width='12' height='14' />}
						onClick={handleDeletePhoto}
					/>
				</Styled.PersonProfilePhoto>
			) : (
				<Styled.PersonProfileCropper>
					<Cropper
						customRequest={photoProfileUploaderProps?.customRequest}
						setBgUrl={handleSetProfilePhoto}
						proportionAspect={1}
					/>
				</Styled.PersonProfileCropper>
			)}
			<Styled.FormBlockWrap title='Personal Details'>
				<Styled.FormBlockLine>
					<Field
						name='firstName'
						validate={composeValidators(requiredFieldValidator, textValidator)}
					>
						{({ input, meta }) => (
							<>
								<FieldWrapper
									isFixed
									name='firstName'
									label='First Name'
									required
									errorMessage={meta.submitFailed && meta.touched && meta.error}
								>
									<Input {...input} placeholder='First Name' autoComplete='off' />
								</FieldWrapper>
							</>
						)}
					</Field>
					<Field
						name='lastName'
						validate={composeValidators(requiredFieldValidator, textValidator)}
					>
						{({ input, meta }) => (
							<>
								<FieldWrapper
									isFixed
									name='lastName'
									label='Last Name'
									required
									errorMessage={meta.submitFailed && meta.touched && meta.error}
								>
									<Input {...input} placeholder='Last Name' autoComplete='off' />
								</FieldWrapper>
							</>
						)}
					</Field>
					{isRoleFieldVisible && (
						<Field name='role' validate={requiredFieldValidator}>
							{({ input, meta }) => (
								<FieldWrapper
									isFixed
									name='role'
									label='Role'
									required
									errorMessage={meta.submitFailed && meta.touched && meta.error}
								>
									<Select
										{...input}
										value={input.value || null}
										options={rolesList}
										allowClear
										disabled={isEditMode}
										placeholder='Please select role'
										fieldNames={{ label: 'value', value: 'id' }}
									/>
								</FieldWrapper>
							)}
						</Field>
					)}
					<Field name='birthdayDate'>
						{({ input }) => (
							<FieldWrapper name='birthdayDate' label='Birthday'>
								<DatePicker
									{...input}
									format={birthdayDataFormat}
									picker='date'
									inputReadOnly={false}
									disabled={disabledFields?.includes('birthdayDate')}
								/>
							</FieldWrapper>
						)}
					</Field>
					<Field name='personalPhone' validate={composeValidators(phoneValidator)}>
						{({ input, meta }) => (
							<FieldWrapper
								isFixed
								name='personalPhone'
								label='Personal Phone Number'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Styled.PhoneFieldWrapper>
									<PhoneInput international {...input} defaultCountry={'GB'} />
								</Styled.PhoneFieldWrapper>
							</FieldWrapper>
						)}
					</Field>
					<Field name='workPhone' validate={phoneValidator}>
						{({ input, meta }) => (
							<FieldWrapper
								isFixed
								name='workPhone'
								label='Work Phone Number'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Styled.PhoneFieldWrapper>
									<PhoneInput
										international
										{...input}
										defaultCountry={'GB'}
										disabled={isFieldDisabledForEdit || disabledFields?.includes('workPhone')}
									/>
								</Styled.PhoneFieldWrapper>
							</FieldWrapper>
						)}
					</Field>
					<Field name='email' validate={composeValidators(requiredFieldValidator, emailValidator)}>
						{({ input, meta }) => (
							<>
								<FieldWrapper
									isFixed
									name='email'
									label='Work email'
									required
									errorMessage={meta.submitFailed && meta.touched && meta.error}
								>
									<Input
										{...input}
										placeholder='e.g. workmail@mail.co.uk'
										autoComplete='off'
										disabled={isFieldDisabledForEdit || disabledFields?.includes('workPhone')}
									/>
								</FieldWrapper>
							</>
						)}
					</Field>
				</Styled.FormBlockLine>
			</Styled.FormBlockWrap>
		</Styled.Wrap>
	);
};
