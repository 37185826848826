import {
	DEFAULT_ATS_BG,
	DEFAULT_CAREER_BG,
	DEFAULT_LOGO,
	DEFAULT_CURRENT_PAGE,
	DEFAULT_PAGE_SIZE,
	TimeOptions,
} from 'modules/Common/constants';

export const initialState = {
	clientsState: {
		clientsData: [],
		clientsShortData: [],
		clientsPaginatedData: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
	},
	clientContextState: {
		clientContextData: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
		selectedListData: [],
		contextClientData: {},
	},
	clientContracts: [],
	clientsSubs: [],
	currentClient: {},
	regions: [],
	creditsDefaultPrices: [],
	subsDefaultPrice: {},
	invoices: {
		data: [],
		pageIndex: DEFAULT_CURRENT_PAGE,
		pageSize: DEFAULT_PAGE_SIZE,
		totalCount: 0,
	},
	invoiceStatuses: [],
	invoicePaymentMethods: [],
	currentInvoice: null,
	clientsByRegion: [],
	tickets: {
		data: [],
		pageIndex: DEFAULT_CURRENT_PAGE,
		pageSize: DEFAULT_PAGE_SIZE,
		totalCount: 0,
	},
	ticketDetails: null,
	interviewsList: [],
	currentContractDetails: null,
	currentClientShortData: {},
	clientChangesFormState: {
		isSaved: true,
		data: {
			logo: DEFAULT_LOGO,
			freeTrial: false,
			creditChecked: false,
			chargeVatOnContracts: false,
			interviewReminder: true,
			sendSMSQuestionnaireReminder: true,
			sendRejectionLetter: true,
			sendThankyouLetter: true,
			enable2FA: false,
			questionnairesFunctionality: true,
			careersPageEnabled: true,
			candidatesDatabaseEnabled: true,
			hrModule: true,
			requireUpfrontPayment: false,
			defaultPaymentTermsUnit: TimeOptions[0].value,
			atsColors: {
				textColour: '#1E1E20',
				headerBackground: '#540AD1',
				highlightColour: '#314EFF',
				highlightColourAlternate: '#262834',
				accentColour: '#99A7FF',
			},
			careerPageColors: {
				textColour: '#1E1E20',
				headerBackground: '#540AD1',
				highlightColourAlternate: '#262834',
				accentColour: '#99A7FF',
				highlightColour: '#540AD1',
			},
			careerBranding: {
				logo: DEFAULT_LOGO,
				backgroundImage: DEFAULT_CAREER_BG,
				socialNetworks: {
					linkedIn: '',
					instagram: '',
					facebook: '',
					youtube: '',
					twitter: '',
				},
				benefits: [],
			},
			atsBranding: {
				logo: DEFAULT_LOGO,
				backgroundImage: DEFAULT_ATS_BG,
			},
			subsInfo: null,
		},
	},
	jobsState: {
		data: [],
		pageIndex: DEFAULT_CURRENT_PAGE,
		pageSize: DEFAULT_PAGE_SIZE,
		totalCount: 0,
	},
	userState: {
		usersList: {
			data: [],
			pageIndex: DEFAULT_CURRENT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
		userRoles: [],
		userManagers: [],
		currentUser: null,
	},
	jobState: {
		jobData: {},
	},
	applicationMessagesState: {},
	candidatesState: {
		candidatesData: {
			data: [],
			pageIndex: 0,
			pageSize: DEFAULT_PAGE_SIZE,
			totalCount: 0,
		},
	},
	statistics: {
		candidateStatistics: [],
		jobsMonthStatistic: null,
	},
	unreadEmails: {},
	dashboardInsights: {},
};
