import { Key } from 'react';

import { TagColoursType } from './common';

export enum TicketsStatusEnum {
	New = 'New',
	InProgress = 'InProgress',
	Resolved = 'Resolved',
}

export enum TicketsStatusValuesEnum {
	New = 'New',
	InProgress = 'In Progress',
	Resolved = 'Resolved',
}

export enum TicketsStatusColors {
	New = TagColoursType.Blue,
	InProgress = TagColoursType.Orange,
	Resolved = TagColoursType.Green,
}

export interface ITicketsTableValues {
	key?: Key;
	id: number;
	client: string;
	creationDate: string;
	resolutionDate: string;
	subject: string;
	ticketStatus: TicketsStatusEnum;
	ticketType: string;
}

export interface ITicketDetails {
	id: number;
	client: {
		id: number;
		name: string;
	};
	description: string;
	user: string;
	visibleToClient: boolean;
	subject: string;
	creationDate: string;
	ticketStatus: {
		id: number;
		name: string;
		value: string;
	};
	ticketType: {
		id: number;
		name: string;
		value: string;
	};
	assessmentQuestions: IAssessmentQuestions[] | null;
	jobId: number | null;
	upgrades: IUpgrades[];
	applicationFormUrl: string | null;
	attachments: IAttachments[];
	createdByClient: boolean;
	activities: IActivityNotes[];
	notes: ITicketNote[];
}

export enum ActivityTypeEnum {
	ChangeStatus = 'CHANGE_STATUS',
	Note = 'NOTE',
}

interface IStatus {
	id: number;
	name: string;
	value: string;
}

interface IBaseActivity {
	id: number;
	date: string;
	user: {
		fullName: string;
		id: number;
	};
}

interface IChangeStatusActivity extends IBaseActivity {
	activityType: ActivityTypeEnum.ChangeStatus;
	fromStatus: IStatus;
	toStatus: IStatus;
}

export type IActivityNotes = IChangeStatusActivity;

export interface ITicketNote {
	id: number;
	sender: {
		id: number;
		fullName: string;
	};
	created: string;
	text: string;
	isVisibleToClient: boolean;
}

interface IAttachments {
	originalFileName: string;
	referenceUuid: string;
}

interface IAssessmentQuestions {
	id: number;
	questionType: string;
	questionText: string;
	orderInAssessment: number;
	assessmentAnswers: IAssessmentAnswers[];
	job: number;
}

interface IAssessmentAnswers {
	id: number;
	answer: string;
}

interface IUpgrades {
	id: number;
	name: UpgradeNameEnum;
}

enum UpgradeNameEnum {
	TheGuardian = 'The Guardian',
	Indeed = 'Indeed',
	HandFilteredCVs = 'Hand Filtered CVs',
}

export interface IPostTicketValues {
	subject: string;
	description: number;
	ticketType: number;
	attachments: string[];
}

export interface IPostTicketBackOfficeValues {
	subject: string;
	description: string;
	attachments: string[];
	visibleToClient: boolean;
	ticketType: number;
	client: number;
	ticketStatus: number;
}
