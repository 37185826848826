export const statisticDummyData = {
	vacationsUsed: 5,
	vacationsByDefault: 10,
	sickLeaveUsed: 2,
	sickLeaveByDefault: 10,
	parentalUsed: 0,
	parentalByDefault: 10,
	otherUsed: 1,
	otherByDefault: 10,
};

export const employeeRequestOffDummyData = [
	{
		id: 2,
		startDate: '2024-08-10',
		endDate: '2024-08-14',
		hours: 40,
		type: 'VACATION',
		firstDayType: 'start',
		lastDayType: 'end',
		description: 'Family Trip',
		client: 1002,
		employee: 102,
		employeeFirstName: 'Jane',
		employeeLastName: 'Smith',
		employeePhoto: '',
		approvedBy: 202,
		declinedBy: false,
		createdBy: 302,
		approved: false,
		created: '2024-07-22',
		declined: null,
		attachments: [
			{
				id: 2,
				fileName: 'hotel_reservation.pdf',
				url: 'https://example.com/attachments/hotel_reservation.pdf',
			},
		],
	},
	{
		id: 3,
		startDate: '2024-09-05',
		endDate: '2024-09-07',
		hours: 24,
		type: 'SICK LEAVE',
		firstDayType: 'start',
		lastDayType: 'end',
		description: 'Medical Leave',
		client: 1003,
		employee: 103,
		employeeFirstName: 'Michael',
		employeeLastName: 'Johnson',
		employeePhoto: '',
		approvedBy: 203,
		declinedBy: false,
		createdBy: 303,
		approved: true,
		created: '2024-08-30',
		declined: null,
		attachments: [
			{
				id: 3,
				fileName: 'doctor_note.pdf',
				url: 'https://example.com/attachments/doctor_note.pdf',
			},
		],
	},
	{
		id: 5,
		startDate: '2024-11-15',
		endDate: '2024-11-18',
		hours: 32,
		type: 'VACATION',
		firstDayType: 'start',
		lastDayType: 'end',
		description: 'Weekend Getaway',
		client: 1005,
		employee: 105,
		employeeFirstName: 'David',
		employeeLastName: 'Williams',
		employeePhoto: '',
		approvedBy: 205,
		declinedBy: false,
		createdBy: 305,
		approved: true,
		created: '2024-11-01',
		declined: null,
		attachments: [
			{
				id: 5,
				fileName: 'getaway_plan.pdf',
				url: 'https://example.com/attachments/getaway_plan.pdf',
			},
		],
	},
	{
		id: 6,
		startDate: '2024-12-20',
		endDate: '2024-12-27',
		hours: 56,
		type: 'VACATION',
		firstDayType: 'start',
		lastDayType: 'end',
		description: 'Christmas Holiday',
		client: 1006,
		employee: 106,
		employeeFirstName: 'Sarah',
		employeeLastName: 'Brown',
		employeePhoto: '',
		approvedBy: 206,
		declinedBy: false,
		createdBy: 306,
		approved: true,
		created: '2024-12-05',
		declined: null,
		attachments: [
			{
				id: 6,
				fileName: 'holiday_schedule.pdf',
				url: 'https://example.com/attachments/holiday_schedule.pdf',
			},
		],
	},
];

export const HRViewEmployeesShortDummyData = [
	{
		id: 1,
		photo: 'fa007b44-fb00-484c-8a34-fbfedab5b9d1',
		firstName: 'John',
		lastName: 'Doe',
		status: { id: 2, name: 'Current', value: 'CURRENT' },
		isNew: true,
	},
	{
		id: 2,
		photo: '',
		firstName: 'Jane',
		lastName: 'Smith',
		status: { id: 1, name: 'Pending', value: 'PENDING' },
		isNew: true,
	},
	{
		id: 3,
		photo: '',
		firstName: 'Alice',
		lastName: 'Johnson',
		status: { id: 2, name: 'Current', value: 'CURRENT' },
		isNew: false,
	},
	{
		id: 4,
		photo: '',
		firstName: 'Robert',
		lastName: 'Brown',
		status: { id: 3, name: 'Leaving', value: 'LEAVING' },
		isNew: false,
	},
	{
		id: 5,
		photo: '',
		firstName: 'Emily',
		lastName: 'Davis',
		status: { id: 1, name: 'Pending', value: 'PENDING' },
		isNew: true,
	},
	{
		id: 6,
		photo: 'ae4560da-8495-4e1e-8fa5-f1c946848a8e',
		firstName: 'Michael',
		lastName: 'Wilson',
		status: { id: 2, name: 'Current', value: 'CURRENT' },
		isNew: false,
	},
	{
		id: 7,
		photo: '',
		firstName: 'Jessica',
		lastName: 'Garcia',
		status: { id: 3, name: 'Inactive', value: 'INACTIVE' },
		isNew: true,
	},
	{
		id: 8,
		photo: '',
		firstName: 'David',
		lastName: 'Martinez',
		status: { id: 1, name: 'Pending', value: 'PENDING' },
		isNew: false,
	},
	{
		id: 9,
		photo: '',
		firstName: 'Sarah',
		lastName: 'Rodriguez',
		status: { id: 2, name: 'Current', value: 'CURRENT' },
		isNew: true,
	},
	{
		id: 10,
		photo: '',
		firstName: 'Christopher',
		lastName: 'Hernandez',
		status: { id: 3, name: 'Inactive', value: 'INACTIVE' },
		isNew: false,
	},
	{
		id: 11,
		photo: '',
		firstName: 'Laura',
		lastName: 'Lopez',
		status: { id: 2, name: 'Current', value: 'CURRENT' },
		isNew: true,
	},
	{
		id: 12,
		photo: '',
		firstName: 'Daniel',
		lastName: 'Gonzalez',
		status: { id: 1, name: 'Pending', value: 'PENDING' },
		isNew: false,
	},
	{
		id: 13,
		photo: '',
		firstName: 'Elizabeth',
		lastName: 'Perez',
		status: { id: 2, name: 'Current', value: 'CURRENT' },
		isNew: true,
	},
	{
		id: 14,
		photo: '',
		firstName: 'James',
		lastName: 'Sanchez',
		status: { id: 3, name: 'Inactive', value: 'INACTIVE' },
		isNew: false,
	},
	{
		id: 15,
		photo: '',
		firstName: 'Ashley',
		lastName: 'Clark',
		status: { id: 1, name: 'Pending', value: 'PENDING' },
		isNew: true,
	},
];
