export const addBackOfficeTicketNoteMock = {
	id: 735,
	client: {
		id: 6,
		name: 'Dev Team',
	},
	visibleToClient: true,
	createdByClient: true,
	ticketType: {
		id: 2,
		name: 'Job Posting',
		value: 'JOB_POSTING',
	},
	ticketStatus: {
		id: 1,
		name: 'New',
		value: 'NEW',
	},
	subject: 'Test',
	description: 'Test',
	attachments: [
		{
			originalFileName: 'DEVT18282-invoice.pdf',
			referenceUuid: '21240e57-b463-46d3-9ae7-3bbe040b79fe',
		},
		{
			originalFileName: 'DEVT2012-invoice.pdf',
			referenceUuid: 'd0b1f819-8ce7-4d9c-98b7-7187f1061157',
		},
	],
	user: 'Pavlo Kadysh',
	creationDate: '2024-09-02T09:45:03Z',
	jobId: null,
	notes: [
		{
			id: 83,
			sender: {
				id: 35,
				fullName: 'Pavlo Kadysh',
			},
			created: '2024-09-24T13:06:30Z',
			text: 'Note added!',
			isVisibleToClient: false,
		},
		{
			id: 84,
			sender: {
				id: 35,
				fullName: 'Pavlo Kadysh',
			},
			created: '2024-09-25T13:06:44Z',
			text: 'One more note!',
			isVisibleToClient: true,
		},
	],
	activities: [
		{
			id: 132,
			user: {
				id: 31,
				fullName: 'Pavlo Kadysh',
			},
			date: '2024-09-23T12:52:45Z',
			fromStatus: {
				id: 1,
				name: 'New',
				value: 'NEW',
			},
			toStatus: {
				id: 2,
				name: 'In progress',
				value: 'IN_PROGRESS',
			},
			activityType: 'CHANGE_STATUS',
		},
		{
			id: 133,
			user: {
				id: 31,
				fullName: 'Pavlo Kadysh',
			},
			date: '2024-09-26T13:04:55Z',
			fromStatus: {
				id: 2,
				name: 'In progress',
				value: 'IN_PROGRESS',
			},
			toStatus: {
				id: 1,
				name: 'New',
				value: 'NEW',
			},
			activityType: 'CHANGE_STATUS',
		},
	],
};
