import React, { useCallback, useState, type FC } from 'react';

import useMedia from 'use-media';

import { useMount } from 'hooks';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';

import EmployeeView from './EmployeeView/EmployeeView';
import { Styled } from './HRDashboard.styled';
import { HRDashboardProps, RequestTabType } from './HRDashboard.types';
import {
	employeeRequestOffDummyData,
	HRViewEmployeesShortDummyData,
	statisticDummyData,
} from './HRDashboardDummyData';
import HRDashboardMobileComponents from './HRDashboardMobileComponents/HRDashboardMobileComponents';
import HRView from './HRView/HRView';

const HRDashboard: FC<HRDashboardProps> = ({ isSuperUser, userId, handleOpenModal }) => {
	const [selectedTab, setSelectedTab] = useState<RequestTabType>('requests');
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);
	const employeeData = HRViewEmployeesShortDummyData;
	const statisticData = statisticDummyData;
	const employeeRequests = employeeRequestOffDummyData;

	useMount(() => {
		// getEmployeeData();
		// getStatisticData();
		// getRequests();
	});

	const handleMobileTabChange = useCallback((tab: RequestTabType) => {
		setSelectedTab(tab);
	}, []);

	const handleApproveAllRequests = () => {
		// approveAllRequests();
	};

	const showRequestTab = !isMobile || selectedTab === 'requests';
	const showCalendarTab = !isMobile || selectedTab === 'calendar';

	return (
		<Styled.Root>
			{isMobile && (
				<HRDashboardMobileComponents
					selectedTab={selectedTab}
					handleTabChange={handleMobileTabChange}
					employeeRequestsNumber={employeeRequests.length}
					handleOpenModal={handleOpenModal}
				/>
			)}
			{isSuperUser ? (
				<HRView
					showRequestTab={showRequestTab}
					showCalendarTab={showCalendarTab}
					statisticData={statisticData}
					employeeData={employeeData}
					employeeRequests={employeeRequests}
					handleApproveAllRequests={handleApproveAllRequests}
				/>
			) : (
				<EmployeeView
					showRequestTab={showRequestTab}
					showCalendarTab={showCalendarTab}
					statisticData={statisticData}
					employeeRequests={employeeRequests}
					employeeViewUpcomingVacationsRequests={employeeRequests}
					userId={userId}
				/>
			)}
		</Styled.Root>
	);
};

export default HRDashboard;
