import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Calendar,
	DateFormat,
	DateLocalizer,
	momentLocalizer,
	ToolbarProps,
	type View,
	Views,
} from 'react-big-calendar';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment-timezone';
import useMedia from 'use-media';

import Box from 'components/Box';
import Container from 'components/Container';
import { CalendarEventType, DateType, HRCalendarProps } from 'modules/Common/types/hrModuleTypes';
import { assignColorsToIds } from 'modules/Common/utils/commonHelpers';
import HRCalendarCustomDateHeader from 'modules/HR/components/HRCalendarCustomDateHeader';
import {
	CalendarColorsByTypes,
	CalendarEmojisByTypes,
} from 'modules/HR/constants/HRModuleConstants.constants';
import { paletteColors } from 'theme/colors';

import { hrCalendarDataFromAPI } from '../HRCalendar/HRCalendarEventsData';

import HRCalendarCustomEvent from './HRCalendarCustomEvent';
import { HRCalendarCustomToolbar } from './HRCalendarCustomToolbar/HRCalendarCustomToolbar';
import { Styled } from './HRCalendarWeekly.styled';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import WeeklyCalendarMobile from './HRCalendarWeeklyMobile';

const defaultTZ = moment.tz.guess();

moment.updateLocale('en', {
	week: {
		dow: 1,
		doy: 1,
	},
});

const getStartEndDate = (date: Date, view: View) => {
	const rangeDate = {
		[Views.WEEK]: [moment(date).startOf('week').toDate(), moment(date).endOf('week').toDate()],
	} as never;

	return rangeDate && rangeDate[view];
};

const HRCalendarWeekly: FC<HRCalendarProps> = () => {
	const [events, setEvents] = useState<CalendarEventType[] | []>([]);
	const [filterValue, setFilterValue] = useState('all');
	const data = hrCalendarDataFromAPI;
	const isDesktopView = useMedia({ minWidth: '768px' });

	const {
		defaultDate,
		defaultView,
		scrollToTime,
		views,
		formats,
		getNow,
		localizerDate,
		validateTypeView,
	} = useMemo(() => {
		moment.tz.setDefault(defaultTZ);

		return {
			defaultDate: moment().toDate(),
			defaultView: Views.WEEK,
			getNow: () => moment().toDate(),
			localizerDate: momentLocalizer(moment),
			formats: {
				dayFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
					localizer.format(date, 'ddd DD', culture),
				dayRangeHeaderFormat: (
					{ start, end }: DateType,
					culture: string,
					localizer: DateLocalizer,
				): DateFormat =>
					localizer.format(start, 'MMM ', culture) +
					localizer.format(start, 'D', culture) +
					' - ' +
					localizer.format(end, 'D', culture) +
					localizer.format(start, ', YYYY', culture),
			},
			scrollToTime: moment().toDate(),
			views: [Views.WEEK],
			validateTypeView: (date: Date[] | DateType, view: View) => {
				let fromDate;
				let toDate;

				if (Array.isArray(date)) {
					toDate = moment(date[0]);
					fromDate = moment(date[0]);
				} else {
					toDate = new Date();
					fromDate = new Date();
				}

				return {
					fromDate: moment(fromDate.toString())?.format('YYYY-MM-DD'),
					toDate: moment(toDate.toString())?.format('YYYY-MM-DD'),
				};
			},
		};
	}, []);

	const [currentDate, setDate] = useState<Date>(defaultDate);

	const handleFilterChange = useCallback((value: string) => {
		return setFilterValue(value);
	}, []);

	const handleEventDecline = useCallback((id: number) => {
		return;
	}, []);

	const handleEventApprove = useCallback((id: number) => {
		return;
	}, []);

	const handleEventEdit = useCallback((id: number) => {
		return;
	}, []);

	const components = useMemo(
		() => ({
			event: (props: React.JSX.IntrinsicAttributes & { event: CalendarEventType }) => (
				<HRCalendarCustomEvent
					{...props}
					handleEventDecline={handleEventDecline}
					handleEventApprove={handleEventApprove}
					handleEventEdit={handleEventEdit}
				/>
			),
			toolbar: (toolbarProps: ToolbarProps) => (
				<HRCalendarCustomToolbar
					{...toolbarProps}
					filterValue={filterValue}
					handleFilterChange={handleFilterChange}
				/>
			),
			// eslint-disable-next-line camelcase
			week: {
				header: HRCalendarCustomDateHeader,
			},
			dateCellWrapper: (props) => {
				return props.children;
			},
		}),
		[
			// timeOffRequestList,
			filterValue,
		],
	);

	// navigate in the calendar (ADD UPDATE)
	const handleNavigate = useCallback(
		(date: Date, view: View) => {
			const currentRange = getStartEndDate(date, view);
			const { fromDate, toDate } = currentRange && validateTypeView(currentRange, view);
			setDate(date);

			if (fromDate && toDate) {
				// getTimeOffRequestList({ fromDate, toDate });
			}
		},
		[
			currentDate,
			setDate,
			// getTimeOffRequestList
		],
	);

	const handleCalendarChange = useCallback((date: Date[] | DateType, view: View) => {
		const { fromDate, toDate } = validateTypeView(date, view);

		if (fromDate && toDate) {
			// getTimeOffRequestList({ fromDate, toDate });
		}
	}, []);

	const eventPropGetter = useCallback((event: CalendarEventType) => {
		const showDefaultColors = !(event.isPending || event.isDeclined);

		return {
			style: {
				backgroundColor: showDefaultColors
					? event?.colors?.bgColor || paletteColors.lightBlue
					: 'transparent',
				color: showDefaultColors ? event?.colors?.color || paletteColors.blue : paletteColors.red,
				border: showDefaultColors ? 'none' : `1px solid ${paletteColors.red}`,
				borderRadius: '6px',
			},
		};
	}, []);

	useEffect(() => {
		const colorMapping = assignColorsToIds(data, ['employeeId']);

		const filteredEvents =
			filterValue === 'all' ? data : data?.filter((event) => event.type === filterValue);

		const mappedEvents = filteredEvents.map((i) => ({
			id: i.id,
			employeeId: i.employeeId,
			start: moment(i.startDate).toDate(),
			end: moment(i.endDate).toDate(),
			hours: i.hours,
			type: i.type,
			eventName: i?.eventName,
			firstDayType: i.firstDayType,
			lastDayType: i.lastDayType,
			description: i.description,
			isApproved: i.isApproved,
			isDeclined: i.isDeclined,
			isPending: i.isPending,
			employeeFullName: i.employeeFullName,
			employeePhotoUuid: i.employeePhotoUuid,
			comments: i.comments,
			colors: CalendarColorsByTypes[i.type],
			icon: CalendarEmojisByTypes[i.type],
			iconBackgroundColor: colorMapping[i.employeeId] || paletteColors.lightBlue,
		}));

		setEvents(mappedEvents);
	}, [
		// timeOffRequestList,
		currentDate,
		filterValue,
	]);

	return (
		<Styled.Root>
			{isDesktopView && (
				<Container noSpaces fullWidth>
					<Box>
						<Calendar
							date={currentDate}
							defaultDate={defaultDate}
							defaultView={defaultView}
							localizer={localizerDate}
							components={components}
							events={events}
							eventPropGetter={eventPropGetter}
							getNow={getNow}
							onNavigate={(date, view) => handleNavigate(date, view)}
							someProp={filterValue}
							onRangeChange={(range, view) => view && handleCalendarChange(range, view)}
							scrollToTime={scrollToTime}
							formats={formats}
							views={views}
							startAccessor='start'
							endAccessor='end'
							style={{ height: '420px', minHeight: '200px', maxHeight: 'calc(100vh - 284px)' }}
							step={1440}
							timeslots={1}
							allDayAccessor={() => true}
							popup
						/>
					</Box>
				</Container>
			)}
			{!isDesktopView && <WeeklyCalendarMobile data={events} />}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		// timeOffRequestList: hrDucks.hrSelectors.getTimeOffRequestList(state),
	}),
	{
		// getTimeOffRequestList: hrDucks.hrSelectors.getTimeOffRequestListRequested,
	},
)(HRCalendarWeekly);
