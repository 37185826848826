import React, { useCallback, useMemo, type FC } from 'react';
import { Field, Form } from 'react-final-form';

import { Input, DatePicker, Checkbox } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { requiredFieldValidator } from 'utils/validators';

import { generateUniqueId } from '../HRModuleSettings.helpers';
import { CustomHolidayEventType, ListActionEnums } from '../HRModuleSettings.types';

import { Styled } from './AddEditCustomHolidayModal.styled';

type AddEditCustomHolidayModalProps = {
	isOpen: number | boolean;
	currentEventList: CustomHolidayEventType[];
	loading?: boolean;
	onCancel: () => void;
	handleUpdateList: (bankHoliday: CustomHolidayEventType, action: ListActionEnums) => void;
};

const prepareInitialValues = (values: CustomHolidayEventType | undefined) => {
	if (!values) return {};

	return {
		...values,
		holidayDate: dayjs(values.holidayDate),
	};
};

export const AddEditCustomHolidayModal: FC<AddEditCustomHolidayModalProps> = ({
	isOpen,
	currentEventList,
	loading,
	onCancel,
	handleUpdateList,
}) => {
	const isEditMode = typeof isOpen === 'number';

	const initialValues = useMemo(
		() => ({
			holidayName: '',
			holidayDate: '',
			holidayDescription: '',
			isEveryYear: true,
			...(isEditMode
				? prepareInitialValues(currentEventList.find((event) => event.id === isOpen))
				: {}),
		}),
		[isOpen],
	);

	const onSubmit = useCallback(
		(values: CustomHolidayEventType) => {
			const valuesToSubmit = {
				...values,
				holidayDate: dayjs(values.holidayDate).format('YYYY-MM-DD'),
				...(!isEditMode && { id: generateUniqueId() }),
			};
			const updateAction = isEditMode ? ListActionEnums.UPDATE : ListActionEnums.ADD;
			handleUpdateList(valuesToSubmit, updateAction);
			onCancel();
		},
		[isOpen],
	);

	const handleDelete = useCallback(() => {
		handleUpdateList({ id: isOpen as number }, ListActionEnums.DELETE);
		onCancel();
	}, [isOpen]);

	return (
		<Styled.Modal open={!!isOpen} footer={null} centered onCancel={onCancel} width='auto'>
			<Styled.ModalHead>
				<h3>{isEditMode ? 'Edit Custom Holiday' : 'Add Custom Holiday'}</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					autoComplete='off'
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Styled.FieldsBlock>
								<Field name='holidayName' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='holidayName'
											label='Holiday Name'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
										>
											<Input {...input} placeholder='e.g. Company B-Day' autoComplete='off' />
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.FieldsBlock>
								<Field name='holidayDate' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='holidayDate'
											label='Day'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
										>
											<DatePicker
												{...input}
												format='MMMM DD'
												picker='date'
												placeholder='DD/MM/YYYY'
											/>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.FieldsBlock>
								<Field name='holidayDescription'>
									{({ input, meta }) => (
										<FieldWrapper
											name='holidayDescription'
											label='Description'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<TextArea
												{...input}
												placeholder='Enter holiday description '
												autoSize={{ minRows: 8, maxRows: 8 }}
											/>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.FieldsBlock>
								<Field name='isEveryYear' type='checkbox'>
									{({ input, meta }) => (
										<FieldWrapper name='isEveryYear'>
											<Checkbox {...input}>Every Year</Checkbox>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.ButtonBox>
								<HRThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									disabled={submitting || loading}
									loading={submitting || loading}
									// disabled={loading.submitButtonLoading}
									// loading={!!loading.settingsLoading}
								>
									Save Holiday
								</HRThemedButton>
								{isEditMode && (
									<Button
										type='button'
										buttonType={ButtonTypes.danger}
										disabled={submitting || loading}
										loading={submitting || loading}
										onClick={handleDelete}
									>
										Remove
									</Button>
								)}
								<HRThemedButton type='button' buttonType={ButtonTypes.tertiary} onClick={onCancel}>
									Cancel
								</HRThemedButton>
							</Styled.ButtonBox>
						</form>
					)}
				/>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
