import React, { useCallback, useMemo, useState, type FC } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { UploadFile } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import { useMount } from 'hooks';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { hrDucks } from 'modules/HR/ducks';
import { GenericType } from 'types';

import { AddEditCustomHolidayModal } from './AddEditCustomHolidayModal/AddEditCustomHolidayModal';
import { AddEditCustomTimeOffModal } from './AddEditCustomTimeOffModal/AddEditCustomTimeOffModal';
import { BankHolidaysModal } from './BankHolidaysModal/BankHolidaysModal';
import HRBoardingBlock from './HRBoardingBlock/HRBoardingBlock';
import HRHolidayAbsencePolicyBlock from './HRHolidayAbsencePolicyBlock/HRHolidayAbsencePolicyBlock';
import { getDraggerProps, handleDownload, transformEmployeeList, updateListHelper } from './HRModuleSettings.helpers';
import {
	bankHolidayThisYearMock,
	customHolidaysListMock,
	EmployeeListOptionMock,
	VacationDaysMock,
} from './HRModuleSettings.mocks';
import { Styled } from './HRModuleSettings.styled';
import {
	BankHolidayEventType,
	CustomHolidayEventType,
	ListActionEnums,
	VacationLeaveDayType,
} from './HRModuleSettings.types';

type HRModuleSettingsProps = {
	loading: GenericType;
};

interface IPostSettingsValues {
	onboardingDescription: string;
	offBoardingDescription: string;
}

const employeeList = EmployeeListOptionMock;

const HRModuleSettings: FC<HRModuleSettingsProps> = ({ loading }) => {
	const [customHolidays, setCustomHolidays] =
		useState<CustomHolidayEventType[]>(customHolidaysListMock);
	const [bankHolidays, setBankHolidays] = useState<BankHolidayEventType[]>(bankHolidayThisYearMock);
	const [vacationLeaveDays, setVacationLeaveDays] =
		useState<VacationLeaveDayType[]>(VacationDaysMock);
	const [onboardingFileList, setOnboardingFileList] = useState<UploadFile[]>([]);
	const [offBoardingFileList, setOffBoardingFileList] = useState<UploadFile[]>([]);
	const [isAddEditCustomHolidayModalVisible, setIsAddEditCustomHolidayModalVisible] = useState<
		boolean | number
	>(false);
	const [isBankHolidaysModalVisible, setIsBankHolidaysModalVisible] = useState<boolean>(false);
	const [employeesObj, setEmployeesObj] = useState(transformEmployeeList(employeeList));
	const [isAddEditVacationsModalVisible, setIsAddEditVacationsModalVisible] = useState<
		boolean | number
	>(false);

	const handleUpdateCustomHolidays = useCallback(
		(customHoliday: CustomHolidayEventType, action: ListActionEnums) => {
			setCustomHolidays((prevBankHolidays) =>
				updateListHelper(prevBankHolidays, customHoliday, action),
			);
		},
		[],
	);

	const handleUpdateVacationLeaveDays = useCallback(
		(vacation: VacationLeaveDayType, action: ListActionEnums) => {
			setVacationLeaveDays((prevVacationLeaveDays) =>
				updateListHelper(prevVacationLeaveDays, vacation, action),
			);
		},
		[],
	);

	const initialValues = useMemo(
		() => ({
			onboardingDescription: '',
			offBoardingDescription: '',
			workingDaysMonday: true,
			workingDaysTuesday: true,
			workingDaysWednesday: true,
			workingDaysThursday: true,
			workingDaysFriday: true,
			workingDaysSaturday: false,
			workingDaysSunday: false,
			country: 'UK',
			standardVacationLeaveDays: 20,
			onboardingDefaultTemplateToggle: true,
			offBoardingDefaultTemplateToggle: true,
		}),
		[],
	);

	useMount(() => {
		// getBankHolidaysRequested();
		// getVacationLeaveDaysRequested();
	});

	const onSubmit = (values: IPostSettingsValues) => {
		// console.log('Form Values', values);
	};

	const onboardingDraggerProps = getDraggerProps(onboardingFileList, setOnboardingFileList);
	const offBoardingDraggerProps = getDraggerProps(offBoardingFileList, setOffBoardingFileList);

	const handleOpenAddEditCustomModal = (id?: number) => {
		setIsAddEditCustomHolidayModalVisible(id || true);
	};

	const handleCloseAddEditCustomModal = () => {
		setIsAddEditCustomHolidayModalVisible(false);
	};

	const toggleBankHolidaysModal = () => {
		setIsBankHolidaysModalVisible((prev) => !prev);
	};

	const handleOpenAddEditVacationsModal = (id?: number) => {
		setIsAddEditVacationsModalVisible(id || true);
	};

	const handleCloseAddEditVacationsModal = () => {
		setIsAddEditVacationsModalVisible(false);
	};

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				render={({ handleSubmit, dirty, values, form }) => {
					return (
						<form onSubmit={handleSubmit}>
							<HRHolidayAbsencePolicyBlock
								customHolidays={customHolidays}
								vacationLeaveDays={vacationLeaveDays}
								employeesList={employeesObj}
								handleViewBankHolidays={toggleBankHolidaysModal}
								handleUpdateCustomHolidays={handleOpenAddEditCustomModal}
								handleUpdateVacationLeaveDays={handleOpenAddEditVacationsModal}
							/>
							<HRBoardingBlock
								blockTitle='Onboarding'
								fieldName='onboarding'
								boardingDraggerProps={onboardingDraggerProps}
								handleDownload={handleDownload}
							/>
							<HRBoardingBlock
								blockTitle='Off-Boarding'
								fieldName='offBoarding'
								boardingDraggerProps={offBoardingDraggerProps}
								handleDownload={handleDownload}
							/>
							<Styled.ButtonBox>
								<HRThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									// disabled={loading.submitButtonLoading}
									// loading={!!loading.settingsLoading}
								>
									Save Setting
								</HRThemedButton>
							</Styled.ButtonBox>
						</form>
					);
				}}
			/>
			<AddEditCustomHolidayModal
				isOpen={isAddEditCustomHolidayModalVisible}
				currentEventList={customHolidays}
				onCancel={handleCloseAddEditCustomModal}
				handleUpdateList={handleUpdateCustomHolidays}
			/>
			<BankHolidaysModal
				isOpen={isBankHolidaysModalVisible}
				bankHolidaysList={bankHolidays}
				onCancel={toggleBankHolidaysModal}
			/>
			<AddEditCustomTimeOffModal
				isOpen={isAddEditVacationsModalVisible}
				employeeList={employeeList}
				currentVacationList={vacationLeaveDays}
				onCancel={handleCloseAddEditVacationsModal}
				handleUpdateList={handleUpdateVacationLeaveDays}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
	}),
	{},
)(HRModuleSettings);
