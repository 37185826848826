import React, { type FC } from 'react';
import { Field } from 'react-final-form';

import { Input, Select } from 'antd';
import countryList from 'country-list';

import FieldWrapper from 'components/FieldWrapper';

import FormBlockLine from '../FormBlockLine';

import { Styled } from './AddressDetails.styled';

const { Option } = Select;

export const AddressDetails: FC = () => {
	const countries = countryList.getData();
	const customFilterOption = (input: string, option?: { children: React.ReactNode }) => {
		return option?.children?.toLowerCase().includes(input.toLowerCase());
	};

	return (
		<Styled.FormBlockWrap title='Address'>
			<FormBlockLine>
				<Field name='postCode'>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='postCode'
								label='Postcode'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input {...input} placeholder='Code' autoComplete='off' />
							</FieldWrapper>
						</>
					)}
				</Field>
				<Field name='country'>
					{({ input, meta }) => (
						<FieldWrapper
							name='country'
							label='Country'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<Select
								{...input}
								showSearch
								value={input.value || null}
								allowClear
								filterOption={customFilterOption}
								placeholder='Please  select country'
							>
								{countries.map((country) => (
									<Option key={country.code} value={country.name}>
										{country.name}
									</Option>
								))}
							</Select>
						</FieldWrapper>
					)}
				</Field>
				<Field name='city'>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='city'
								label='City'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input {...input} placeholder='Enter City' autoComplete='off' />
							</FieldWrapper>
						</>
					)}
				</Field>
				<Field name='address'>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='address'
								label='Address'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input {...input} placeholder='Street, building number' autoComplete='off' />
							</FieldWrapper>
						</>
					)}
				</Field>
			</FormBlockLine>
		</Styled.FormBlockWrap>
	);
};
