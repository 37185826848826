import { Select } from 'antd';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface WorkingDaysToggleProps {
	isActive: boolean;
}

export namespace Styled {
	export const Block = styled.section`
		border-radius: 10px;
		border: 1px solid ${COLORS.lightGray3};
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 24px 32px;
		margin-bottom: 24px;
	`;

	export const BlockTitle = styled.h2`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: -0.264px;
		margin-bottom: 24px;
	`;

	export const Section = styled.section`
		margin-bottom: 32px;
	`;

	export const SectionTitle = styled.h3`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		margin-bottom: 16px;
	`;

	export const WorkingDaysTogglesWrap = styled.div`
		display: flex;
		gap: 16px 32px;
		flex-wrap: wrap;
		margin-bottom: 32px;
	`;

	export const WorkingDaysToggle = styled.div<WorkingDaysToggleProps>`
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 10px;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 16px;
		min-width: 260px;
		width: 23%;
		background: ${({ isActive }) => (isActive ? COLORS.white : COLORS.lightGray3)};
		> div {
			display: flex;
			width: 100%;
			flex-direction: row-reverse;
			justify-content: space-between;
		}
		h4 {
			padding-left: 0;
			text-transform: uppercase;
		}
	`;

	export const SectionTopLine = styled.div`
		display: flex;
		align-items: center;
		gap: 32px;
		margin-bottom: 16px;

		input {
			height: 40px;
			line-height: 40px;
			width: 594px;
		}
	`;

	export const SelectAntd = styled(Select)`
		width: 360px;

		.ant-select-selector {
			height: 40px !important;
		}

		.ant-select-selection-item {
			min-height: 24px;
			line-height: 40px !important;
			height: auto;
		}

		.ant-select-selection-item-content {
			white-space: normal;
		}
	`;

	export const ListOfItems = styled.div`
		display: flex;
		flex-direction: column;
		gap: 16px;
	`;

	export const ListItem = styled.div`
		border-radius: 10px;
		border: 1px solid ${COLORS.lightGray2};
		background: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		padding: 12px 16px;
		display: flex;
		align-items: center;
		gap: 16px;
		max-width: 594px;
	`;

	export const ListItemTitle = styled.h6`
		color: ${COLORS.gray1};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
	`;

	export const ListItemDate = styled.p`
		color: ${COLORS.gray1};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 0;
	`;

	export const ListItemEditIcon = styled.div`
		margin-left: auto;
		svg path {
			fill: ${COLORS.darkGray2};
			transition: fill 0.3s;
		}
		&:hover {
			cursor: pointer;
			svg path {
				fill: ${COLORS.black};
			}
		}
	`;
}
