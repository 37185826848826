import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Calendar,
	DateFormat,
	DateLocalizer,
	momentLocalizer,
	ToolbarProps,
	type View,
	Views,
} from 'react-big-calendar';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment-timezone';

import Box from 'components/Box';
import Container from 'components/Container';
import { CalendarEventType, DateType, HRCalendarProps } from 'modules/Common/types/hrModuleTypes';
import { assignColorsToIds } from 'modules/Common/utils/commonHelpers';
import HRCalendarCustomDateHeader from 'modules/HR/components/HRCalendarCustomDateHeader';
import {
	CalendarColorsByTypes,
	CalendarEmojisByTypes,
} from 'modules/HR/constants/HRModuleConstants.constants';
import { paletteColors } from 'theme/colors';

import { Styled } from './HRCalendar.styled';
import HRCalendarCustomEvent from './HRCalendarCustomEvent';
import { HRCalendarCustomToolbar } from './HRCalendarCustomToolbar/HRCalendarCustomToolbar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { hrCalendarDataFromAPI } from './HRCalendarEventsData';

const defaultTZ = moment.tz.guess();

const getStartEndDate = (date: Date, view: View) => {
	const rangeDate = {
		[Views.MONTH]: {
			start: moment(date).startOf('month').toDate(),
			end: moment(date).endOf('month').toDate(),
		},
		[Views.WEEK]: [moment(date).startOf('week').toDate(), moment(date).endOf('week').toDate()],
		[Views.DAY]: [moment(date).toDate()],
	} as never;

	return rangeDate && rangeDate[view];
};

const HRCalendar: FC<HRCalendarProps> = () => {
	const [events, setEvents] = useState<CalendarEventType[] | []>([]);
	const [filterValue, setFilterValue] = useState('all');
	const [currentView, setCurrentView] = useState<View>(Views.MONTH);
	const data = hrCalendarDataFromAPI;

	const {
		defaultDate,
		defaultView,
		scrollToTime,
		views,
		formats,
		getNow,
		localizerDate,
		validateTypeView,
	} = useMemo(() => {
		moment.tz.setDefault(defaultTZ);

		return {
			defaultDate: moment().toDate(),
			defaultView: Views.MONTH,
			getNow: () => moment().toDate(),
			localizerDate: momentLocalizer(moment),
			formats: {
				dayFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
					localizer.format(date, 'ddd DD', culture),
				dayHeaderFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
					localizer.format(date, 'dddd MMMM Do', culture),
				dayRangeHeaderFormat: (
					{ start, end }: DateType,
					culture: string,
					localizer: DateLocalizer,
				): DateFormat =>
					localizer.format(start, 'MMM ', culture) +
					localizer.format(start, 'D', culture) +
					' - ' +
					localizer.format(end, 'D', culture) +
					localizer.format(start, ', YYYY', culture),
				timeGutterFormat: (date: Date, culture: string, localizer: DateLocalizer): DateFormat =>
					localizer.format(date, 'HH:mm', culture),
			},
			scrollToTime: moment().toDate(),
			views: [Views.MONTH, Views.WEEK, Views.DAY],
			validateTypeView: (date: Date[] | DateType, view: View) => {
				let fromDate;
				let toDate;

				if (Array.isArray(date) && view === Views.DAY) {
					toDate = moment(date[0]?.toString()).add(1, 'days');
					fromDate = moment(date[0]?.toString()).subtract(1, 'days');
				} else if (Array.isArray(date) && view === Views.WEEK) {
					toDate = date?.[date?.length - 1];
					fromDate = date?.[0];
				} else if ('start' in date && view === Views.MONTH) {
					fromDate = date?.start;
					toDate = date?.end;
				} else {
					toDate = new Date();
					fromDate = new Date();
				}

				return {
					fromDate: moment(fromDate.toString())?.format('YYYY-MM-DD'),
					toDate: moment(toDate.toString())?.format('YYYY-MM-DD'),
				};
			},
		};
	}, []);

	const [currentDate, setDate] = useState<Date>(defaultDate);

	const handleFilterChange = useCallback((value: string) => {
		return setFilterValue(value);
	}, []);

	const handleEventDecline = useCallback((id: number) => {
		return;
	}, []);

	const handleEventApprove = useCallback((id: number) => {
		return;
	}, []);

	const handleEventEdit = useCallback((id: number) => {
		return;
	}, []);

	const handleChangeView = useCallback((view: View) => {
		setCurrentView(view);
	}, []);

	const components = useMemo(
		() => ({
			event: (props: React.JSX.IntrinsicAttributes & { event: CalendarEventType }) => (
				<HRCalendarCustomEvent
					{...props}
					handleEventDecline={handleEventDecline}
					handleEventApprove={handleEventApprove}
					handleEventEdit={handleEventEdit}
				/>
			),
			toolbar: (toolbarProps: ToolbarProps) => (
				<HRCalendarCustomToolbar
					{...toolbarProps}
					filterValue={filterValue}
					handleFilterChange={handleFilterChange}
				/>
			),
			week: {
				header: HRCalendarCustomDateHeader,
			},
		}),
		[
			// timeOffRequestList,
			filterValue,
		],
	);

	const handleNavigate = useCallback(
		(date: Date, view: View) => {
			const currentRange = getStartEndDate(date, view);
			const { fromDate, toDate } = currentRange && validateTypeView(currentRange, view);
			setDate(date);

			if (fromDate && toDate) {
				// getTimeOffRequestList({ fromDate, toDate });
			}
		},
		[
			currentDate,
			setDate,
			// getTimeOffRequestList
		],
	);

	const handleCalendarChange = useCallback((date: Date[] | DateType, view: View) => {
		const { fromDate, toDate } = validateTypeView(date, view);

		if (fromDate && toDate) {
			// getTimeOffRequestList({ fromDate, toDate });
		}
	}, []);

	const eventPropGetter = useCallback((event: CalendarEventType) => {
		const showDefaultColors = !(event.isPending || event.isDeclined);

		return {
			style: {
				backgroundColor: showDefaultColors
					? event?.colors?.bgColor || paletteColors.lightBlue
					: 'transparent',
				color: showDefaultColors ? event?.colors?.color || paletteColors.blue : paletteColors.red,
				border: showDefaultColors ? 'none' : `1px solid ${paletteColors.red}`,
				borderRadius: '6px',
			},
		};
	}, []);

	useEffect(() => {
		const colorMapping = assignColorsToIds(data, ['employeeId']);

		const filteredEvents =
			filterValue === 'all' ? data : data?.filter((event) => event.type === filterValue);

		const mappedEvents = filteredEvents.map((i) => ({
			id: i.id,
			employeeId: i.employeeId,
			start: moment(i.startDate).toDate(),
			end: moment(i.endDate).toDate(),
			hours: i.hours,
			type: i.type,
			eventName: i?.eventName,
			firstDayType: i.firstDayType,
			lastDayType: i.lastDayType,
			description: i.description,
			isApproved: i.isApproved,
			isDeclined: i.isDeclined,
			isPending: i.isPending,
			employeeFullName: i.employeeFullName,
			employeePhotoUuid: i.employeePhotoUuid,
			comments: i.comments,
			colors: CalendarColorsByTypes[i.type],
			icon: CalendarEmojisByTypes[i.type],
			iconBackgroundColor: colorMapping[i.employeeId] || paletteColors.lightBlue,
		}));

		setEvents(mappedEvents);
	}, [
		// timeOffRequestList,
		currentDate,
		filterValue,
	]);

	return (
		<Styled.Root isWeekView={currentView === Views.WEEK}>
			<Container noSpaces fullWidth>
				<Box>
					<Calendar
						date={currentDate}
						defaultDate={defaultDate}
						defaultView={defaultView}
						localizer={localizerDate}
						components={components}
						events={events}
						eventPropGetter={eventPropGetter}
						getNow={getNow}
						onNavigate={(date, view) => handleNavigate(date, view)}
						someProp={filterValue}
						onRangeChange={(range, view) => view && handleCalendarChange(range, view)}
						scrollToTime={scrollToTime}
						formats={formats}
						views={views}
						onView={(view) => handleChangeView(view)}
						startAccessor='start'
						endAccessor='end'
						style={{ height: 700, minHeight: '100%', maxHeight: 'calc(100vh - 284px)' }}
						step={120}
						popup
						showMultiDayTimes
						allDayAccessor={() => currentView === Views.WEEK}
					/>
				</Box>
			</Container>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		// timeOffRequestList: hrDucks.hrSelectors.getTimeOffRequestList(state),
	}),
	{
		// getTimeOffRequestList: hrDucks.hrSelectors.getTimeOffRequestListRequested,
	},
)(HRCalendar);
