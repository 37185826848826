import dayjs from 'dayjs';

import {
	EmployeeStatusEnum,
	EmployeeStatusTypesFormEnum,
	IEmployeeState,
} from 'modules/Common/types/employee';

export const getAvailableStatusesOptionsForEmployee = (
	currentEmployeeStatus: IEmployeeState,
	allStatuses: IEmployeeState[],
) => {
	if (currentEmployeeStatus.name === EmployeeStatusEnum.PENDING) return allStatuses;
	if (currentEmployeeStatus.name === EmployeeStatusEnum.CURRENT)
		return allStatuses.filter((statusOption) => statusOption.name !== EmployeeStatusEnum.PENDING);
	if (currentEmployeeStatus.name === EmployeeStatusEnum.LEAVING)
		return allStatuses.filter((statusOption) => statusOption.name !== EmployeeStatusEnum.PENDING);

	if (currentEmployeeStatus.name === EmployeeStatusEnum.LEFT)
		return allStatuses.filter(
			(statusOption) =>
				statusOption.name !== EmployeeStatusEnum.PENDING &&
				statusOption.name !== EmployeeStatusEnum.LEAVING,
		);
};

export const disabledDateHelper = (current: string, endingStatus: EmployeeStatusTypesFormEnum) => {
	const now = dayjs().startOf('day');
	if (
		endingStatus === EmployeeStatusTypesFormEnum.Current ||
		endingStatus === EmployeeStatusTypesFormEnum.Left
	) {
		return !(current && dayjs(current).startOf('day').isSameOrBefore(now));
	}
	if (endingStatus === EmployeeStatusTypesFormEnum.Leaving) {
		return !(current && dayjs(current).startOf('day').isAfter(now));
	}

	return true;
};

export const getDateLabelName = (
	startStatus: EmployeeStatusTypesFormEnum,
	endStatus: EmployeeStatusTypesFormEnum,
) => {
	if (endStatus === EmployeeStatusTypesFormEnum.Left) {
		return 'Leaving Date';
	}
	if (
		endStatus === EmployeeStatusTypesFormEnum.Current &&
		startStatus === EmployeeStatusTypesFormEnum.Pending
	) {
		return 'Start Date';
	}
	if (endStatus === EmployeeStatusTypesFormEnum.Leaving) {
		return 'Leaving Date';
	}

	return '';
};

const colors = [
	'#FF5733',
	'#33FF57',
	'#3357FF',
	'#FF33A1',
	'#A133FF',
	'#33FFA1',
	'#FF8C33',
	'#8C33FF',
	'#33FF8C',
	'#FF3333',
	'#33FF33',
	'#3333FF',
	'#FF9933',
	'#33FF99',
	'#9933FF',
	'#FF3399',
	'#9933FF',
	'#33FF99',
	'#FFCC33',
	'#33FFCC',
	'#CC33FF',
	'#FF33CC',
	'#CC33FF',
	'#33FFCC',
	'#FF6633',
	'#33FF66',
	'#6633FF',
	'#FF3366',
	'#6633FF',
	'#33FF66',
	'#FFCC99',
	'#99FFCC',
	'#CC99FF',
	'#FF99CC',
	'#CC99FF',
	'#99FFCC',
];

export const getBackgroundColor = (id: number) => {
	return colors[id % colors.length];
};

export const prepareEmployeeStatusesList = (employeeStatusesList: IEmployeeState[]) => {
	const filteredEmployeeStatusesList = employeeStatusesList.filter(
		(employeeStatus) => employeeStatus.id !== EmployeeStatusTypesFormEnum.Left,
	);

	return [
		{
			id: 5,
			name: 'All',
			value: 'ALL',
		},
		...filteredEmployeeStatusesList,
	];
};
