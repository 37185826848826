import React, { useCallback, useMemo, type FC } from 'react';
import { Field, Form } from 'react-final-form';

import { Input, Select } from 'antd';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { requiredFieldValidator } from 'utils/validators';

import { generateUniqueId } from '../HRModuleSettings.helpers';
import {
	CustomHolidayEventType,
	EmployeeOptions,
	ListActionEnums,
	VacationLeaveDayType,
} from '../HRModuleSettings.types';

import { Styled } from './AddEditCustomTimeOffModal.styled';

type AddEditCustomTimeOffModalProps = {
	isOpen: number | boolean;
	employeeList: EmployeeOptions[];
	currentVacationList: VacationLeaveDayType[];
	loading?: boolean;
	onCancel: () => void;
	handleUpdateList: (vacation: VacationLeaveDayType, action: ListActionEnums) => void;
};

const { Option } = Select;

export const AddEditCustomTimeOffModal: FC<AddEditCustomTimeOffModalProps> = ({
	isOpen,
	employeeList,
	currentVacationList,
	loading,
	onCancel,
	handleUpdateList,
}) => {
	const isEditMode = typeof isOpen === 'number';

	const initialValues = useMemo(
		() => ({
			employeeId: '',
			days: '',
			...(isEditMode ? currentVacationList.find((vacation) => vacation.id === isOpen) || {} : {}),
		}),
		[isOpen],
	);

	const onSubmit = useCallback(
		(values: CustomHolidayEventType) => {
			const valuesToSubmit = {
				...values,
				...(!isEditMode && { id: generateUniqueId() }),
			};
			const updateAction = isEditMode ? ListActionEnums.UPDATE : ListActionEnums.ADD;
			handleUpdateList(valuesToSubmit, updateAction);
			onCancel();
		},
		[isOpen],
	);

	const handleDelete = useCallback(() => {
		handleUpdateList({ id: isOpen as number }, ListActionEnums.DELETE);
		onCancel();
	}, [isOpen]);

	return (
		<Styled.Modal open={!!isOpen} footer={null} centered onCancel={onCancel} width='auto'>
			<Styled.ModalHead>
				<h3>{isEditMode ? 'Edit Time Off' : 'Add Custom Time Off'}</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					autoComplete='off'
					render={({ handleSubmit, submitting }) => (
						<form onSubmit={handleSubmit}>
							<Styled.FieldsBlock>
								<Field name='employeeId' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='employeeId'
											label='Employee'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
										>
											<Select {...input} value={input.value || null} placeholder='Please Select'>
												{employeeList?.map((option: EmployeeOptions) => (
													<Option key={option.value} value={option.value}>
														{option.label}
													</Option>
												))}
											</Select>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.FieldsBlock>
								<Field name='days' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='days'
											label='Day Numbers'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
										>
											<Input {...input} placeholder='e.g. 30' autoComplete='off' />
										</FieldWrapper>
									)}
								</Field>
							</Styled.FieldsBlock>
							<Styled.ButtonBox>
								<HRThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									disabled={submitting || loading}
									loading={submitting || loading}
									// disabled={loading.submitButtonLoading}
									// loading={!!loading.settingsLoading}
								>
									Save Holiday
								</HRThemedButton>
								{isEditMode && (
									<Button
										type='button'
										buttonType={ButtonTypes.danger}
										disabled={submitting || loading}
										loading={submitting || loading}
										onClick={handleDelete}
									>
										Remove
									</Button>
								)}
								<HRThemedButton type='button' buttonType={ButtonTypes.tertiary} onClick={onCancel}>
									Cancel
								</HRThemedButton>
							</Styled.ButtonBox>
						</form>
					)}
				/>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
