import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ButtonTypes } from 'components/Button/Button.types';
import { IUser } from 'modules/Common/types';
import HRRequestEditTimeOffModal from 'modules/HR/components/HRRequestEditTimeOffModal';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import HRCalendar from 'modules/HR/containers/HRCalendar';
import { unregisteredDucks } from 'modules/Unregistered/ducks';

import { availableTypeOffDataMock } from './HRCalendarPage.mock';
import { Styled } from './HRCalendarPage.styled';

const HRCalendarPage = ({ userInfo }: { userInfo: IUser }) => {
	const [isModalOpen, setIsModalOpen] = useState<number | null>(null);
	const userId = userInfo?.userId;

	const handleOpenModal = (employeeId: number) => {
		setIsModalOpen(employeeId);
	};

	const handleClosedModal = () => {
		setIsModalOpen(null);
	};

	return (
		<Styled.Root>
			<Styled.Head>
				<Styled.Title>Calendar</Styled.Title>
				<HRThemedButton
					buttonType={ButtonTypes.primary}
					type='button'
					onClick={() => handleOpenModal(userId)}
				>
					Request Time Off
				</HRThemedButton>
			</Styled.Head>
			<Styled.Content>
				<HRCalendar />
			</Styled.Content>
			<HRRequestEditTimeOffModal
				employeeId={isModalOpen}
				onCancel={handleClosedModal}
				handlePostTimeOffEvent={() => undefined}
				handleEditTimeOffEvent={() => undefined}
				loading={false}
				availableTypeOffData={availableTypeOffDataMock}
			/>
		</Styled.Root>
	);
};

export default connect((state) => ({
	userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
}))(HRCalendarPage);
