import React from 'react';

import dayjs from 'dayjs';

import EyeOpenIcon from 'components/SVG/EyeOpenIcon';
import { ITicketNote } from 'modules/Common/types';
import { getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';
import { COLORS } from 'theme';

import { MergedActivityWithNotesItem } from './TicketNotes.helper';
import { Styled } from './TicketNotes.styled';

interface TextNoteProps {
	note: MergedActivityWithNotesItem;
	senderColors: Record<number, string>;
	isVisibilityLabelHidden?: boolean;
}

const TextNote: React.FC<TextNoteProps> = ({
	note,
	senderColors,
	isVisibilityLabelHidden = false,
}) => {
	const noteId = note.id;
	const userId = note.userId;
	const fullName = (note.data as ITicketNote).sender.fullName;
	const isVisibleToClient =
		!isVisibilityLabelHidden && (note.data as ITicketNote).isVisibleToClient;
	const noteDate = note.date;
	const noteText = (note.data as ITicketNote).text;

	return (
		<Styled.NoteItem key={noteId}>
			<Styled.NoteInfo>
				<Styled.UserIcon bgColor={senderColors[userId]}>
					{getFirstLettersUppercase(fullName)}
				</Styled.UserIcon>
				<Styled.NoteName>{fullName}</Styled.NoteName>
				<Styled.NoteDate>{dayjs(noteDate).format('MMM DD, YYYY')}</Styled.NoteDate>
				{isVisibleToClient && (
					<Styled.VisibilityLabel>
						<EyeOpenIcon fill={COLORS.purple} width='24px' />
						Visible to client
					</Styled.VisibilityLabel>
				)}
			</Styled.NoteInfo>
			<Styled.NoteText>{noteText}</Styled.NoteText>
		</Styled.NoteItem>
	);
};

export default TextNote;
