import React, { useState, type FC, useCallback, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';

import { TablePaginationConfig, Tabs } from 'antd';
import { SorterResult } from 'antd/es/table/interface';

import Table from 'components/Table';
import { useMount } from 'hooks';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import { TabsTitleEnum } from 'modules/Common/types';
import { GenericType, IOption } from 'types';

import { columns } from './SupportTickets.entities';
import { createOptionsWithAll } from './SupportTickets.helper';

type SupportTicketsProps = {
	loading?: GenericType;
	ticketTypes: IOption[];
	ticketStatuses: IOption[];
	tickets: {
		data: object[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	getTicketsRequested: (params: { active: boolean; page?: number; size?: number }) => void;
	getTicketsTypesRequested: () => void;
	getTicketsStatusesRequested: () => void;
	deleteTicketByIdRequested: (ticketId: number, callback: () => void) => void;
};

const SupportTickets: FC<SupportTicketsProps> = ({
	getTicketsRequested,
	getTicketsTypesRequested,
	getTicketsStatusesRequested,
	loading,
	tickets,
	ticketTypes,
	ticketStatuses,
	deleteTicketByIdRequested,
}) => {
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);
	const [tableParams, setTableParams] = useState({});
	const isInitialMount = useRef(true);

	const { pageIndex, pageSize, totalCount, data } = tickets || {};

	const isActiveTab = tab === TabsTitleEnum.ActiveTab;

	const handleChangeTabs = (id: string) => {
		getTicketsRequested({ active: isActiveTab });
		setTab(id);
	};

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			getTicketsRequested({ active: isActiveTab, page, size });
		},
		[isActiveTab],
	);

	const handleDeleteItem = (ticketId: number) => {
		deleteTicketByIdRequested(ticketId, () => {
			getTicketsRequested({ active: isActiveTab, page: pageIndex, size: pageSize });
		});
	};

	useMount(() => {
		!ticketTypes?.length && getTicketsTypesRequested();
		!ticketStatuses?.length && getTicketsStatusesRequested();
	});

	useEffect(() => {
		if (isInitialMount.current) {
			getTicketsRequested({
				active: isActiveTab,
				page: DEFAULT_CURRENT_PAGE,
				size: DEFAULT_PAGE_SIZE,
			});
			isInitialMount.current = false;
		} else {
			getTicketsRequested({ active: isActiveTab, page: pageIndex, size: pageSize });
		}
	}, [tab]);

	const handleSearch = (value: string) => {
		setTableParams({ ...tableParams, page: 1, search: value });
	};

	const handleStatusFilter = (value: number) => {
		setTableParams({ ...tableParams, page: 1, status: value });
	};

	const handleTypeFilter = (value: number) => {
		setTableParams({ ...tableParams, page: 1, type: value });
	};

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			sorter,
		});
	};
	const columnsData = columns(isActiveTab, handleDeleteItem);

	const statusOptions = useMemo(() => createOptionsWithAll(ticketStatuses), [ticketStatuses]);
	const typeOptions = useMemo(() => createOptionsWithAll(ticketTypes), [ticketTypes]);

	return (
		<Tabs
			defaultActiveKey={TabsTitleEnum.ActiveTab}
			tabBarStyle={{ textTransform: 'uppercase' }}
			onChange={handleChangeTabs}
			items={[
				{
					label: 'Active',
					key: TabsTitleEnum.ActiveTab,
					active: true,
					children: (
						<Table
							pageSize={pageSize}
							current={pageIndex}
							loading={loading?.getTicketsLoad}
							total={totalCount}
							data={data}
							columns={columnsData}
							onChange={handleTablePaginationChange}
							onPageSizeChange={handleTablePaginationChange}
							onTableChange={handleTableChange}
						/>
					),
				},
				{
					label: 'Resolved',
					key: TabsTitleEnum.ArchiveTab,
					children: (
						<Table
							pageSize={pageSize}
							current={pageIndex}
							loading={loading?.getTicketsLoad}
							total={totalCount}
							data={data}
							columns={columnsData}
							onChange={handleTablePaginationChange}
							onPageSizeChange={handleTablePaginationChange}
							onTableChange={handleTableChange}
						/>
					),
				},
			]}
			tabBarExtraContent={
				<div style={{ textTransform: 'capitalize' }}>
					<SearchFilterSortWrapper
						search={{
							onSearch: handleSearch,
							isDebounce: true,
							placeholder: 'Search a client',
							styles: { width: '300px', flexBasis: 'auto' },
						}}
						filter={
							isActiveTab
								? {
									mode: 'single',
									options: statusOptions,
									onFilter: handleStatusFilter,
									label: 'Status',
									width: '160px',
									placeholder: 'All',
								  }
								: undefined
						}
						secondFilter={{
							mode: 'single',
							options: typeOptions,
							onFilter: handleTypeFilter,
							width: '170px',
							label: 'Type',
							placeholder: 'All',
						}}
					/>
				</div>
			}
		/>
	);
};

export default connect(
	(state) => ({
		tickets: backOfficeDucks.backOfficeSelectors.getBOTicketsState(state),
		ticketTypes: commonDucks.commonSelectors.getTicketTypes(state),
		ticketStatuses: commonDucks.commonSelectors.getTicketStatuses(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getTicketsRequested: backOfficeDucks.backOfficeActions.geTicketsRequested,
		getTicketsTypesRequested: commonDucks.commonActions.getTicketsTypesRequested,
		getTicketsStatusesRequested: commonDucks.commonActions.getTicketsStatusesRequested,
		deleteTicketByIdRequested: backOfficeDucks.backOfficeActions.deleteTicketByIdRequested,
	},
)(SupportTickets);
