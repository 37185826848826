import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import { useMount } from 'hooks';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Routes } from 'types';

import { DEFAULT_PAGE_SIZE } from './BODashboard.constants';
import { getNumberOfNewTickets } from './BODashboard.helpers';
import { Styled } from './BODashboard.styled';
import { BODashboardProps } from './BODashboard.types';
import { columns as clientsColumns } from './BODashboardClientsTable.entities';
import { columns as ticketsColumns } from './BODashboardTicketsTable.entities';

const BODashboard: FC<BODashboardProps> = ({
	userInfo,
	BODashboardInsights,
	clients,
	tickets,
	loading,
	getBODashboardInsightsRequested,
	getClientsRequested,
	getTicketsRequested,
}) => {
	const {
		data: clientsData,
		pageIndex: clientsPageIndex,
		totalCount: clientsTotalCount,
	} = clients || {};
	const {
		data: ticketsData,
		pageIndex: ticketsPageIndex,
		totalCount: ticketsTotalCount,
	} = tickets || {};
	const navigate = useNavigate();

	useMount(() => {
		isEmpty(BODashboardInsights) && getBODashboardInsightsRequested();
		getClientsRequested({ page: 0, size: DEFAULT_PAGE_SIZE });
		getTicketsRequested({ active: true, page: 0, size: DEFAULT_PAGE_SIZE });
	});

	const insightsLoading = !!loading?.getBoDashboardInsightsLoad;
	const clientsLoading = !!loading?.getBoClientsLoad;
	const ticketsLoading = !!loading?.getTicketsLoad;
	const clientsColumnsData = clientsColumns();
	const ticketsColumnsData = ticketsColumns();

	const numberOfNewTickets = getNumberOfNewTickets(ticketsData);
	const newTicketsLabel = numberOfNewTickets ? `(${numberOfNewTickets} new)` : '';
	const numberOfNewClientsMockNumber = 5;
	const newClientsLabel = numberOfNewClientsMockNumber
		? `(${numberOfNewClientsMockNumber} new)`
		: '';

	if (insightsLoading) {
		return <Spinner />;
	}

	return (
		<Styled.Root>
			<Styled.HeaderBackground />
			<Styled.Header>
				<Styled.HeaderTitle>
					Welcome back <br />
					{`${userInfo?.firstName}`}
				</Styled.HeaderTitle>
				<Styled.InsightsWrapper>
					{Object.entries(BODashboardInsights)?.map(([name, number]) => (
						<Styled.InsightBox key={name}>
							<Styled.InsightBoxNumber>{number.toLocaleString()}</Styled.InsightBoxNumber>
							<Styled.InsightBoxName>{name}</Styled.InsightBoxName>
						</Styled.InsightBox>
					))}
				</Styled.InsightsWrapper>
			</Styled.Header>
			<Styled.Content>
				<Styled.ContentBlock>
					<Styled.ContentBlockHeader>
						<Styled.ContentBlockTitle>Clients {newClientsLabel}</Styled.ContentBlockTitle>
						<Button
							buttonType={ButtonTypes.primary}
							onClick={() => navigate(`${Routes.BackOffice}${Routes.ClientCreate}`)}
						>
							Create Client
						</Button>
					</Styled.ContentBlockHeader>
					<Styled.ContentBlockBody>
						<Table
							data={clientsData}
							columns={clientsColumnsData}
							pageSize={DEFAULT_PAGE_SIZE}
							total={clientsTotalCount}
							current={clientsPageIndex}
							loading={clientsLoading}
						/>
					</Styled.ContentBlockBody>
					<Styled.ContentBlockFooter>
						<Button
							buttonType={ButtonTypes.secondary}
							onClick={() => navigate(`${Routes.BackOffice}${Routes.Clients}`)}
						>
							View All
						</Button>
					</Styled.ContentBlockFooter>
				</Styled.ContentBlock>
				<Styled.ContentBlock>
					<Styled.ContentBlockHeader>
						<Styled.ContentBlockTitle>Tickets {newTicketsLabel}</Styled.ContentBlockTitle>
						<Button
							buttonType={ButtonTypes.primary}
							onClick={() => navigate(`${Routes.BackOffice}${Routes.CreateSupportTicket}`)}
						>
							Create Ticket
						</Button>
					</Styled.ContentBlockHeader>
					<Styled.ContentBlockBody>
						<Table
							data={ticketsData}
							columns={ticketsColumnsData}
							pageSize={DEFAULT_PAGE_SIZE}
							total={ticketsTotalCount}
							current={ticketsPageIndex}
							loading={ticketsLoading}
						/>
					</Styled.ContentBlockBody>
					<Styled.ContentBlockFooter>
						<Button
							buttonType={ButtonTypes.secondary}
							onClick={() => navigate(`${Routes.BackOffice}${Routes.SupportTickets}`)}
						>
							View All
						</Button>
					</Styled.ContentBlockFooter>
				</Styled.ContentBlock>
			</Styled.Content>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		BODashboardInsights: backOfficeDucks.backOfficeSelectors.getBODashboardsInsights(state),
		userInfo: unregisteredDucks.unregisteredSelectors.getUser(state),
		clients: backOfficeDucks.backOfficeSelectors.getClientsPaginatedData(state),
		tickets: backOfficeDucks.backOfficeSelectors.getBOTicketsState(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		getBODashboardInsightsRequested:
			backOfficeDucks.backOfficeActions.getBODashboardInsightsRequested,
		getClientsRequested: backOfficeDucks.backOfficeActions.getBOClientsRequested,
		getTicketsRequested: backOfficeDucks.backOfficeActions.geTicketsRequested,
	},
)(BODashboard);
