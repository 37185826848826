import { message } from 'antd';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import API from 'api';
import { MESSAGES } from 'modules/Common/constants';
import { IEmployeeValues } from 'modules/Common/types/employee';

import { ActionTypes, GenericAction } from './types';

export const createSagas = (TYPES: ActionTypes) => {
	function* getEmployeeRolesSaga(): Generator<CallEffect | PutEffect, void> {
		try {
			const roles = yield call(API.employeeService.getEmployeeRoles);

			yield put({ type: TYPES.GET_EMPLOYEES_ROLES.SUCCESS, payload: roles });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYEES_ROLES.FAILED, payload: error });
		}
	}

	function* getEmployeeStatusesSaga(): Generator<CallEffect | PutEffect, void> {
		try {
			const employeeStatuses = yield call(API.employeeService.getEmployeeStatuses);

			yield put({ type: TYPES.GET_EMPLOYEE_STATUSES.SUCCESS, payload: employeeStatuses });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYEE_STATUSES.FAILED, payload: error });
		}
	}

	function* createEmployeeSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.addEmployee, payload);

			yield put({ type: TYPES.CREATE_EMPLOYEE.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyCreatedEmployeeAccount);
		} catch (error) {
			yield put({ type: TYPES.CREATE_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* editEmployeeSaga(
		action: GenericAction<{ isSuperUser: boolean, employeeId: string, values: IEmployeeValues }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { callback } = action;
			const { isSuperUser, employeeId, values } = action.payload;

			const currentEndpoint = isSuperUser ? API.employeeService.editEmployeeSuperUser : API.employeeService.editEmployeeNonSuperUser;
			yield call(currentEndpoint, employeeId, values);

			yield put({ type: TYPES.EDIT_EMPLOYEE.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.employeeAccountUpdated);
		} catch (error) {
			yield put({ type: TYPES.EDIT_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* getOwnProfileSaga(
		action: GenericAction<{ values: IEmployeeValues }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const employeeData = yield call(API.employeeService.getOwnProfileSuperUser);

			yield put({ type: TYPES.GET_OWN_PROFILE.SUCCESS, payload: employeeData });
		} catch (error) {
			yield put({ type: TYPES.GET_OWN_PROFILE.FAILED, payload: error });
		}
	}

	function* editOwnProfileSaga(
		action: GenericAction<{ isSuperUser: boolean, values: IEmployeeValues }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { isSuperUser, values } = action.payload;

			const currentEndpoint = isSuperUser ? API.employeeService.editOwnProfileSuperUser : API.employeeService.editOwnProfileNonSuperUser;
			yield call(currentEndpoint, values);

			yield put({ type: TYPES.EDIT_OWN_PROFILE.SUCCESS });

			yield call(message.success, MESSAGES.yourProfileUpdated);
		} catch (error) {
			yield put({ type: TYPES.EDIT_OWN_PROFILE.FAILED, payload: error });
		}
	}

	function* getEmployeesListSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload: params, isSuperUser } = action;

			const url = isSuperUser
				? API.employeeService.getEmployeesListSuperUser
				: API.employeeService.getEmployeesListNonSuperUser;

			const list = yield call(
				url,
				params || {
					size: 20,
					active: true,
				},
			);

			yield put({ type: TYPES.VIEW_EMPLOYEES_LIST.SUCCESS, payload: list });
		} catch (error) {
			yield put({ type: TYPES.VIEW_EMPLOYEES_LIST.FAILED, payload: error });
		}
	}

	function* archiveEmployeeSaga(
		action: GenericAction<{ employeeId: number; leaveDate: string }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.archiveEmployee, payload);

			yield put({ type: TYPES.ARCHIVE_EMPLOYEE.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyArchivedEmployee);
		} catch (error) {
			yield put({ type: TYPES.ARCHIVE_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* unArchiveEmployeeSaga(
		action: GenericAction<{ employeeId: number }>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.unArchiveEmployee, payload);

			yield put({ type: TYPES.UNARCHIVE_EMPLOYEE.SUCCESS });

			callback && (yield call(callback));

			yield call(message.success, MESSAGES.successfullyUnArchivedEmployee);
		} catch (error) {
			yield put({ type: TYPES.UNARCHIVE_EMPLOYEE.FAILED, payload: error });
		}
	}

	function* changeEmployeeStatusSaga(
		action: GenericAction<{
			employeeId: number;
			employeeStatus: number;
			startDate?: string;
			leaveDate?: string;
		}>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload, callback } = action;

			yield call(API.employeeService.changeEmployeeStatus, payload);

			yield put({ type: TYPES.CHANGE_EMPLOYEE_STATUS.SUCCESS });

			if (callback) {
				yield call(callback);
			}

			yield call(message.success, MESSAGES.successfullyUpdatedEmployeeStatus);
		} catch (error) {
			yield put({ type: TYPES.CHANGE_EMPLOYEE_STATUS.FAILED, payload: error });
		}
	}

	function* getEmployeeByIdSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			const { payload: employeeId, isSuperUser } = action;

			const url = isSuperUser
				? API.employeeService.getEmployeeByIdSuperUser
				: API.employeeService.getEmployeeByIdNonSuperUser;

			const employeesData = yield call(url, employeeId);

			yield put({ type: TYPES.GET_EMPLOYEE_BY_ID.SUCCESS, payload: employeesData });
		} catch (error) {
			yield put({ type: TYPES.GET_EMPLOYEE_BY_ID.FAILED, payload: error });
		}
	}

	function* sendFirstDayInvitationSaga(
		action: GenericAction<IEmployeeValues>,
	): Generator<CallEffect | PutEffect, void> {
		try {
			yield call(API.employeeService.sendFirstDayEmployeeInvitation, action.payload);

			yield put({ type: TYPES.SEND_FIRST_DAY_INVITATION.SUCCESS });

			yield call(message.success, MESSAGES.successfullySentFirstDayInvitation);
		} catch (error) {
			yield put({ type: TYPES.SEND_FIRST_DAY_INVITATION.FAILED, payload: error });
		}
	}

	return {
		getEmployeeRolesSaga,
		getEmployeeStatusesSaga,
		createEmployeeSaga,
		getEmployeesListSaga,
		archiveEmployeeSaga,
		unArchiveEmployeeSaga,
		changeEmployeeStatusSaga,
		getEmployeeByIdSaga,
		sendFirstDayInvitationSaga,
		editEmployeeSaga,
		editOwnProfileSaga,
		getOwnProfileSaga,
	};
};
