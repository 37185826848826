import dayjs from 'dayjs';

import { IActivityNotes, ITicketNote } from 'modules/Common/types';

import { NoteTypeEnum } from './TicketNotes.types';

export interface MergedActivityWithNotesItem {
	id: number;
	date: string;
	type: NoteTypeEnum;
	userId: number;
	data: ITicketNote | IActivityNotes;
}

export const mergeAndSortByDate = (
	notes: ITicketNote[],
	activities: IActivityNotes[],
	filterNotVisibleToClient: boolean,
): MergedActivityWithNotesItem[] => {
	const textNotes = filterNotVisibleToClient
		? notes.filter((note) => note.isVisibleToClient)
		: notes;
	const mergedItems: MergedActivityWithNotesItem[] = [
		...textNotes.map((note) => ({
			id: note.id,
			date: note.created,
			type: NoteTypeEnum.Note,
			userId: note.sender.id,
			data: note,
		})),
		...activities.map((activity) => ({
			id: activity.id,
			date: activity.date,
			type: NoteTypeEnum.Activity,
			userId: activity.user.id,
			data: activity,
		})),
	];

	return mergedItems.sort((a, b) => {
		return dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1;
	});
};
