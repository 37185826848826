import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekday);

export const calculateDayOffsRequested = (
	startDateEndTime: [string, string],
	startType: string,
	endType: string,
	holidays?: string[],
) => {
	const start = dayjs(startDateEndTime[0], 'DD/MM/YYYY');
	const end = dayjs(startDateEndTime[1], 'DD/MM/YYYY');
	const holidaySet = new Set(
		holidays?.map((date) => dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD')),
	);

	let totalDays = 0;
	const daysListArray = [];

	for (let date = start; date.isSameOrBefore(end); date = date.add(1, 'day')) {
		const formattedDate = date.format('YYYY-MM-DD');

		if (date.day() !== 0 && date.day() !== 6 && !holidaySet.has(formattedDate)) {
			// Exclude weekends (Sunday = 0, Saturday = 6) and holidays
			if (date.isSame(start, 'day')) {
				totalDays += startType === 'FULL' ? 1 : 0.5;
				daysListArray.push({ [formattedDate]: startType === 'FULL' ? 'All Day' : 'Half Day' });
			} else if (date.isSame(end, 'day')) {
				totalDays += endType === 'FULL' ? 1 : 0.5;
				daysListArray.push({ [formattedDate]: endType === 'FULL' ? 'All Day' : 'Half Day' });
			} else {
				totalDays += 1;
				daysListArray.push({ [formattedDate]: 'All Day' });
			}
		} else {
			daysListArray.push({ [formattedDate]: 'Non working Day' });
		}
	}

	return { totalDays, daysListArray };
};
