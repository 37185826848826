import { Button } from 'antd';
import { darken } from 'polished';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

interface RootProps {
	isWeekView: boolean;
}
interface IUserIconProps {
	bgColor?: string;
	backgroundImageUrl?: string;
}

export namespace Styled {
	export const Root = styled.div<RootProps>`
		position: relative;

		.rbc-time-view,
		.rbc-time-header-content,
		.rbc-time-header,
		.rbc-time-header-cell,
		.rbc-header,
		.rbc-day-bg + .rbc-day-bg {
			border: none;
		}

		.rbc-time-view {
			border: ${COLORS.lightGray4};

			.rbc-row {
				min-height: 0;
			}
		}

		.rbc-header span {
			font-size: 12px;
			font-weight: 600;
		}

		.rbc-time-content {
			border: 0.25px solid ${COLORS.lightGray4};

			& > * + * > * {
				border-left: 0.25px solid ${COLORS.lightGray4};
			}
		}

		.rbc-allday-cell {
			.rbc-row {
				min-height: 0;
			}
		}

		.rbc-time-header {
			.rbc-header {
				display: flex;
				align-items: center;
				min-height: 24px;
			}
		}

		.rbc-time-header-content {
			.rbc-today {
				color: ${COLORS.blue2};
				background-color: transparent;
			}
		}

		.rbc-btn-group {
			button {
				transition: color 0.2s ease;
				cursor: pointer;

				&:hover {
					color: ${COLORS.blue};
				}
			}
		}

		.rbc-events-container {
			margin: 0 10px;
			border: none;

			.rbc-event-label {
				display: none;
			}
		}

		.rbc-ellipsis,
		.rbc-show-more,
		.rbc-row-segment .rbc-event-content,
		.rbc-event-label {
			white-space: initial;
		}

		.rbc-timeslot-group {
			min-height: 48px;
			border-bottom: 0.25px solid ${COLORS.lightGray4};

			.rbc-time-slot {
				display: flex;
				justify-content: center;
				align-items: end;
			}

			.rbc-label {
				font-size: 12px;
			}
		}

		.rbc-day-slot {
			.rbc-time-slot {
				border: none;
			}

			.rbc-event-content {
				line-height: normal;
			}
		}

		.rbc-current-time-indicator {
			height: 2px;
			background-color: ${COLORS.blue};
		}

		.rbc-event {
			font-size: 12px;
			padding: 4px 6px;
			border-radius: 2px;
		}

		.rbc-time-column.rbc-now.rbc-today {
			width: 100%;
			background-color: ${COLORS.lightBlue7};
		}

		${({ isWeekView }) =>
		isWeekView &&
			`
			.rbc-time-content,
			.rbc-label.rbc-time-header-gutter {
				display: none;
			}

			.rbc-header {
				text-align: left;
				border-bottom: none;
			}

			.rbc-header button {
				padding: 10px 16px;
				pointer-events: none;

				span {
					color: ${COLORS.black};
					font-family: Inter;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 150%;
					letter-spacing: -0.132px;
					text-transform: uppercase;
				}
			}

			.rbc-time-view .rbc-row {
				min-height: auto;
			}

			.rbc-time-header-content {
				border: none;
			}

			.rbc-row-content {
				padding-top: 10px;
			}

			.rbc-time-header {
				flex: 1 0 auto;
			}

			.rbc-time-view {
				overflow-y: auto;
				border: 1px solid ${COLORS.lightGray2};
			}
			.rbc-row-segment {
				margin-bottom: 2px;
			}
			.rbc-day-bg + .rbc-day-bg,
			.rbc-header + .rbc-header {
				border-left: 1px solid #ddd;
			}
		`}
	`;

	export const EventWrapper = styled.div`
		position: relative;
		height: 100%;

		.rbc-month-view & {
			max-height: 14px;
		}

		span {
			display: flex;
			align-items: center;
			height: 100%;
		}
	`;

	export const PopoverHead = styled.div`
		display: flex;
		max-width: 326px;
		margin: 0 -12px;
		padding: 4px 16px 8px 24px;
		border-bottom: 1px solid ${COLORS.lightGray2};
		gap: 8px;
		font-family: 'Inter';

		h4 {
			word-break: break-all;
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px;
		}

		a {
			font-size: 14px;
			line-height: 20px;
		}
	`;

	export const UserIcon = styled.div<IUserIconProps>`
		width: 24px;
		height: 24px;
		background: ${({ backgroundImageUrl, bgColor }) =>
		backgroundImageUrl
			? `url(${backgroundImageUrl}) center/contain no-repeat`
			: bgColor || 'COLORS.green'};
		border-radius: 50%;
		color: ${COLORS.white};
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 9px;
		font-weight: bold;
		${({ backgroundImageUrl }) =>
				backgroundImageUrl &&
			`
				background-color: transparent;
				color: transparent;
		`}
	`;

	export const PopoverContent = styled.div`
		min-width: 302px;
		padding: 0 4px 4px 12px;
		font-family: 'Inter';
	`;

	export const PopoverEventType = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
		margin-bottom: 5px;
	`;

	export const PopoverEventDate = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
	`;

	export const PopoverCommentsSection = styled.div`
		margin-top: 16px;
		h6 {
			color: ${COLORS.darkGray1};
			font-family: Inter;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: 16px;
			margin-bottom: 5px;
		}
		p {
			color: ${COLORS.black};
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			letter-spacing: -0.154px;
		}
	`;

	export const ButtonBox = styled.div`
		display: flex;
		gap: 8px;
		font-family: 'Inter';

		a {
			display: block;
		}

		button {
			width: 100%;
			height: 40px;
			font-size: 16px;
			font-weight: 500;
		}
	`;

	export const ButtonApprove = styled(Button)`
		background-color: ${COLORS.green};
		&:hover {
			background-color: ${darken(0.1, COLORS.green)}!important;
		}
	`;
}
