import { combineReducers } from 'redux';

import { takeLatest } from 'redux-saga/effects';

import createLoading from 'modules/Common/ducks/loading';

import { createActions } from './actions';
import { createActionsTypes } from './constants';
import { HRModuleReducer } from './reducer';
import { createSagas } from './sagas';
import { createSelectors } from './selectors';

type HrModuleDucksProps = {
	prefix: string;
	root: string;
};

function getHrDucks(props: HrModuleDucksProps) {
	const { prefix, root } = props;
	const PREFIX = prefix;

	const TYPES = createActionsTypes(PREFIX);
	const ACTIONS = createActions(TYPES);
	const SELECTORS = createSelectors(root);

	const SAGAS = createSagas(TYPES, PREFIX, SELECTORS, ACTIONS, props);
	const hrReducer = HRModuleReducer(TYPES);

	const { reducer: loadingReducer } = createLoading({
		GET_EMPLOYEES_ROLES: TYPES.GET_EMPLOYEES_ROLES,
		GET_EMPLOYEE_STATUSES: TYPES.GET_EMPLOYEE_STATUSES,
		CREATE_EMPLOYEE: TYPES.CREATE_EMPLOYEE,
		VIEW_EMPLOYEES_LIST: TYPES.VIEW_EMPLOYEES_LIST,
		ARCHIVE_EMPLOYEE: TYPES.ARCHIVE_EMPLOYEE,
		UNARCHIVE_EMPLOYEE: TYPES.UNARCHIVE_EMPLOYEE,
		CHANGE_EMPLOYEE_STATUS: TYPES.CHANGE_EMPLOYEE_STATUS,
		GET_EMPLOYEE_BY_ID: TYPES.GET_EMPLOYEE_BY_ID,
		SEND_FIRST_DAY_INVITATION: TYPES.SEND_FIRST_DAY_INVITATION,
		EDIT_EMPLOYEE: TYPES.EDIT_EMPLOYEE,
		EDIT_EMPLOYEE_PASSWORD: TYPES.EDIT_EMPLOYEE_PASSWORD,
		GET_OWN_PROFILE: TYPES.GET_OWN_PROFILE,
		EDIT_OWN_PROFILE: TYPES.EDIT_OWN_PROFILE,
		EDIT_OWN_PASSWORD: TYPES.EDIT_OWN_PASSWORD,
	});

	function* hrSaga() {
		yield takeLatest(TYPES.GET_EMPLOYEES_ROLES.REQUESTED, SAGAS.getEmployeeRolesSaga);
		yield takeLatest(TYPES.GET_EMPLOYEE_STATUSES.REQUESTED, SAGAS.getEmployeeStatusesSaga);
		yield takeLatest(TYPES.CREATE_EMPLOYEE.REQUESTED, SAGAS.createEmployeeSaga);
		yield takeLatest(TYPES.VIEW_EMPLOYEES_LIST.REQUESTED, SAGAS.getEmployeesListSaga);
		yield takeLatest(TYPES.ARCHIVE_EMPLOYEE.REQUESTED, SAGAS.archiveEmployeeSaga);
		yield takeLatest(TYPES.UNARCHIVE_EMPLOYEE.REQUESTED, SAGAS.unArchiveEmployeeSaga);
		yield takeLatest(TYPES.CHANGE_EMPLOYEE_STATUS.REQUESTED, SAGAS.changeEmployeeStatusSaga);
		yield takeLatest(TYPES.GET_EMPLOYEE_BY_ID.REQUESTED, SAGAS.getEmployeeByIdSaga);
		yield takeLatest(TYPES.SEND_FIRST_DAY_INVITATION.REQUESTED, SAGAS.sendFirstDayInvitationSaga);
		yield takeLatest(TYPES.EDIT_EMPLOYEE.REQUESTED, SAGAS.editEmployeeSaga);
		yield takeLatest(TYPES.EDIT_OWN_PROFILE.REQUESTED, SAGAS.editOwnProfileSaga);
		yield takeLatest(TYPES.GET_OWN_PROFILE.REQUESTED, SAGAS.getOwnProfileSaga);
	}

	const reducer = combineReducers({ data: hrReducer, loadingReducer });

	return {
		reducer,
		hrSaga,
		hrActions: { ...ACTIONS },
		hrSelectors: SELECTORS,
		hrActionTypes: TYPES,
		hrSagas: SAGAS,
	};
}

export const hrDucks = getHrDucks({ prefix: 'hr', root: 'hrReducer' });
