import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Card, Button } from 'antd';
import dayjs from 'dayjs';

import ArrowNext from 'components/SVG/ArrowNext';
import ArrowPrevious from 'components/SVG/ArrowPrevious';
import { CalendarEventType } from 'modules/Common/types/hrModuleTypes';
import {
	CALENDAR_EVENT_TYPES,
	CalendarColorsByTypes,
} from 'modules/HR/constants/HRModuleConstants.constants';
import { COLORS } from 'theme';

import HRCalendarCustomEvent from '../HRCalendarCustomEvent';

import { Styled } from './HRCalendarWeeklyMobile.styled';

interface HRCalendarWeeklyMobileProps {
	data: CalendarEventType[];
	onFetchEvents: (startOfWeek: string, endOfWeek: string) => void;
}

const HRCalendarWeeklyMobile: React.FC<HRCalendarWeeklyMobileProps> = ({ data, onFetchEvents }) => {
	const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
	const [selectedEvents, setSelectedEvents] = useState<CalendarEventType[]>([]);
	const [currentWeekStart, setCurrentWeekStart] = useState<dayjs.Dayjs>(
		dayjs().startOf('week').add(1, 'day'),
	);

	useEffect(() => {
		fetchEventsForCurrentWeek();
	}, [currentWeekStart]);

	const fetchEventsForCurrentWeek = () => {
		const startOfWeek = dayjs(currentWeekStart).format('YYYY-MM-DD');
		const endOfWeek = dayjs(currentWeekStart).endOf('week').format('YYYY-MM-DD');
		// getTimeOffRequestList(startOfWeek, endOfWeek);
	};

	useEffect(() => {
		setSelectedEvents(
			data
				.filter((request) =>
					dayjs(selectedDate).isBetween(dayjs(request.start), dayjs(request.end), 'day', '[]'),
				)
				.filter((request) => request.type !== CALENDAR_EVENT_TYPES.publicHoliday),
		);
	}, [selectedDate, data]);

	const getCurrentWeek = (): dayjs.Dayjs[] => {
		return Array.from({ length: 7 }).map((_, i) => currentWeekStart.add(i, 'day'));
	};

	// Handle going to the next week
	const goToNextWeek = () => {
		setCurrentWeekStart((prev) => prev.add(1, 'week'));
	};

	// Handle going to the previous week
	const goToPreviousWeek = () => {
		setCurrentWeekStart((prev) => prev.subtract(1, 'week'));
	};

	return (
		<Card>
			<Styled.DateRangeWrapper>
				<Button
					className='rbc-btn-group__nav-btn'
					icon={<ArrowPrevious width='7' height='9' fill={COLORS.blue2} />}
					onClick={goToPreviousWeek}
					size='small'
				></Button>
				<Styled.DateRangeLabel>
					{currentWeekStart.format('MMMM D')} - {currentWeekStart.add(6, 'day').format('D, YYYY')}
				</Styled.DateRangeLabel>
				<Button
					className='rbc-btn-group__nav-btn'
					icon={<ArrowNext width='9' height='9' fill={COLORS.blue2} />}
					onClick={goToNextWeek}
					size='small'
				></Button>
			</Styled.DateRangeWrapper>

			<Styled.DatesList>
				{getCurrentWeek().map((date, i) => (
					<Styled.DateWrap
						key={i}
						isCurrentDate={dayjs().format('YYYY-MM-DD') === date.format('YYYY-MM-DD')}
						isSelected={selectedDate === date.format('YYYY-MM-DD')}
						onClick={() => setSelectedDate(date.format('YYYY-MM-DD'))}
					>
						<div>{date.format('DD')}</div>
						<span>{date.format('ddd')}</span>
					</Styled.DateWrap>
				))}
			</Styled.DatesList>

			{selectedDate && (
				<Styled.RequestsContent>
					<Styled.RequestsHeader>Requests:</Styled.RequestsHeader>
					<Styled.RequestsList>
						{selectedEvents.length > 0 ? (
							selectedEvents.map((request) => (
								<Styled.RequestItemShortInfo
									isApproved={request.isApproved}
									backgroundColor={CalendarColorsByTypes[request.type].bgColor}
									key={request.id}
								>
									<HRCalendarCustomEvent
										event={request}
										popoverPlacement='top'
										popoverTrigger={'hover'}
										popoverIsOff={true}
										handleEventDecline={() => undefined}
										handleEventApprove={() => undefined}
										handleEventEdit={() => undefined}
									/>
								</Styled.RequestItemShortInfo>
							))
						) : (
							<Styled.NoRequests>No requests currently</Styled.NoRequests>
						)}
					</Styled.RequestsList>
				</Styled.RequestsContent>
			)}
		</Card>
	);
};

export default connect(
	(state) => ({
		// timeOffRequestList: hrDucks.hrSelectors.getTimeOffRequestList(state),
	}),
	{
		// getTimeOffRequestList: hrDucks.hrSelectors.getTimeOffRequestListRequested,
	},
)(HRCalendarWeeklyMobile);
