import { UploadFile } from 'antd';

import { acceptedOnboardingFilesUploadFormFileFormat } from 'modules/Common/constants';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { handleFileDownload } from 'utils/helpers';

import { EmployeeOptions, ListActionEnums } from './HRModuleSettings.types';

export const updateListHelper = <T extends { id: number }>(
	list: T[],
	item: T,
	action: ListActionEnums,
): T[] => {
	switch (action) {
	case ListActionEnums.ADD:
		return [...list, item];
	case ListActionEnums.DELETE:
		return list.filter((listItem) => listItem.id !== item.id);
	case ListActionEnums.UPDATE:
		return list.map((listItem) => (listItem.id === item.id ? item : listItem));
	default:
		return list;
	}
};

export const generateUniqueId = (): number => {
	return Date.now() * 10000 + Math.floor(Math.random() * 10000);
};

export const transformEmployeeList = (
	employeeList: EmployeeOptions[],
): { [key: number]: string } => {
	return employeeList.reduce((acc, curr) => {
		acc[curr.value] = curr.label;

		return acc;
	}, {} as { [key: number]: string });
};

export const handleDownload = (file: UploadFile) => {
	const { name } = file;
	const uid = file?.response?.data;
	handleFileDownload(uid, name);
};

export const getDraggerProps =
	(
		fileList: UploadFile[],
		setFileList: React.Dispatch<React.SetStateAction<UploadFile<unknown>[]>>,
	) =>
		({ onChange, value = [] }: { onChange: (fileIds: string[]) => void; value: string[] }) =>
			getMultipleFileUploaderProps(
				(url, responseData) => {
					onChange([...value, responseData]);
				},
				{
					fileList,
					accept: acceptedOnboardingFilesUploadFormFileFormat,
					onRemove: (file) => {
						onChange([...value.filter((item) => item !== file?.response?.data)]);
					},
					onDownload: handleDownload,
					fileType: FileUploadTypesEnum.Ticket,
					maxCount: undefined,
				},
				setFileList,
			);
