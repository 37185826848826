import React, { type FC, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import DownloadIcon from 'components/SVG/DownloadIcon';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedEmployeeFilesUploadFormFileFormat } from 'modules/Common/constants';
import { FileUploadTypesEnum, IPostTicketValues } from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { COLORS } from 'theme';
import { GenericType } from 'types';
import { handleFileDownload } from 'utils/helpers';

import { Styled } from './HRAttachmentListForm.styled';

type HRAttachmentListFormProps = {
	hrLoading: GenericType;
	attachmentsList: { originalFileName: string; referenceUuid: string }[];
};

const HRAttachmentListForm: FC<HRAttachmentListFormProps> = ({ hrLoading, attachmentsList }) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const onSubmit = (values: IPostTicketValues) => {
		const valuesForSubmit = fileList?.map((file) => {
			const { response, name } = file;

			return {
				name: name,
				referenceUuid: response.data,
			};
		});
		// saveEmployeeAttachments({ ...valuesForSubmit });
	};

	useEffect(() => {
		if (!attachmentsList?.length) return;
		const initialFileList = attachmentsList.map((attachment, index) => ({
			uid: `${index}`,
			name: attachment.originalFileName,
			status: 'done',
			response: {
				data: attachment.referenceUuid,
			},
		})) as UploadFile[];
		setFileList(initialFileList);
	}, [attachmentsList]);

	const initialValues = useMemo(
		() => ({
			attachments: attachmentsList,
		}),
		[attachmentsList],
	);

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData) => {
				onChange([...value, responseData]);
			},
			{
				fileList,
				accept: acceptedEmployeeFilesUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					onChange([...value.filter((item) => item !== file?.response?.data)]);
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.Ticket,
				maxCount: undefined,
			},
			setFileList,
		);

	const handleDownload = (file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	};

	const submitButtonLoading = hrLoading?.saveEmployeeAttachmentsLoad;

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				render={({ handleSubmit, pristine }) => (
					<form onSubmit={handleSubmit}>
						<FormBlockLine columns={1}>
							<Field name='attachments'>
								{({ input, meta }) => (
									<FieldWrapper
										isFixed
										name='attachments'
										label='Attachments List'
										errorMessage={meta.submitFailed && meta.touched && meta.error}
									>
										<Styled.Dragger
											{...draggerProps(input)}
											listType='text'
											onPreview={handleDownload}
											showUploadList={{
												showDownloadIcon: true,
												downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
											}}
										>
											<p className='ant-upload-drag-icon'>
												<InboxOutlined />
											</p>
											<p className='ant-upload-text'>Upload a files or drag and drop it</p>
											<p className='ant-upload-hint'>
												{' '}
												PDF, DOC, DOCX, XLS, XLSX, JPEG, PNG up to 15MB{' '}
											</p>
										</Styled.Dragger>
									</FieldWrapper>
								)}
							</Field>
						</FormBlockLine>
						<Styled.ButtonBox>
							<HRThemedButton
								type='submit'
								buttonType={ButtonTypes.primary}
								loading={false}
								disabled={pristine || !!submitButtonLoading}
							>
								{'Save attachments'}
							</HRThemedButton>
						</Styled.ButtonBox>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		atsLoading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{},
)(HRAttachmentListForm);
