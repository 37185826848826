import React, { type FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import Spinner from 'components/Spinner';
import { atsDucks } from 'modules/ATS/ducks';
import UnImpersonateContainer from 'modules/Common/containers/UnImpersonateContainer';
import { ClientBrandingDataType } from 'modules/Common/types/branding';
import HrModuleLayout from 'modules/HR/containers/HrModuleLayout/HrModuleLayout';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CookiesType, UserRolesType, ReactParent } from 'types';

type HRModuleMainPageProps = ReactParent & {
	clientId: number;
	clientAtsBrandingData: ClientBrandingDataType;
	roles: UserRolesType;
	getATSBrandingByClientId: (clientId: number) => void;
};

export const HRModuleMainPage: FC<HRModuleMainPageProps> = ({
	children,
	clientId,
	roles,
	clientAtsBrandingData,
	getATSBrandingByClientId,
}) => {
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);
	const brandingDataEmpty = isEmpty(clientAtsBrandingData) || !clientAtsBrandingData.id;

	useEffect(() => {
		if (clientId) {
			getATSBrandingByClientId(clientId);
		}
	}, [clientId]);

	if (brandingDataEmpty) {
		return <Spinner fullWidth />;
	}

	return (
		<>
			{isImpersonate && <UnImpersonateContainer />}
			<HrModuleLayout brandingData={clientAtsBrandingData} isImpersonate={isImpersonate}>
				<Outlet />
				<>{children}</>
			</HrModuleLayout>
		</>
	);
};

export default connect(
	(state) => ({
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		clientAtsBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
	}),
	{
		getATSBrandingByClientId: atsDucks.atsActions.getATSBrandingByClientId,
	},
)(HRModuleMainPage);
