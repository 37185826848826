import React, { type FC } from 'react';
import { Field } from 'react-final-form';

import { DatePicker, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FormApi } from 'final-form';

import FieldWrapper from 'components/FieldWrapper';
import { IEmployeeValues } from 'modules/Common/types/employee';
import { DataFormatEnum } from 'types';
import {
	composeValidators,
	endDateValidator,
	requiredFieldValidator,
	textValidator,
} from 'utils/validators';

import FormBlockLine from '../FormBlockLine';

import { Styled } from './JobDetails.styled';

type JobDetailsProps = {
	formObj: FormApi<IEmployeeValues, Partial<IEmployeeValues>>;
	isFieldDisabledForEdit: boolean;
	disabledFields?: string[];
};

export const JobDetails: FC<JobDetailsProps> = ({
	formObj,
	isFieldDisabledForEdit,
	disabledFields,
}) => {
	const dataFormat = DataFormatEnum.UK ? 'DD.MM.YYYY' : 'MM.DD.YYYY';

	const startDate = formObj.getState().values.startDate;
	const minEndDate = startDate ? dayjs(startDate).startOf('day').add(1, 'day') : null;
	const endDate = formObj.getState().values.leaveDate;
	const minStartDate = endDate ? dayjs(endDate).startOf('day') : null;

	return (
		<Styled.FormBlockWrap title='Job Details'>
			<FormBlockLine>
				<Field name='startDate' validate={requiredFieldValidator}>
					{({ input, meta }) => (
						<FieldWrapper
							isFixed
							name='startDate'
							label='Start date'
							required
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<DatePicker
								{...input}
								format={dataFormat}
								inputReadOnly={false}
								picker='date'
								disabledDate={(current) => {
									return minStartDate ? current && current > minStartDate : false;
								}}
								disabled={isFieldDisabledForEdit || disabledFields?.includes('startDate')}
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field
					name='leaveDate'
					validate={(value: Dayjs) => endDateValidator(formObj.getState().values.startDate, value)}
				>
					{({ input, meta }) => (
						<FieldWrapper
							isFixed
							name='leaveDate'
							label='Leaving Date'
							errorMessage={meta.submitFailed && meta.touched && meta.error}
						>
							<DatePicker
								{...input}
								format={dataFormat}
								inputReadOnly={false}
								picker='date'
								disabledDate={(current) => {
									return minEndDate ? current && current < minEndDate : false;
								}}
								disabled={isFieldDisabledForEdit || disabledFields?.includes('leaveDate')}
							/>
						</FieldWrapper>
					)}
				</Field>
				<Field name='jobTitle' validate={composeValidators(requiredFieldValidator, textValidator)}>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								isFixed
								name='jobTitle'
								label='Job Title'
								required
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input
									{...input}
									placeholder='Enter Job Title'
									autoComplete='off'
									disabled={isFieldDisabledForEdit || disabledFields?.includes('jobTitle')}
								/>
							</FieldWrapper>
						</>
					)}
				</Field>
				<Field name='departmentName'>
					{({ input, meta }) => (
						<>
							<FieldWrapper
								name='departmentName'
								label='Department'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
							>
								<Input
									{...input}
									placeholder='Enter Department'
									autoComplete='off'
									disabled={isFieldDisabledForEdit || disabledFields?.includes('departmentName')}
								/>
							</FieldWrapper>
						</>
					)}
				</Field>
			</FormBlockLine>
		</Styled.FormBlockWrap>
	);
};
