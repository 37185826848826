import React, { type FC } from 'react';

import dayjs from 'dayjs';

import { ButtonTypes } from 'components/Button';
import HRThemedButton from 'modules/HR/components/HRThemedButton';

import { BankHolidayEventType } from '../HRModuleSettings.types';

import { Styled } from './BankHolidaysModal.styled';

type BankHolidaysModalProps = {
	isOpen: boolean;
	bankHolidaysList: BankHolidayEventType[];
	onCancel: () => void;
};

export const BankHolidaysModal: FC<BankHolidaysModalProps> = ({
	isOpen,
	bankHolidaysList,
	onCancel,
}) => {
	const currentYear = dayjs().format('YYYY');

	return (
		<Styled.Modal open={!!isOpen} footer={null} centered onCancel={onCancel} width='auto'>
			<Styled.ModalHead>
				<h3>Bank Holidays {currentYear}</h3>
			</Styled.ModalHead>
			<Styled.ModalContent>
				<Styled.List>
					{bankHolidaysList.map((holiday) => (
						<Styled.Item key={holiday.id}>
							<Styled.ItemTitle>{holiday.holidayName}</Styled.ItemTitle>
							<Styled.ItemDate>{dayjs(holiday.holidayDate).format('DD MMMM')}</Styled.ItemDate>
							<Styled.ItemDay>{dayjs(holiday.holidayDate).format('dddd')}</Styled.ItemDay>
						</Styled.Item>
					))}
				</Styled.List>
				<Styled.ButtonBox>
					<HRThemedButton type='button' buttonType={ButtonTypes.tertiary} onClick={onCancel}>
						Cancel
					</HRThemedButton>
				</Styled.ButtonBox>
			</Styled.ModalContent>
		</Styled.Modal>
	);
};
