import React, { type FC } from 'react';
import { Field } from 'react-final-form';

import { Input } from 'antd';
import dayjs from 'dayjs';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import EditIcon from 'components/SVG/EditIcon';
import HRThemedButton from 'modules/HR/components/HRThemedButton';
import { COLORS } from 'theme';

import { CustomHolidayEventType, VacationLeaveDayType } from '../HRModuleSettings.types';

import { DayToggleItem } from './DayToggleItem';
import { Styled } from './HRHolidayAbsencePolicyBlock.styled';

const daysOfWeek = [
	{ name: 'workingDaysMonday', label: 'Monday' },
	{ name: 'workingDaysTuesday', label: 'Tuesday' },
	{ name: 'workingDaysWednesday', label: 'Wednesday' },
	{ name: 'workingDaysThursday', label: 'Thursday' },
	{ name: 'workingDaysFriday', label: 'Friday' },
	{ name: 'workingDaysSaturday', label: 'Saturday' },
	{ name: 'workingDaysSunday', label: 'Sunday' },
];

type HRHolidayAbsencePolicyBlockProps = {
	customHolidays: CustomHolidayEventType[];
	vacationLeaveDays: VacationLeaveDayType[];
	employeesList: { [key: number]: string };
	handleViewBankHolidays: () => void;
	handleUpdateCustomHolidays: (id?: number) => void;
	handleUpdateVacationLeaveDays: (id?: number) => void;
};

const HRHolidayAbsencePolicyBlock: FC<HRHolidayAbsencePolicyBlockProps> = ({
	customHolidays,
	vacationLeaveDays,
	employeesList,
	handleViewBankHolidays,
	handleUpdateCustomHolidays,
	handleUpdateVacationLeaveDays,
}) => {
	return (
		<Styled.Block>
			<Styled.BlockTitle>Holidays & absence policy</Styled.BlockTitle>
			<Styled.Section>
				<Styled.SectionTitle>Working days</Styled.SectionTitle>
				<Styled.WorkingDaysTogglesWrap>
					{daysOfWeek.map(({ name, label }) => (
						<DayToggleItem key={name} dayName={name} dayLabel={label} />
					))}
				</Styled.WorkingDaysTogglesWrap>
			</Styled.Section>
			<Styled.Section>
				<Styled.SectionTitle>Bank Holidays</Styled.SectionTitle>
				<Styled.SectionTopLine>
					<Field name='country'>
						{({ input }) => (
							<Styled.SelectAntd
								{...input}
								value={input.value || null}
								options={[
									{ label: 'United Kingdom', value: 'UK' },
									{ label: 'United States', value: 'USA' },
								]}
								placeholder='Select Country'
								disabled={true}
							/>
						)}
					</Field>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.secondary}
						onClick={handleViewBankHolidays}
					>
						View Bank Holidays
					</HRThemedButton>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.primary}
						onClick={() => handleUpdateCustomHolidays()}
					>
						Add Custom Holiday
					</HRThemedButton>
				</Styled.SectionTopLine>
				<Styled.ListOfItems>
					{customHolidays.map(({ id, holidayName, holidayDate, isEveryYear }) => (
						<Styled.ListItem key={id}>
							<Styled.ListItemTitle>{holidayName}</Styled.ListItemTitle>
							<Styled.ListItemDate>{`${dayjs(holidayDate).format('MMM DD, YYYY')} ${
								isEveryYear ? '(Yearly)' : '(Once)'
							}`}</Styled.ListItemDate>
							<Styled.ListItemEditIcon onClick={() => handleUpdateCustomHolidays(id)}>
								<EditIcon fill={COLORS.darkGray2} width='24px' height='24px' />
							</Styled.ListItemEditIcon>
						</Styled.ListItem>
					))}
				</Styled.ListOfItems>
			</Styled.Section>
			<Styled.Section>
				<Styled.SectionTitle>Vacation Leave Days</Styled.SectionTitle>
				<Styled.SectionTopLine>
					<Field name='standardVacationLeaveDays'>
						{({ input, meta }) => (
							<FieldWrapper
								name='standardVacationLeaveDays'
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								required
							>
								<Input {...input} placeholder='20' />
							</FieldWrapper>
						)}
					</Field>
					<HRThemedButton
						type='button'
						buttonType={ButtonTypes.primary}
						onClick={() => handleUpdateVacationLeaveDays()}
					>
						Add Custom Time Off
					</HRThemedButton>
				</Styled.SectionTopLine>
				<Styled.ListOfItems>
					{vacationLeaveDays.map(({ id, employeeId, days }) => (
						<Styled.ListItem key={employeeId}>
							<Styled.ListItemTitle>{employeesList[employeeId]}</Styled.ListItemTitle>
							<Styled.ListItemDate>{`${days} days`}</Styled.ListItemDate>
							<Styled.ListItemEditIcon onClick={() => handleUpdateVacationLeaveDays(id)}>
								<EditIcon fill={COLORS.darkGray2} width='24px' height='24px' />
							</Styled.ListItemEditIcon>
						</Styled.ListItem>
					))}
				</Styled.ListOfItems>
			</Styled.Section>
		</Styled.Block>
	);
};

export default HRHolidayAbsencePolicyBlock;
