import dayjs from 'dayjs';

import { IEmployeeValues } from 'modules/Common/types/employee';

import { CreateEmployeeStateFromAtsType } from './CreateEditEmployee.types';

export const normalizeEmployeeStateData = (
	data: CreateEmployeeStateFromAtsType,
	isEditMode: boolean,
) => {
	if (isEditMode || !data) return {};

	const fullName = data.fullName.split(' ');
	const firstName = fullName[0] || '';
	const lastName = fullName.slice(1).join(' ') || '';

	return {
		firstName,
		lastName,
		personalPhoneNumber: data.phoneNumber || '',
		jobTitle: data.jobTitle || '',
	};
};

export const prepareEmployeeValues = (values: IEmployeeValues) => ({
	...values,
	startDate: values.startDate ? dayjs(values.startDate) : null,
	leaveDate: values.leaveDate ? dayjs(values.leaveDate) : null,
	birthdayDate: values.birthdayDate ? dayjs(values.birthdayDate) : null,
	role: (values?.role || values?.role?.id) || null,
});
