export const hrCalendarDataFromAPI = [
	{
		id: 1,
		startDate: '2024-08-01T09:00:00.000Z',
		endDate: '2024-08-01T17:00:00.000Z',
		hours: 8,
		type: 'VACATION',
		firstDayType: 'FULL',
		lastDayType: 'FULL',
		description: 'Family trip',
		isApproved: true,
		isDeclined: false,
		isPending: false,
		employeeId: 1001,
		employeeFullName: 'Alice Smith',
		employeePhotoUuid: '10418fb7-f6b3-47a7-9d1f-623ea0d2225b',
		comments: 'Taking a day off for a family trip.',
	},
	{
		id: 2,
		startDate: '2024-08-05T09:00:00.000Z',
		endDate: '2024-08-05T13:00:00.000Z',
		hours: 4,
		type: 'SICK LEAVE',
		firstDayType: 'HALF',
		lastDayType: 'HALF',
		description: 'Doctor appointment',
		isApproved: false,
		isDeclined: false,
		isPending: true,
		employeeId: 1002,
		employeeFullName: 'Bob Johnson',
		employeePhotoUuid: '10418fb7-f6b3-47a7-9d1f-623ea0d2225b',
		comments: 'Half-day sick leave for a doctor appointment.',
	},
	{
		id: 3,
		startDate: '2024-08-10T09:00:00.000Z',
		endDate: '2024-08-17T17:00:00.000Z',
		hours: 56,
		type: 'MATERNITY LEAVE',
		firstDayType: 'FULL',
		lastDayType: 'FULL',
		description: 'Maternity leave',
		isApproved: false,
		isDeclined: false,
		isPending: true,
		employeeId: 35,
		employeeFullName: 'Carol Williams',
		employeePhotoUuid: null,
		comments: 'Starting maternity leave.',
	},
	{
		id: 41,
		startDate: '2024-08-13T09:00:00.000Z',
		endDate: '2024-08-20T17:00:00.000Z',
		hours: 8,
		type: 'OTHER',
		firstDayType: 'FULL',
		lastDayType: 'FULL',
		description: 'Personal errand',
		isApproved: true,
		isDeclined: false,
		isPending: false,
		employeeId: 1004,
		employeeFullName: 'David Grey',
		employeePhotoUuid: null,
		comments: 'Taking a few days off for personal errands.',
	},
	{
		id: 4,
		startDate: '2024-08-20T09:00:00.000Z',
		endDate: '2024-08-20T17:00:00.000Z',
		hours: 8,
		type: 'OTHER',
		firstDayType: 'FULL',
		lastDayType: 'FULL',
		description: 'Personal errand',
		isApproved: true,
		isDeclined: false,
		isPending: false,
		employeeId: 1004,
		employeeFullName: 'David Brown',
		employeePhotoUuid: null,
		comments: 'Taking a day off for personal errands.',
	},
	{
		id: 5,
		startDate: '2024-08-25T09:00:00.000Z',
		endDate: '2024-08-25T17:00:00.000Z',
		hours: 8,
		type: 'VACATION',
		firstDayType: 'FULL',
		lastDayType: 'FULL',
		description: 'Vacation day',
		isApproved: false,
		isDeclined: false,
		isPending: true,
		employeeId: 1005,
		employeeFullName: 'Eve Davis',
		employeePhotoUuid: null,
		comments: 'Taking a vacation day.',
	},
	{
		id: 6,
		startDate: '2024-08-28T09:00:00.000Z',
		endDate: '2024-08-30T17:00:00.000Z',
		hours: 24,
		type: 'SICK LEAVE',
		firstDayType: 'FULL',
		lastDayType: 'FULL',
		description: 'Recovery from illness',
		isApproved: false,
		isDeclined: true,
		isPending: false,
		employeeId: 1006,
		employeeFullName: 'Frank Harris',
		employeePhotoUuid: null,
		comments: 'Taking a few days off to recover from an illness.',
	},
	{
		id: 7,
		startDate: '2024-08-28T09:00:00.000Z',
		endDate: '2024-08-28T17:00:00.000Z',
		hours: 24,
		type: 'PUBLIC HOLIDAY',
		eventName: 'Humor Day',
		firstDayType: 'FULL',
		lastDayType: 'FULL',
		description: '',
		isApproved: true,
		isDeclined: false,
		isPending: false,
		employeeId: 1006,
		employeeFullName: '',
		employeePhotoUuid: null,
		comments: '',
	},
	{
		id: 46,
		startDate: '2024-08-14T09:00:00.000Z',
		endDate: '2024-08-14T13:00:00.000Z',
		hours: 4,
		type: 'SICK LEAVE',
		firstDayType: 'HALF',
		lastDayType: 'HALF',
		description: 'Doctor appointment',
		isApproved: true,
		isDeclined: false,
		isPending: false,
		employeeId: 1002,
		employeeFullName: 'Bob Johnson',
		employeePhotoUuid: '10418fb7-f6b3-47a7-9d1f-623ea0d2225b',
		comments: 'Half-day sick leave for a doctor appointment.',
	},
];
