import React, { FC } from 'react';
import { useMedia } from 'react-use';

import { EmployeeRequestTimeOffType } from 'modules/HR/components/HRRequestComponent/HRRequestComponent.types';
import TimeOffUsedStatisticsEmployee from 'modules/HR/components/TimeOffUsedStatisticsEmployee';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';

import HRCalendarWeekly from '../../HRCalendarWeekly';
import { Styled } from '../HRDashboard.styled';
import { StatisticsDataType } from '../HRDashboard.types';
import RequestsWidget from '../RequestsWidget/RequestsWidget';

interface EmployeeViewProps {
	showRequestTab: boolean;
	showCalendarTab: boolean;
	statisticData: StatisticsDataType;
	employeeRequests: EmployeeRequestTimeOffType[];
	employeeViewUpcomingVacationsRequests: EmployeeRequestTimeOffType[];
	userId: number;
}

const EmployeeView: FC<EmployeeViewProps> = ({
	showRequestTab,
	showCalendarTab,
	statisticData,
	employeeRequests,
	employeeViewUpcomingVacationsRequests,
	userId,
}) => {
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	return (
		<Styled.DashboardLayout>
			{showCalendarTab && <HRCalendarWeekly />}
			<Styled.LayoutColumns isMobile={isMobile}>
				<Styled.LeftColumn isMobile={isMobile}>
					{showRequestTab && (
						<RequestsWidget
							title='My Time Offs History'
							emptyRequestsTitle='No requests at the moment'
							isEmployeeView={true}
							employeeRequests={employeeRequests}
							userId={userId}
						/>
					)}
				</Styled.LeftColumn>
				<Styled.RightColumn isMobile={isMobile}>
					<TimeOffUsedStatisticsEmployee statisticData={statisticData} />
					<RequestsWidget
						title='Upcoming Vacations'
						emptyRequestsTitle='No upcoming vacations at the moment'
						isEmployeeView={true}
						employeeRequests={employeeViewUpcomingVacationsRequests}
						shortView={true}
					/>
				</Styled.RightColumn>
			</Styled.LayoutColumns>
		</Styled.DashboardLayout>
	); };

export default EmployeeView;
