import { Routes, SettingsRoutes, BackOfficeSettingsRoutes } from './routes';

export enum SubscriptionPlanEnum {
	FREE = 'Free',
	PREMIUM = 'Premium',
}

export type SubscriptionPlansType = {
	isPremiumPlan: boolean;
};

export type CountriesType = {
	isUK: boolean;
	isUS: boolean;
};

export enum CountriesEnumIds {
	UK = 1,
	US = 2,
}

export const enum UserRolesEnum {
	BACK_SUPER_USER = 1,
	BACK_MANAGER = 2,
	BACK_SALES = 3,
	BACK_CUSTOMER_SUPPORT = 4,
	JAT_SUPER_USER = 5,
	JAT_USER = 6,
	JAT_FINANCE_USER = 7,
	HR_EMPLOYEE = 10,
	HR_MANAGER = 12,
}

export const enum UserRoleNamesEnum {
	BACK_SUPER_USER = 'BACK_SUPER_USER',
	BACK_MANAGER = 'BACK_MANAGER',
	BACK_SALES = 'BACK_SALES',
	BACK_CUSTOMER_SUPPORT = 'BACK_CUSTOMER_SUPPORT',
	JAT_SUPER_USER = 'JAT_SUPER_USER',
	JAT_USER = 'JAT_USER',
	JAT_FINANCE_USER = 'JAT_FINANCE_USER',
	HR_EMPLOYEE = 'JAT_USER',
	HR_MANAGER = 'JAT_FINANCE_USER',
}

export const enum UserTypesEnum {
	BACKOFFICE = 1,
	ATS = 2,
	HR_MODULE = 3,
}

export const enum UserTypesValueEnum {
	BACKOFFICE = 'backoffice',
	ATS = 'ats',
	HR_MODULE = 'hr',
}

export type UserRoleType = {
	id: number;
	name: UserRoleNamesEnum;
	value: string;
};

export enum AppModes {
	Preview = 'PREVIEW',
	Edit = 'EDIT',
	App = 'APP',
}

export type UserRolesType = {
	isSuperUser: boolean;
	isManagerUser: boolean;
	isSalesUser: boolean;
	isServiceUser: boolean;
	isAtsSuperUser: boolean;
	isAtsStandardUser: boolean;
	isAtsFinanceUser: boolean;
};

export const BORouteRoleMap = {
	[`${Routes.BOClientContext}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
		'isAtsSuperUser',
		'isAtsStandardUser',
		'isAtsFinanceUser',
	],
	[`${Routes.BackOffice}${Routes.Dashboard}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Clients}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.ClientCreate}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.EditClient}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.InvoicesView}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.CreateClientBranding}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.EditClientBranding}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.SupportTickets}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.CreateSupportTicket}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.EditSupportTicket}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Jobs}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.CreateJob}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.EditJob}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Questionnaires}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.ContractsCreate}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Contracts}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.ContractsView}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.ContractsEdit}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Invoices}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.InvoicesCreate}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.InvoiceEdit}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Rates}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Users}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Candidates}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Management}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${Routes.BackOffice}${Routes.Settings}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${BackOfficeSettingsRoutes.CreditPrices}`]: ['isSuperUser'],
	[`${BackOfficeSettingsRoutes.EmailTemplates}`]: ['isSuperUser'],
	[`${BackOfficeSettingsRoutes.ChangePassword}`]: [
		'isSuperUser',
		'isManagerUser',
		'isSalesUser',
		'isServiceUser',
	],
	[`${BackOfficeSettingsRoutes.UserAccess}`]: ['isSuperUser'],
	[`${BackOfficeSettingsRoutes.UserAccessCreate}`]: ['isSuperUser'],
	[`${BackOfficeSettingsRoutes.UserAccessEdit}`]: ['isSuperUser'],
};

export const AtsRouteRoleMap = {
	[`${Routes.ATS}${Routes.Dashboard}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.Statistic}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.Candidates}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.CandidatesDatabase}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.CandidatesSendSMS}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.AppliedJobsCandidate}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.Interviews}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.ArrangeInterviewCreate}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.ArrangeInterviewEdit}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.Jobs}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.JobView}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.PostJob}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.PostJobCopy}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.PostJobEdit}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.Questionnaires}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.QuestionnairesCreate}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.QuestionnairesCopy}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.QuestionnairesEdit}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.QuestionnairesSend}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.Messages}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.BuyVideoCredits}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.BuySMSCredits}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.Credits}`]: ['isAtsSuperUser', 'isAtsStandardUser', 'isAtsFinanceUser'],
	[`${Routes.ATS}${Routes.SupportCenter}`]: [
		'isAtsSuperUser',
		'isAtsStandardUser',
		'isAtsFinanceUser',
	],
	[`${Routes.ATS}${Routes.SupportCenterTicketCreate}`]: [
		'isAtsSuperUser',
		'isAtsStandardUser',
		'isAtsFinanceUser',
	],
	[`${Routes.ATS}${Routes.Finance}`]: ['isAtsSuperUser', 'isAtsStandardUser', 'isAtsFinanceUser'],
	[`${Routes.ATS}${Routes.InvoiceForward}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.ContractsView}`]: [
		'isAtsSuperUser',
		'isAtsStandardUser',
		'isAtsFinanceUser',
	],
	[`${Routes.ATS}${Routes.InvoicesView}`]: [
		'isAtsSuperUser',
		'isAtsStandardUser',
		'isAtsFinanceUser',
	],
	[`${Routes.ATS}${Routes.CreateMessage}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.UserProfile}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${Routes.ATS}${Routes.Settings}`]: ['isAtsSuperUser', 'isAtsStandardUser', 'isAtsFinanceUser'],
	[`${SettingsRoutes.CompanyDetails}`]: ['isAtsSuperUser', 'isAtsStandardUser', 'isAtsFinanceUser'],
	[`${SettingsRoutes.Subscription}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.Branding}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.SocialNetworks}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.EmailTemplates}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.EmailNotifications}`]: [
		'isAtsSuperUser',
		'isAtsStandardUser',
		'isAtsFinanceUser',
	],
	[`${SettingsRoutes.SMSReminders}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${SettingsRoutes.EmailSignature}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${SettingsRoutes.DefaultMessages}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.Authentication}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.AutoPop}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
	[`${SettingsRoutes.ChangePassword}`]: ['isAtsSuperUser', 'isAtsStandardUser', 'isAtsFinanceUser'],
	[`${SettingsRoutes.UserAccess}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.UserAccessCreate}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.UserAccessEdit}`]: ['isAtsSuperUser'],
	[`${SettingsRoutes.PersonalData}`]: ['isAtsSuperUser', 'isAtsStandardUser'],
};

export const AtsRouteCountryMap = {
	[`${Routes.ATS}${Routes.Dashboard}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.Statistic}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.Candidates}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.AppliedJobsCandidate}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.CandidatesDatabase}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.CandidatesSendSMS}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.Interviews}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.ArrangeInterviewCreate}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.ArrangeInterviewEdit}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.Jobs}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.JobView}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.PostJob}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.PostJobCopy}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.PostJobEdit}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.PostJobBoost}`]: ['isUK'],
	[`${Routes.ATS}${Routes.Questionnaires}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.QuestionnairesCreate}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.QuestionnairesCopy}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.QuestionnairesEdit}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.QuestionnairesSend}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.Messages}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.Credits}`]: ['isUK'],
	[`${Routes.ATS}${Routes.SupportCenter}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.SupportCenterTicketCreate}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.Finance}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.InvoiceForward}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.ContractsView}`]: ['isUK'],
	[`${Routes.ATS}${Routes.InvoicesView}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.UserProfile}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.Settings}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.BuyVideoCredits}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.BuySMSCredits}`]: ['isUK', 'isUS'],
	[`${Routes.ATS}${Routes.CreateMessage}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.CompanyDetails}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.Subscription}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.Branding}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.SocialNetworks}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.EmailTemplates}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.EmailNotifications}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.SMSReminders}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.EmailSignature}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.DefaultMessages}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.Authentication}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.AutoPop}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.ChangePassword}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.UserAccess}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.UserAccessCreate}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.UserAccessEdit}`]: ['isUK', 'isUS'],
	[`${SettingsRoutes.PersonalData}`]: ['isUK', 'isUS'],
};
