import React, { type FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import { pick, difference } from 'lodash';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import { useMount } from 'hooks';
import { acceptedImageFileFormat } from 'modules/Common/constants';
import { IEmployeeValues } from 'modules/Common/types/employee';
import { getSingleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { employeeFormAllowedFields } from 'modules/HR/constants/HRModuleConstants.constants';
import { hrDucks } from 'modules/HR/ducks';
import { UserRolesEnum } from 'types';

import { Routes } from '../../routes/types';

import AddressDetails from './AddressDetails';
import { AllowedNonSUProfileFields, AllowedSUProfileFields } from './CreateEditEmployee.constants';
import { normalizeEmployeeStateData, prepareEmployeeValues } from './CreateEditEmployee.helpers';
import { Styled } from './CreateEditEmployee.styled';
import { CreateEditEmployeeProps } from './CreateEditEmployee.types';
import EmergencyContacts from './EmergencyContacts';
import JobDetails from './JobDetails';
import OtherDetails from './OtherDetails';
import Password from './Password';
import PersonalDetails from './PersonalDetails';

const baseUrl = process.env.REACT_APP_API_URL;

const currentUserId = 1;

const CreateEditEmployee: FC<CreateEditEmployeeProps> = ({
	isEditMode = false,
	employeeId,
	employeeRolesList,
	loading,
	getEmployeeRoles,
	createEmployeeRequested,
	getEmployeeByIdRequested,
	getOwnProfileRequested,
	employeeDetails,
	isOwnProfile,
	editEmployeeRequested,
	editOwnProfileRequested,
}) => {
	const [photoUuid, setPhotoUuid] = useState<string | null>(null);
	const currentEditEmployeeFields = isEditMode && employeeDetails;
	const isFieldDisabledForEdit =
		isEditMode && currentEditEmployeeFields?.role?.id === UserRolesEnum.HR_EMPLOYEE;
	const isRoleFieldVisible =
		!isEditMode ||
		(currentEditEmployeeFields?.role?.id === UserRolesEnum.HR_MANAGER &&
			currentEditEmployeeFields.userId !== currentUserId);

	const navigate = useNavigate();
	const { state } = useLocation();

	const initialValues = useMemo(
		() => ({
			personalPhone: null,
			workPhone: null,
			emergencyContactPhone: null,
			usesDefaultOnboardingTemplate: true,
			...normalizeEmployeeStateData(state, isEditMode),
			...(isEditMode &&
				currentEditEmployeeFields &&
				prepareEmployeeValues(currentEditEmployeeFields)),
		}),
		[employeeDetails],
	);

	const onSubmit = (values: IEmployeeValues) => {
		const valuesToSend = {
			...pick(values, employeeFormAllowedFields),
			photo: photoUuid,
			startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
			birthdayDate: dayjs(values.birthdayDate).format('YYYY-MM-DD'),
			...(values?.leaveDate && {
				leaveDate: dayjs(values.leaveDate).format('YYYY-MM-DD'),
			}),
		} as IEmployeeValues;
		const path = `${Routes.HRModule}${Routes.PeopleManagement}`;

		if (isEditMode) {
			if (isOwnProfile) {
				const isSuperUser =
					currentEditEmployeeFields?.role === UserRolesEnum.HR_MANAGER ||
					currentEditEmployeeFields?.role === UserRolesEnum.JAT_SUPER_USER;
				const allowedFields = isSuperUser ? AllowedSUProfileFields : AllowedNonSUProfileFields;

				editOwnProfileRequested(isSuperUser, pick(valuesToSend, allowedFields));
			} else {
				editEmployeeRequested(true, values?.employeeId, valuesToSend, () => navigate(path));
			}
		} else {
			createEmployeeRequested(valuesToSend, () => navigate(path));
		}
	};

	useEffect(() => {
		if (currentEditEmployeeFields?.photo) {
			setPhotoUuid(currentEditEmployeeFields?.photo);
		}
	}, [currentEditEmployeeFields]);

	const handleCancel = (event: FormEvent, reset: () => void) => {
		event.preventDefault();

		reset();
	};

	const handleSetProfilePhoto = (photoId: string) => setPhotoUuid(photoId);

	const handleDeletePhoto = () => setPhotoUuid('');

	const photoProfileUploaderProps = getSingleFileUploaderProps(
		(url, responseData) => {
			setPhotoUuid(responseData);
		},
		{
			showUploadList: false,
			accept: acceptedImageFileFormat,
			url: `${baseUrl}/hr/employee/photo`,
		},
	);

	const disabledFields =
		isOwnProfile && currentEditEmployeeFields?.role === UserRolesEnum.HR_EMPLOYEE
			? difference(AllowedSUProfileFields, AllowedNonSUProfileFields)
			: [];

	useMount(() => {
		getEmployeeRoles();
		if (isOwnProfile) {
			getOwnProfileRequested();
		}
		if (isEditMode && employeeId) {
			getEmployeeByIdRequested(employeeId, true);
		}
	});

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				render={({ handleSubmit, dirty, form }) => (
					<form onSubmit={handleSubmit}>
						<Styled.FormFieldsWrap>
							<PersonalDetails
								isEditMode={isEditMode}
								photo={photoUuid}
								rolesList={employeeRolesList}
								handleSetProfilePhoto={handleSetProfilePhoto}
								handleDeletePhoto={handleDeletePhoto}
								photoProfileUploaderProps={photoProfileUploaderProps}
								isFieldDisabledForEdit={isFieldDisabledForEdit}
								isRoleFieldVisible={true}
								disabledFields={disabledFields}
							/>
							<AddressDetails />
							<JobDetails
								disabledFields={disabledFields}
								formObj={form}
								isFieldDisabledForEdit={isFieldDisabledForEdit}
							/>
							<EmergencyContacts />
							<Password formObj={form} />
							<OtherDetails disabledFields={disabledFields} />
						</Styled.FormFieldsWrap>
						<Styled.ButtonBox>
							<Styled.Button
								type='button'
								buttonType={ButtonTypes.default}
								onClick={(e) => handleCancel(e, form.reset)}
							>
								Cancel
							</Styled.Button>
							<Button
								type='submit'
								buttonType={ButtonTypes.primary}
								disabled={!dirty}
								// loading={!!loading?.createEmployee}
							>
								{isEditMode ? 'Save Changes' : 'Add Employee'}
							</Button>
						</Styled.ButtonBox>
					</form>
				)}
			></Form>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		employeeRolesList: hrDucks.hrSelectors.getEmployeeRoles(state),
		loading: hrDucks.hrSelectors.getHrModuleLoading(state),
		employeeDetails: hrDucks.hrSelectors.getEmployeeDetails(state),
	}),
	{
		getEmployeeRoles: hrDucks.hrActions.getEmployeeRolesRequested,
		getEmployeeByIdRequested: hrDucks.hrActions.getEmployeeByIdRequested,
		getOwnProfileRequested: hrDucks.hrActions.getOwnProfileRequested,
		createEmployeeRequested: hrDucks.hrActions.createEmployeeRequested,
		editEmployeeRequested: hrDucks.hrActions.editEmployeeRequested,
		editOwnProfileRequested: hrDucks.hrActions.editOwnProfileRequested,
	},
)(CreateEditEmployee);
