export enum EmployeeStatusEnum {
	PENDING = 'Pending',
	CURRENT = 'Current',
	LEAVING = 'Leaving',
	LEFT = 'Left',
}

export enum EmployeeStatusValuesEnum {
	Pending = 'Pending',
	Current = 'Current',
	Leaving = 'Leaving',
	Left = 'Left',
}

export enum EmployeeStatusTypesFormEnum {
	Pending = 1,
	Current = 2,
	Leaving = 3,
	Left = 4,
}

export interface IEmployeeState {
	id: EmployeeStatusTypesFormEnum;
	name: EmployeeStatusEnum;
	value: EmployeeStatusValuesEnum;
}

export interface IEmployeeTableValues {
	employeeId: number;
	userId: number;
	photo: string;
	firstName: string;
	lastName: string;
	jobTitle: string;
	email: string;
	startDate: string;
	leaveDate: string;
	status: IEmployeeState;
	workPhone: string;
}

export interface IEmployeeValues {
	photo: string | null;
	firstName: string;
	lastName: string;
	role: number;
	email: string;
	birthdayDate: string;
	personalPhone?: string;
	workPhone?: string;
	postCode?: string;
	city?: string;
	country?: string;
	address?: string;
	employeeId?: string;
	leaveDate: string;
	startDate: string;
	jobTitle: string;
	departmentName: string;
	emergencyContactFullName: string;
	emergencyContactPhone: string;
	password: string;
	usesDefaultOnboardingTemplate: boolean;
}

export interface IEmployeeShortValues {
	id: number;
	photo: string;
	firstName: string;
	lastName: string;
	status: IEmployeeState;
	isNew: boolean;
}

export interface IEmployeeProfileBase {
	employeeId: number;
	userId: number;
	firstName: string;
	lastName: string;
	photo: string;
	jobTitle: string;
	departmentName: string;
	city: string;
	startDate: string;
	workPhone: string;
	email: string;
	birthdayDate: string;
}

export interface IEmployeeProfileSuperUser extends IEmployeeProfileBase {
	personalPhone: string;
	emergencyContactFullName: string;
	emergencyContactPhone: string;
	address: string;
	postCode: string;
	country: string;
	showFirstDayInvitation: boolean;
}
