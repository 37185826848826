import React, { type FC } from 'react';

import { Button } from 'antd';

import { IUser } from 'modules/Common/types';
import { capitalizeFirstLetterOfEachWord } from 'utils/helpers';

import { Styled } from './HRCalendarPopupEvent.styled';

type HRCalendarPopupEventProps = {
	id: number;
	employeeId: number;
	isApproved: boolean;
	isDeclined: boolean;
	isPending: boolean;
	user: IUser;
	type: string;
	start: Date;
	end: Date;
	comment: string;
	handleApprove: (id: number) => void;
	handleDecline: (id: number) => void;
	handleEdit: (id: number) => void;
};

const HRCalendarPopupEvent: FC<HRCalendarPopupEventProps> = ({
	id,
	employeeId,
	isApproved,
	isDeclined,
	isPending,
	user,
	type,
	start,
	end,
	comment,
	handleApprove,
	handleDecline,
	handleEdit,
}) => {
	const { id: currentUserId } = user || {};

	return (
		<Styled.PopoverContent>
			<Styled.PopoverEventType>{capitalizeFirstLetterOfEachWord(type)}</Styled.PopoverEventType>
			<Styled.PopoverEventDate>{start.toDateString()}</Styled.PopoverEventDate>
			<Styled.PopoverCommentsSection>
				<h6>Comments</h6>
				<p>{comment}</p>
			</Styled.PopoverCommentsSection>
			{isPending && (
				<Styled.ButtonBox>
					{currentUserId === employeeId ? (
						<Button type='primary' onClick={() => id && handleEdit(id)}>
							Edit
						</Button>
					) : (
						<>
							<Button danger onClick={() => id && handleDecline(id)}>
								Decline
							</Button>
							<Styled.ButtonApprove type='primary' onClick={() => id && handleApprove(id)}>
								Approve
							</Styled.ButtonApprove>
						</>
					)}
				</Styled.ButtonBox>
			)}
		</Styled.PopoverContent>
	);
};

export default HRCalendarPopupEvent;
