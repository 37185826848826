import React, { FC, useEffect, useState } from 'react';

import { getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';

import { Styled } from './UserIcon.styled';

type UserIconProps = {
	imageId?: string;
	backgroundColor: string;
	width?: string;
	fullName?: string;
	fetchAndSetImageHelper?: (imageId: string, setImageUrl: (link: string) => void) => void;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

export const UserIcon: FC<UserIconProps> = ({
	imageId = '',
	backgroundColor,
	width = '40px',
	fullName = 'User',
	fetchAndSetImageHelper,
}) => {
	const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>('');

	useEffect(() => {
		if (!imageId) return;

		if (fetchAndSetImageHelper) {
			fetchAndSetImageHelper(imageId, setBackgroundImageUrl);
		} else {
			setBackgroundImageUrl(`${baseUrl}${imageId}`);
		}
	}, [imageId, fetchAndSetImageHelper]);

	return (
		<Styled.Icon
			width={width}
			backgroundImageUrl={backgroundImageUrl}
			backgroundColor={backgroundColor}
		>
			{!backgroundImageUrl && getFirstLettersUppercase(fullName)}
		</Styled.Icon>
	);
};
