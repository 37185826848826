import React, { type FC, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { isEqual } from 'lodash';

import Spinner from 'components/Spinner';
import CVViewIcon from 'components/SVG/CVViewIcon';
import DotIcon from 'components/SVG/DotIcon';
import EditIcon from 'components/SVG/EditIcon';
import EyeOpenIcon from 'components/SVG/EyeOpenIcon';
import KanbanViewIcon from 'components/SVG/KanbanViewIcon';
import ListViewIcon from 'components/SVG/ListViewIcon';
import MapMarkerIcon from 'components/SVG/MapMarkerIcon';
import UsdIcon from 'components/SVG/UsdIcon';
import { useMount, usePrevious, useUnmount } from 'hooks';
import CandidateListSwitch from 'modules/ATS/components/CandidateListSwitcher';
import EmployeeToHRModal from 'modules/ATS/components/EmployeeToHRModal';
import SubscriptionModal from 'modules/ATS/components/SubscriptionModal';
import { ActivateJobOptions } from 'modules/Common/constants';
import { UNASSIGNED_JOB_TITLE } from 'modules/Common/constants/table';
import { commonDucks } from 'modules/Common/ducks';
import {
	CandidateResponseType,
	CandidateStatusType,
	ClientBrandingDataType,
	ICandidatesData,
	ICandidatesSortedByStatus,
	IUpdateCandidateRankProps,
	JobClassesEnum,
	JobDataType,
	QueryParamsSearchType,
	QueryParamsType,
} from 'modules/Common/types';
import { Routes as HRRoutes } from 'modules/HR/routes/types';
import { COLORS } from 'theme';
import { DataFormatEnum, GenericType, Routes, SubscriptionPlansType } from 'types';
import { getDateFormat, sleep } from 'utils/helpers';

import CandidateApplicationDetails from '../../containers/CandidateApplicationDetails';
import { atsDucks } from '../../ducks';
import { CandidatesStatusValuesEnum } from '../CandidatesApplication/CandidatesApplication.types';
import CandidatesApplicationTableListContainer from '../CandidatesApplication/CandidatesApplicationTableListContainer';
import KanbanCandidatesView from '../KanbanCandidatesView';

import CandidateApplicationPanelMenu from './CandidateApplicationPanelMenu';
import { Styled } from './JobDetailsView.styled';

const SWITCHER_ITEMS = [
	{
		id: '0',
		value: '0',
		icon: <CVViewIcon />,
	},
	{
		id: '1',
		value: '1',
		icon: <ListViewIcon />,
	},
	{
		id: '2',
		value: '2',
		icon: <KanbanViewIcon />,
	},
];

type ATSJobsListPageProps = {
	autoPop: boolean;
	getJobById: (jobId: string | undefined) => void;
	candidatesByStatus: ICandidatesSortedByStatus[];
	jobData: JobDataType;
	subscriptionPlans: SubscriptionPlansType;
	getClientAutoPop: () => void;
	resetJobDetails: () => void;
	clientAtsBrandingData: ClientBrandingDataType;
	updateCandidateStatus: CandidateStatusType;
	updateCandidateRankRequested: (props: IUpdateCandidateRankProps) => void;
	getCandidateByIdRequested: (id: number) => void;
	archiveAndUnarchiveJob: (jobId: string | undefined, active: boolean) => void;
	queryParams: QueryParamsType;
	saveSearchQueryParams: (params: QueryParamsSearchType | null) => void;
	loading: GenericType;
};

const JobDetailsView: FC<ATSJobsListPageProps> = ({
	autoPop,
	jobData,
	getJobById,
	getClientAutoPop,
	candidatesByStatus,
	clientAtsBrandingData,
	subscriptionPlans,
	updateCandidateStatus,
	updateCandidateRankRequested,
	getCandidateByIdRequested,
	archiveAndUnarchiveJob,
	queryParams,
	saveSearchQueryParams,
	resetJobDetails,
	loading,
}) => {
	const isUnassignedJob = jobData?.title === UNASSIGNED_JOB_TITLE;
	const navigate = useNavigate();
	const [activeItem, onSwitch] = useState(SWITCHER_ITEMS[0].id);
	const { jobId } = useParams();
	const [currentCandidate, setCurrentCandidate] = useState<Partial<ICandidatesData> | null>(null);
	const previousCandidatesData = usePrevious(jobData?.candidates);
	const [employeeToHRModal, setEmployeeToHRModal] = useState<CandidateResponseType | null>(null);
	const [subscriptionModal, setSubscriptionModal] = useState<boolean>(false);
	const updateCandidateByClientPopTriggeredRef = useRef(false);

	const handleChangeCandidate = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
		e.preventDefault();

		setCurrentCandidate({
			...jobData?.candidates?.find((item) => item?.id === id),
		});
	};

	const handleOpenSubscriptionModal = useCallback(() => {
		setSubscriptionModal(true);
	}, []);

	const handleCloseSubscriptionModal = useCallback(() => {
		setSubscriptionModal(false);
	}, []);

	const handleOpenEmployeeToHRModal = useCallback(
		(status: CandidatesStatusValuesEnum, employeeData: CandidateResponseType[]) => {
			if (status === CandidatesStatusValuesEnum.Hired && employeeData.length) {
				setEmployeeToHRModal(employeeData[0]);
			}
		},
		[],
	);

	const handleCloseEmployeeToHRModal = useCallback(() => {
		setEmployeeToHRModal(null);
	}, []);

	const handleMoveCandidateToHRModule = useCallback(() => {
		handleCloseEmployeeToHRModal();
		if (subscriptionPlans.isPremiumPlan) {
			navigate(`${HRRoutes.HRModule}${HRRoutes.EmployeeCreate}`, {
				state: { ...employeeToHRModal, jobTitle: jobData?.title || '' },
			});
		} else {
			handleOpenSubscriptionModal();
		}
	}, [employeeToHRModal]);

	const handleUpdateCandidateStatus = useCallback(
		(
			candidateIds: number[],
			status: string,
			job?: number | undefined,
			callback?: (ids: number[]) => void,
		) => {
			updateCandidateStatus(candidateIds, status, job, (__, successfulCandidates) => {
				callback && callback(candidateIds);
				handleOpenEmployeeToHRModal(status as CandidatesStatusValuesEnum, successfulCandidates);

				if (autoPop && status === CandidatesStatusValuesEnum.Rejected) {
					const { candidates } = jobData;
					let candidateInGroupId = 0;
					const candidate = candidates.find((c) => c.id === candidateIds[0]);
					const getCandidatesByStatus = (st, currentCandidateId, cand) =>
						cand
							?.filter((item) => item.candidateState?.value === st)
							.filter((c, idx) => {
								const isCurrentCandidate = c.id === currentCandidateId;
								if (isCurrentCandidate) {
									candidateInGroupId = idx;
								}

								return !isCurrentCandidate;
							});
					const getCandidatesByStatusCur = (st) =>
						getCandidatesByStatus(st, currentCandidate?.id, candidates);
					const candidatesMap = {
						[CandidatesStatusValuesEnum.NewCandidate]: getCandidatesByStatusCur(
							CandidatesStatusValuesEnum.NewCandidate,
						),
						[CandidatesStatusValuesEnum.Interviews]: getCandidatesByStatusCur(
							CandidatesStatusValuesEnum.Interviews,
						),
						[CandidatesStatusValuesEnum.Potential]: getCandidatesByStatusCur(
							CandidatesStatusValuesEnum.Potential,
						),
						[CandidatesStatusValuesEnum.Offers]: getCandidatesByStatusCur(
							CandidatesStatusValuesEnum.Offers,
						),
						[CandidatesStatusValuesEnum.Hired]: getCandidatesByStatusCur(
							CandidatesStatusValuesEnum.Hired,
						),
						[CandidatesStatusValuesEnum.Rejected]: getCandidatesByStatusCur(
							CandidatesStatusValuesEnum.Rejected,
						),
					};

					const initialCandidateList = candidatesMap[candidate?.candidateState?.value];

					const getFirstNonEmptyCandidatesGroup = (statusesEnum, groupedCandidates) => {
						let firstNonEmptyConllection = null;

						for (let index = 0; index < statusesEnum.length; index++) {
							const currentCollection = groupedCandidates[statusesEnum[index]];

							if (currentCollection[0]) {
								firstNonEmptyConllection = currentCollection;
								break;
							}
						}

						return firstNonEmptyConllection;
					};
					const currentList = initialCandidateList?.length
						? initialCandidateList
						: getFirstNonEmptyCandidatesGroup(
							Object.values(CandidatesStatusValuesEnum),
							candidatesMap,
						  );

					if (!currentList) {
						updateCandidateByClientPopTriggeredRef.current = true;
						setCurrentCandidate(currentCandidate);
					}

					const nextCandidateIndex = currentList[candidateInGroupId]
						? candidateInGroupId
						: candidateInGroupId - 1;

					updateCandidateByClientPopTriggeredRef.current = true;
					setCurrentCandidate(currentList[nextCandidateIndex]);
				}
			});
		},
		[jobData, currentCandidate],
	);

	const handleCandidateRank = useCallback(
		(value: number, callback: () => void) => {
			currentCandidate?.id !== undefined &&
				updateCandidateRankRequested({
					candidateAppId: currentCandidate.id,
					rank: value,
					jobId,
					cb: callback,
				});
		},
		[jobData, currentCandidate?.id],
	);

	const handleChangeActiveJob = useCallback(
		async (active: boolean) => {
			await archiveAndUnarchiveJob(jobId, active);
			await sleep(1000);
			await getJobById(jobId);
		},
		[archiveAndUnarchiveJob],
	);

	const handleSelectSpecificCandidate = useCallback(
		(id: number) => {
			const selectedCandidate = jobData?.candidates?.find((item) => item?.id === id);

			if (selectedCandidate) {
				setCurrentCandidate(() => selectedCandidate);
				onSwitch(() => SWITCHER_ITEMS[0].id);
			}
		},
		[jobData],
	);

	useEffect(() => {
		jobId && getJobById(jobId);
	}, [jobId]);

	useEffect(() => {
		!!jobData?.candidates?.length &&
			!currentCandidate &&
			setCurrentCandidate(jobData?.candidates[0]);
	}, [jobData]);

	useEffect(() => {
		if (updateCandidateByClientPopTriggeredRef.current) {
			updateCandidateByClientPopTriggeredRef.current = false;

			return;
		}

		const updatedCandidate =
			currentCandidate?.id &&
			jobData?.candidates?.find((item) => item?.id === currentCandidate?.id);
		if (!isEqual(jobData?.candidates, previousCandidatesData)) {
			updatedCandidate && setCurrentCandidate(updatedCandidate);
		}
	}, [jobData?.candidates]);

	useMount(() => {
		getClientAutoPop();
	});

	useEffect(() => {
		if (currentCandidate?.id) {
			// Update status New for Current Candidate
			currentCandidate.isNew && getCandidateByIdRequested(currentCandidate.id);
		}
	}, [currentCandidate?.id]);

	useUnmount(() => {
		saveSearchQueryParams(null);
		resetJobDetails();
	});

	if (loading?.getJobByIdLoad) {
		return <Spinner fixed />;
	}

	return (
		<Styled.Root>
			{jobData && (
				<Styled.MainInfo>
					<Styled.BoxWrapper>
						<Styled.InfoHead>
							<h2 color={clientAtsBrandingData?.titleColor}>{jobData?.title}</h2>
							{!isUnassignedJob && (
								<Styled.ControlButtonsBox>
									<Styled.Button
										onClick={() => navigate(`${Routes.ATS}${Routes.JobView}/${jobId}`)}
										icon={<EyeOpenIcon width='16' height='16' />}
										size='large'
									>
										View job
									</Styled.Button>
									{jobData?.jobClass !== JobClassesEnum.PaidJob && (
										<Styled.Button
											onClick={() => navigate(`${Routes.ATS}${Routes.PostJobEdit}/${jobId}`)}
											icon={<EditIcon width='16' height='16' fill={COLORS.black} />}
											size='large'
										/>
									)}
									<Styled.Select
										value={ActivateJobOptions.find((option) => option.value === jobData?.active)}
										onChange={(value) => handleChangeActiveJob(value as boolean)}
									>
										{ActivateJobOptions.map((option) => (
											<Styled.SelectOption key={option.id} value={option.value}>
												{option.name}
											</Styled.SelectOption>
										))}
									</Styled.Select>
								</Styled.ControlButtonsBox>
							)}
						</Styled.InfoHead>
						{!isUnassignedJob && (
							<Styled.InfoDetails>
								{jobData?.location && (
									<Styled.InfoDetailsItem>
										<MapMarkerIcon width='20' height='20' />
										<Styled.Label>{jobData?.location}</Styled.Label>
									</Styled.InfoDetailsItem>
								)}
								{jobData?.salary && (
									<>
										<DotIcon />
										<Styled.InfoDetailsItem>
											<UsdIcon width='20' height='20' />
											<Styled.Label>{jobData?.salary}</Styled.Label>
										</Styled.InfoDetailsItem>
									</>
								)}
								{jobData?.linkedQuestionnaire && (
									<>
										<DotIcon />
										<Styled.InfoDetailsItem>
											Linked Questionnaire: <Link to={jobData?.linkedQuestionnaire} />
										</Styled.InfoDetailsItem>
									</>
								)}
								{jobData?.startDate && jobData?.endDate && (
									<Styled.Data>
										{`Published: ${getDateFormat(
											jobData?.startDate,
											DataFormatEnum.Full,
										)} - ${getDateFormat(jobData?.endDate, DataFormatEnum.Full)}`}
									</Styled.Data>
								)}
							</Styled.InfoDetails>
						)}
					</Styled.BoxWrapper>
				</Styled.MainInfo>
			)}

			{jobData?.candidates?.length ? (
				<>
					<Styled.ContentTitlePanel>
						<Styled.ContentTitle>
							Candidate List <Styled.Amount>{jobData?.candidates?.length}</Styled.Amount>
						</Styled.ContentTitle>
						<CandidateListSwitch
							activeItem={activeItem}
							items={SWITCHER_ITEMS}
							onSwitch={onSwitch}
						/>
					</Styled.ContentTitlePanel>
					{currentCandidate && jobId && activeItem === SWITCHER_ITEMS[0].id && (
						<CandidateApplicationDetails
							currentJobId={jobId}
							currentCandidate={currentCandidate}
							panelMenuComponent={() => (
								<CandidateApplicationPanelMenu
									activeId={currentCandidate?.id}
									data={candidatesByStatus}
									onChangeCallback={handleChangeCandidate}
								/>
							)}
							updateCandidateStatus={handleUpdateCandidateStatus}
							updateCandidateRank={handleCandidateRank}
							showInfoList
						/>
					)}
					{jobData && activeItem === SWITCHER_ITEMS[1].id && (
						<CandidatesApplicationTableListContainer
							jobsData={[jobData]}
							updateCandidateStatus={handleUpdateCandidateStatus}
							handleSelectSpecificCandidate={handleSelectSpecificCandidate}
						/>
					)}
					{jobData && activeItem === SWITCHER_ITEMS[2].id && (
						<KanbanCandidatesView
							item={jobData}
							queryParams={queryParams}
							handleSelectSpecificCandidate={handleSelectSpecificCandidate}
							saveSearchQueryParams={saveSearchQueryParams}
							updateCandidateStatus={handleUpdateCandidateStatus}
						/>
					)}
				</>
			) : (
				<Styled.NoInfo>
					<img src='/images/no-data.png' alt='No candidates' />
					<p>You don’t have any candidates for this role yet, check back soon!</p>
				</Styled.NoInfo>
			)}
			<EmployeeToHRModal
				modalOpen={!!employeeToHRModal}
				handleApprove={handleMoveCandidateToHRModule}
				handleCloseModal={handleCloseEmployeeToHRModal}
			/>
			<SubscriptionModal
				modalOpen={subscriptionModal}
				handleCloseModal={handleCloseSubscriptionModal}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		autoPop: atsDucks.atsSelectors.getClientAutoPop(state)?.autoPop,
		jobData: commonDucks.commonSelectors.getJobData(state),
		candidatesByStatus: commonDucks.commonSelectors.getSortedCandidatesByStatus(state),
		clientAtsBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		queryParams: commonDucks.commonSelectors.getQueryParamsState(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		resetJobDetails: commonDucks.commonActions.resetJobDetailsState,
		getJobById: commonDucks.commonActions.getJobByIdRequested,
		getClientAutoPop: atsDucks.atsActions.getClientAutoPopRequested,
		updateCandidateStatus: commonDucks.commonActions.updateApplicationStatusRequested,
		updateCandidateRankRequested: commonDucks.commonActions.updateCandidateRankRequested,
		archiveAndUnarchiveJob: atsDucks.atsActions.archiveAndUnarchiveJobRequested,
		getCandidateByIdRequested: commonDucks.commonActions.getCandidateByIdRequested,
		saveSearchQueryParams: commonDucks.commonActions.saveSearchQueryParamsRequested,
	},
)(JobDetailsView);
