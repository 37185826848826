import React, { FC } from 'react';
import { useMedia } from 'react-use';

import { IEmployeeShortValues } from 'modules/Common/types/employee';
import { EmployeeRequestTimeOffType } from 'modules/HR/components/HRRequestComponent/HRRequestComponent.types';
import HRViewEmployeesDashboard from 'modules/HR/components/HRViewEmployeesDashboard';
import TimeOffUsedStatistics from 'modules/HR/components/TimeOffUsedStatistics';
import { MOBILE_VIEW_BREAKPOINT } from 'modules/HR/constants/HRModuleConstants.constants';

import HRCalendarWeekly from '../../HRCalendarWeekly';
import { Styled } from '../HRDashboard.styled';
import { StatisticsDataType } from '../HRDashboard.types';
import RequestsWidget from '../RequestsWidget/RequestsWidget';

interface HRViewProps {
	showRequestTab: boolean;
	showCalendarTab: boolean;
	statisticData: StatisticsDataType;
	employeeData: IEmployeeShortValues[];
	employeeRequests: EmployeeRequestTimeOffType[];
	handleApproveAllRequests: () => void;
}

const HRView: FC<HRViewProps> = ({
	showRequestTab,
	showCalendarTab,
	statisticData,
	employeeData,
	employeeRequests,
	handleApproveAllRequests,
}) => {
	const isMobile = useMedia(`(max-width: ${MOBILE_VIEW_BREAKPOINT})`);

	return (
		<Styled.DashboardLayout>
			<Styled.LayoutColumns isMobile={isMobile}>
				<Styled.LeftColumn isMobile={isMobile}>
					{showCalendarTab && <HRCalendarWeekly />}
					{showRequestTab && (
						<RequestsWidget
							title='Requests'
							emptyRequestsTitle='No requests at the moment'
							isEmployeeView={false}
							employeeRequests={employeeRequests}
							handleApproveAllRequests={handleApproveAllRequests}
						/>
					)}
				</Styled.LeftColumn>
				<Styled.RightColumn isMobile={isMobile}>
					<TimeOffUsedStatistics statisticData={statisticData} dashboardChart={true} />
					<HRViewEmployeesDashboard employeeList={employeeData} loading={false} />
				</Styled.RightColumn>
			</Styled.LayoutColumns>
		</Styled.DashboardLayout>
	);
};

export default HRView;
