import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Tabs } from 'antd';
import { SorterResult, TablePaginationConfig } from 'antd/es/table/interface';

import Table from 'components/Table';
import { ClntContext } from 'modules/BackOffice/containers/ClientContextContainer/ClientContextContainer';
import { columns } from 'modules/BackOffice/containers/SupportTickets/SupportTickets.entities';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { TabsTitleEnum } from 'modules/Common/types';
import { GenericType, Routes } from 'types';

import { Styled } from './ClientContextTicketsPage.styled';

type SupportTickets = {
	data: object[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
};

const ClientContextTicketsPage = () => {
	const { clientId } = useParams();
	const navigate = useNavigate();

	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);
	const [tableParams, setTableParams] = useState({});
	const dispatch = useDispatch();

	const clientStore = useContext(ClntContext)[clientId];
	const loading: GenericType = useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading);
	const tickets: SupportTickets = useSelector(clientStore?.backOfficeSelectors?.getBOTicketsState);
	const getTicketsRequested: (params: { active: boolean; page?: number; size?: number }) => void =
		clientStore?.backOfficeActions?.geTicketsRequested;
	const deleteTicketByIdRequested: (ticketId: number, callback: () => void) => void =
		clientStore?.backOfficeActions?.deleteTicketByIdRequested;

	const { pageIndex, pageSize, totalCount, data } = tickets || {};

	const isActiveTab = tab === TabsTitleEnum.ActiveTab;

	const handleChangeTabs = (id: string) => {
		dispatch(getTicketsRequested({ active: isActiveTab, clientId }));
		setTab(id);
	};

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			dispatch(getTicketsRequested({ active: isActiveTab, page, size, clientId }));
		},
		[isActiveTab],
	);

	const handleDeleteItem = (ticketId: number) => {
		dispatch(
			deleteTicketByIdRequested(ticketId, () => {
				dispatch(
					getTicketsRequested({ active: isActiveTab, page: pageIndex, size: pageSize, clientId }),
				);
			}),
		);
	};

	useEffect(() => {
		dispatch(
			getTicketsRequested({ active: isActiveTab, page: pageIndex, size: pageSize, clientId }),
		);
	}, [tab, clientId]);

	const handleSearch = (value: string) => {
		setTableParams({ ...tableParams, page: 1, search: value });
	};

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			sorter,
		});
	};
	const columnsData = columns(isActiveTab, handleDeleteItem, clientId).slice(1);

	return (
		<>
			<Styled.Head>
				<Styled.Title>Tickets</Styled.Title>
				<Button
					type='primary'
					onClick={() =>
						navigate(`${Routes.BOClientContext}/${clientId}${Routes.CreateSupportTicket}`)
					}
				>
					Create New
				</Button>
			</Styled.Head>
			<Tabs
				defaultActiveKey={TabsTitleEnum.ActiveTab}
				tabBarStyle={{ textTransform: 'uppercase' }}
				onChange={handleChangeTabs}
				items={[
					{
						label: 'Active',
						key: TabsTitleEnum.ActiveTab,
						active: true,
						children: (
							<Table
								pageSize={pageSize}
								current={pageIndex}
								loading={loading?.getTicketsLoad}
								total={totalCount}
								data={data}
								columns={columnsData}
								onChange={handleTablePaginationChange}
								onPageSizeChange={handleTablePaginationChange}
								onTableChange={handleTableChange}
							/>
						),
					},
					{
						label: 'Resolved',
						key: TabsTitleEnum.ArchiveTab,
						children: (
							<Table
								pageSize={pageSize}
								current={pageIndex}
								loading={loading?.getTicketsLoad}
								total={totalCount}
								data={data}
								columns={columnsData}
								onChange={handleTablePaginationChange}
								onPageSizeChange={handleTablePaginationChange}
								onTableChange={handleTableChange}
							/>
						),
					},
				]}
				tabBarExtraContent={
					<SearchFilterSortWrapper
						search={{
							onSearch: handleSearch,
							isDebounce: true,
							placeholder: 'Search',
							styles: { width: '300px', flexBasis: 'auto' },
						}}
					/>
				}
			/>
		</>
	);
};

export default ClientContextTicketsPage;
