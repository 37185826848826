import { createAsyncConstantsMap, createSyncConstantsMap } from 'utils/reduxHelpers';

export const createActionsTypes = (PREFIX) => {
	const asyncActions = createAsyncConstantsMap(PREFIX, [
		'GET_CLIENTS',
		'GET_CLIENTS_SHORT',
		'GET_BO_CLIENTS',
		'GET_CURRENT_CLIENT',
		'GET_CLIENT_BY_ID_SHORT',
		'GET_CLIENT_CONTEXT_CLIENTS_LIST',
		'GET_TICKETS',
		'GET_TICKET_DETAILS',
		'GET_CLIENTS_SUBS',
		'GET_BACKOFFICE_CONTRACT_BY_ID',
		'GET_BACKOFFICE_INVOICES',
		'DELETE_INVOICE_BY_ID',
		'DELETE_TICKET_BY_ID',
		'GET_CLIENT_REGIONS',
		'GET_CLIENTS_BY_REGION',
		'GET_CONTEXT_CLIENT',
		'GET_BO_USER_BY_ID',
		'CREATE_BO_USER',
		'UPDATE_BO_USER',
		'GET_BO_USERS',
		'GET_BO_USER_ROLES',
		'GET_BO_USER_MANAGERS',
		'GET_BO_CONTRACTS_BY_CLIENT_ID',
		'ACTIVATE_BO_USER',
		'CREATE_AND_UPDATE_CLIENT',
		'CREATE_CONTRACT',
		'CREATE_INVOICE',
		'UPDATE_INVOICE',
		'UPDATE_CONTRACT',
		'SET_CLIENT_NETWORKS',
		'GET_CLIENT_SUBS_INFO',
		'GET_INVOICE_BY_ID',
		'EDIT_INVOICE',
		'GET_ALL_BACKOFFICE_JOBS',
		'GET_BACKOFFICE_CANDIDATES',
		'GET_BACKOFFICE_JOB_BY_ID',
		'GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID',
		'GET_CLIENT_SUBSCRIPTION_TYPES',
		'GET_CREDITS_DEFAULT_PRICES',
		'UPDATE_CREDITS_DEFAULT_PRICES',
		'GET_SUBS_DEFAULT_PRICES',
		'UPDATE_SUBS_DEFAULT_PRICES',
		'CREATE_BACKOFFICE_TICKET',
		'UPDATE_BACKOFFICE_TICKET',
		'UPDATE_BACKOFFICE_TICKET_STATUS',
		'GET_INVOICE_STATUSES',
		'GET_INVOICE_PAYMENT_METHODS',
		'UPDATE_INVOICE_STATUS',
		'CHANGE_USER_PASSWORD',
		'GET_CLIENT_CONTEXT_INTERVIEW_LIST',
		'GET_CANDIDATE_STATISTICS_BO',
		'GET_CLIENT_CONTEXT_DASHBOARD_UNREAD_EMAILS',
		'GET_JOBS_MONTH_STATISTIC_BO',
		'BOOST_JOB_BO',
		'ADD_NOTE_TO_TICKET_BO',
		'GET_BO_DASHBOARD_INSIGHTS',
	]);
	const syncActions = createSyncConstantsMap(PREFIX, [
		'SET_CLIENT_CHANGES_FORM',
		'OMIT_CLIENT_CHANGES_FORM_VALUES',
		'RESET_TICKET_DETAILS',
		'RESET_CLIENT_CHANGES_FORM',
		'RESET_BACKOFFICE_JOB_CHANGES_FORM',
		'SAVE_CLIENT_CONTEXT_SELECTED_LIST',
	]);

	return {
		...asyncActions,
		...syncActions,
	};
};
